import React from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../hooks/useTypedSelector'

import { SignUpContainer } from './SignUpContainer'
import { SignUpTextContainer } from './SignUpTextContainer'

import { AppDispatch } from '../../../store/store'
import { SignUpProgress } from '../../../constants/pagesProgress'
import MainBg from '../../../assets/img/SignUpBg.png'
import { SignUpProgressStatus } from './SignUpProgressStatus'
import { useOnlyUnauthorized } from '../../../hooks/useOnlyUnauthorized'

type PropsType = {
  setFullScreenBg: (args: boolean) => void
}

export const SignUpProcess: React.FC<PropsType> = ({ setFullScreenBg }) => {
  useOnlyUnauthorized()
  const { progress } = useTypedSelector((store) => store.common)
  const { user } = useTypedSelector((store) => store.user)
  const dispatch = useDispatch<AppDispatch>()

  const { first_name } = user

  return (
    <section
      style={{
        background: `url(${MainBg})`,
        backgroundSize: 'cover',
        display: 'flex',
        position: 'relative',
        marginBottom: '12px',
        height: '580px'
      }}
    >
      {!(
        progress === SignUpProgress.SignUpLogoPhoto || progress === SignUpProgress.SignUpCongrats
      ) && (
        <article className="max-w-600px w-full relative">
          <div className="bg-black absolute left-0 top-0 w-full h-full opacity-50" />
          <div className="w-full py-11">
            <SignUpProgressStatus progress={progress} />
          </div>
          <SignUpTextContainer progress={progress} first_name={first_name} email={user.email} />
        </article>
      )}
      <div className="relative w-full">
        <div className="bg-white absolute left-0 top-0 w-full h-full opacity-90" />
        <SignUpContainer
          user={user}
          progress={progress}
          dispatch={dispatch}
          setFullScreenBg={setFullScreenBg}
        />
      </div>
      <div className="absolute left-0 -bottom-3 bg-81A46A w-full h-3" />
    </section>
  )
}
