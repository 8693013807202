import React, { useState, useEffect, useRef } from 'react'

import { AnyAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../../store/store'
import { CustomButton } from '../../Common/CustomButton'
import { useApiDispatch } from '../../../hooks/api/useApiDispatch'
import { confirmEmail, resendCode } from '../../../store/auth/actions'
import { useLoading } from '../../../hooks/api/useLoading'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { SignUpProgress } from '../../../constants/pagesProgress'
import { setSkipMobile } from '../../../store/auth/authSlice'

type PropsType = {
  dispatch: AppDispatch
  setProgress: (progress: string) => AnyAction
  nextProgress: string
  prevProgress: string
}

const MOBILE_CODE_LENGTH = 6
const DEFAULT_INPUT_STATE = Array(MOBILE_CODE_LENGTH).join('.').split('.')

export const MobileCode: React.FC<PropsType> = ({ dispatch, setProgress, nextProgress, prevProgress }) => {
  const resend = useApiDispatch(resendCode)
  const verify = useApiDispatch(confirmEmail)
  const resetLoading = useLoading(resendCode)
  const { skipMobile } = useTypedSelector((store) => store.auth)

  const [error, setError] = useState('')
  const [values, setValues] = useState<string[]>(DEFAULT_INPUT_STATE)

  const itemsRef = useRef<any[]>([])

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, DEFAULT_INPUT_STATE.length)
  }, [DEFAULT_INPUT_STATE])

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let localValues = [...values]
    localValues[index] = e.target.value
    if (values[index]) {
      setValues(localValues)
      return
    }
    itemsRef.current[index + 1]?.focus()
    setValues(localValues)
  }

  const handleCode = () => {
    verify(values.join(''))
    .then(({ error, payload }) => {
      if (error) {
        setError(payload)
      } else {
        dispatch(setProgress(nextProgress))
      }
    })
  }

  useEffect(() => {
    if (values.map((value) => !!value).every(value => value)) {
      setError('')
      handleCode()
    }
  }, [values])

  const goBack = () => {
    const backProgress = skipMobile ? SignUpProgress.SignUpMobile : prevProgress
    dispatch(setSkipMobile(false))
    dispatch(setProgress(backProgress))
  }

  const handleResendCode = () => {
    resend({})
  }

  return (
    <div className="relative pt-120px pl-140px pr-100px">
      <article className="mb-8">
        <h1 className="mb-5 signUp__component_title text-4A4A4A">Enter Your Validation Code</h1>
        <p className="text-4A4A4A text-18/22 opacity-80">
          Please enter your validation code to continue.
        </p>
      </article>
      <div className="relative">
        <span className="absolute left-0 -top-6 text-sm text-B52A2A opacity-80">{error}</span>
        <form className="relative mb-2">
          {DEFAULT_INPUT_STATE.map((_, i) => {
            return (
              <input
                onChange={e => inputHandler(e, i)}
                key={i}
                ref={el => itemsRef.current[i] = el}
                className={`mobile__code border border-solid ${
                  !!error ? 'border-EE3D23 text-B52A2A' : 'border-81A46A text-333333'
                }`}
                maxLength={1}
              />
            )
          })}
        </form>
      </div>
      <p>
        Need a new one? {'  '}
        <CustomButton
          className="text-6EB629"
          text="Resend"
          onClick={handleResendCode}
          loading={resetLoading}
        />
      </p>
      <div className="mt-8">
        <CustomButton
          text="< Back"
          className="secondary-button text-20/24"
          onClick={goBack}
        />
      </div>
    </div>
  )
}
