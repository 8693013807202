import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store/store'
import { AsyncThunk } from '@reduxjs/toolkit'
import { addSuccessfulRequests, deleteSuccessfulRequests, finishLoading, startLoading } from '../../store/api/apiSlice'
import { useMemo } from 'react'

export const useApiDispatch = (thunk: AsyncThunk<any, any, any>): (payload: any) => Promise<any> => {
  const dispatch = useDispatch<AppDispatch>()

  return useMemo(() => {
    return (payload: any) => {
      dispatch(startLoading(thunk.typePrefix))
      return dispatch(thunk(payload))
      .then((...args) => {
        const res = args[0] || {}
        const { error } = res as { error: any }

        if (!error) {
          dispatch(addSuccessfulRequests(thunk.typePrefix))
          setTimeout(() => dispatch(deleteSuccessfulRequests(thunk.typePrefix)), 5 * 1000)
        }

        return Promise.resolve(...args)
      })
      .finally(() => dispatch(finishLoading(thunk.typePrefix)))
    }
  }, [thunk])
}