import React from 'react'

export const SupportEmailText: React.FC = () => {
  return (
    <a className="text-6EB629 font-bold" href="mailto:headpro@golfbevy.com">
      {' '}
      headpro@golfbevy.com
    </a>
  )
}
