import React, { ChangeEvent, memo, useState } from 'react'
import Dropzone from 'react-dropzone'

import { ReactComponent as EditPencil } from '../../assets/svg/editPencil.svg'
import { ReactComponent as Logo } from '../../assets/svg/headerLogo.svg'

type PropsType = {
  oneRow?: boolean
  editPencil?: boolean
  imageBlockClass: string
  onImgChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    setLogoImg: (logo: unknown) => void,
    setPhotoImg?: (image: unknown) => void
  ) => void
  onImgDragAndDrop?: (
    files: any,
    setLogoImg: (logo: unknown) => void
  ) => void
  croppedLogo: unknown
  isText: boolean
  setLogoImg: (logo: unknown) => void
  setPhotoImg?: (photo: unknown) => void
  imageTitle?: string
  imageTitleText?: string
  isUploadedImageRounded?: boolean
  inputName?: string
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const UploadLogo: React.FC<PropsType> = memo(
  ({
    oneRow = true,
    editPencil = true,
    imageBlockClass,
    onImgChange,
    onImgDragAndDrop = () => {},
    inputName,
    handleChange,
    croppedLogo,
    isText,
    setLogoImg,
    setPhotoImg,
    imageTitle,
    imageTitleText,
    isUploadedImageRounded = false
  }) => {

    const [showUploadModal, setShowUploadModal] = useState(false)

    const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setPhotoImg) {
        return onImgChange(event, setLogoImg, setPhotoImg)
      }
      return onImgChange(event, setLogoImg)
    }

    const onImageDragAndDrop = (files: unknown) => {
      return onImgDragAndDrop(files, setLogoImg)
    }

    return (
      <>
        <div className={`${oneRow ? "flex items-center" : ""}`}>
          <div className={`mr-10 ${oneRow ? "w-64" : ""}`}>
            <div className="flex">
              <span className={`${editPencil ? "text-18/22" : "text-16/20"} leading-6 text-black`}>{imageTitle}</span>
              {editPencil && (
                <label className="cursor-pointer ml-2" htmlFor="logo">
                  <EditPencil />
                  <input
                    onChange={(event) => {
                      onImageChange(event)
                      handleChange && handleChange(event)
                    }}
                    className="hidden"
                    type="file"
                    id="logo"
                    accept="image/jpeg, image/png"
                    name={inputName}
                  />
                </label>  
              )}
            </div>
            {imageTitleText && (
              <span className="text-xs flex opacity-70 text-4A4A4A">{imageTitleText}</span>
            )}
          </div>
          <div>
            <Dropzone accept="image/jpeg, image/png" 
              onDropAccepted={(acceptedFiles) => {
                  onImageDragAndDrop(acceptedFiles) 
                  setShowUploadModal(false)
              }} 
              onDragEnter={(e) => setShowUploadModal(true)}
              onDragLeave={(e) => setShowUploadModal(false)}
              onDrop={(e) => setShowUploadModal(false)}
            >
              {({getRootProps, getInputProps}) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {
                    showUploadModal &&
                    <div
                      className={`absolute bg-white z-50 translate-center w-5/6 h-full top-1/2 left-1/2 flex justify-center items-center rounded-md border border-dashed border-979797`}>
                      <span className="text-333333 opacity-50 text-18/22 ">Drag your image here</span>
                    </div>
                  }
                  <div
                    className={`relative bg-white shadow-md flex justify-center items-center ${imageBlockClass} ${
                      isText && 'mb-2'
                    }`}
                  >
                    {croppedLogo ? (
                      <img
                        className={`w-85px ${isUploadedImageRounded && 'rounded-5px'}`}
                        src={croppedLogo as string}
                        alt="img"
                      />
                      ) : (
                        <div className="flex flex-col items-center">
                          <Logo className={`${isText ? 'w-100px' : 'w-100px'} ${!croppedLogo && 'opacity-30'}`} />
                          <p className="text-xs leading-5 text-333333 opacity-40">Drag your image here</p>
                        </div>
                      )
                    }
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
                      
        {isText && (
          <>
            <p className="text-12/14 text-808080">Logos must be PNG or JPG format.</p>
            <p className="text-12/14 text-808080">The higher resolution the better.</p>
          </>
        )}
        
      </>
    )
  }
)
