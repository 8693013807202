import React from 'react'
import { AvailabilityDay } from '../../../../../models/AvailabilityDay'
import format from 'date-fns/format'
import { DayPeriod } from '../../../../../models/DayPeriod'
import { DayCheckbox } from './DayCheckbox'

const getDateDay = (day: AvailabilityDay): string => {
  const { date } = day
  return format(date, 'EEEE, MMMM do')
}

type PropTypes = { day: AvailabilityDay, onChange: any, disabled: boolean }

export const Day: React.FC<PropTypes> = ({ day, onChange, disabled }) => {
  const dayName = getDateDay(day)
  return (
    <div
      className="w-full flex h-[58px] border-b border-solid border-0 border-b-[1.5px] last:border-none border-979797 items-center pl-[22px] pr-2 justify-between">
      <span className={`text-[13px] leading-3 ${disabled ? 'disabled' : ''}`}>{dayName}</span>
      <span className="flex gap-x-2.5">
       <DayCheckbox
         name={dayName + DayPeriod.AM}
         isChecked={day.am}
         onChange={() => onChange(day, DayPeriod.AM)}
         period={DayPeriod.AM}
         disabled={disabled}
       />
      <DayCheckbox
        name={dayName + DayPeriod.PM}
        isChecked={day.pm}
        onChange={() => onChange(day, DayPeriod.PM)}
        period={DayPeriod.PM}
        disabled={disabled}
      />
      </span>

    </div>
  )
}