import React from 'react'
import { EventForm } from './EventForm'
import { Page } from '../Components/Page'
import { useParams } from 'react-router-dom'
import { useEvent } from '../../../hooks/useEvent'
import { updateEvent } from '../../../store/event/actions'
import { useOutletContext } from '../../Common/CustomContext'

export const EditEventPage: React.FC = () => {
  const { id } = useParams()
  const event = useEvent(Number(id))
  const { course } = useOutletContext()

  return (
    <Page title="Edit Event">
      <EventForm event={event} onSubmitThunk={updateEvent} editing course={course} />
    </Page>
  )
}
