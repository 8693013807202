import axios, { AxiosRequestConfig } from 'axios'
import { getFromLocalStorage, removeFromLocalStorage } from './localStorage'
import { LOGGED_OUT } from '../constants/urls'

const agent = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api/v1`
})

export const setTokenHeader = () => {
  agent.interceptors.request.use((config) => {
    const token = getFromLocalStorage('authToken')

    let headers = { ...config.headers }

    if (token) {
      headers = { ...headers, Authorization: `Token ${token}` }
    }

    return {
      ...config,
      headers: headers
    }
  })
}

setTokenHeader()

agent.interceptors.response.use((response) => {
  return Promise.resolve(response)
}, (error) => {
  if (error.response.status === 401) {
    removeFromLocalStorage('authToken')
    window.location.replace(LOGGED_OUT)
  }
  return Promise.reject(error)
})

function request({ method, url, data }: AxiosRequestConfig) {
  return agent({
    method,
    url,
    data,
    withCredentials: true
  })
}

export default request
