// import { toApiDateTime, toFormDateTime } from './date'
import { FeedOffer, Offer } from '../models/Offer'
import { OfferStatus } from '../models/OfferStatus'
import { fileListToApiImage } from './image'

export const fromOfferDto = (offerDto: any): Offer => {
  const { image } = offerDto

  return {
    ...offerDto,
    image: {
      url: image,
      name: image?.split('/').reverse()[0]
    }
  }
}

export const fromOfferDtos = (offerDtos: Offer[]): Offer[] => {
  return offerDtos.map(fromOfferDto)
}

export const toOfferDto = async (offerFormObject: Offer): Promise<any> => {
  const image = await fileListToApiImage(offerFormObject.image)

  return {
    ...offerFormObject,
    image: image?.file_name ? image : undefined
  }
}

export const offerStatusToName = (status: OfferStatus): string => {
  switch (status) {
    case OfferStatus.ACTIVE:
      return 'Active'
    case OfferStatus.INACTIVE:
      return 'Inactive'
    case OfferStatus.PENDING:
      return 'Pending'
    case OfferStatus.UNPUBLISHED:
      return 'Unpublished'
  }
}

export const statusToUnpublished = (status: OfferStatus) => {
  return status === OfferStatus.UNPUBLISHED
}

export const getOfferStatus = (offer: Offer): OfferStatus => {
  const now = (new Date()).getTime()
  const oneDay = 24 * 60 * 60
  const publishStart = (new Date(offer.date.start)).getTime()
  const publishEnd = (new Date(offer.date.end)).getTime()

  if (publishStart > now) {
    return OfferStatus.PENDING
  } else {
    if (now < publishEnd + oneDay) {
      return OfferStatus.ACTIVE
    } else {
      return OfferStatus.INACTIVE
    }
  }
}

export const getFeedOfferStatus = (offer: FeedOffer): OfferStatus => {
  const now = (new Date()).getTime()
  const oneDay = 24 * 60 * 60
  const publishStart = (new Date(offer.date_publish_start)).getTime()
  const publishEnd = (new Date(offer.date_publish_end)).getTime()

  if (publishStart > now) {
    return OfferStatus.PENDING
  } else {
    if (now < publishEnd + oneDay) {
      return OfferStatus.ACTIVE
    } else {
      return OfferStatus.INACTIVE
    }
  }
}

export const offersToOptions = (offers: Offer[]) => {
  return offers.map(({ id, name }: Offer) => ({ id, name }))
}