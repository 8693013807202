import React, { ChangeEventHandler } from 'react'
import { UseFormRegister } from 'react-hook-form'

import { ReactComponent as CheckMark } from '../../assets/svg/golfAppCheckMark.svg'
import { ReactComponent as CheckMarkGrey } from '../../assets/svg/golfAppCheckMarkGrey.svg'

type PropsType = {
  text?: string
  htmlFor?: string
  className?: string
  textClass?: string
  name: string
  checkMarkColor: string
  register?: UseFormRegister<any>
  isCheckboxChecked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
}

export const CustomCheckbox: React.FC<PropsType> =
  ({
     text,
     className,
     textClass,
     htmlFor,
     name,
     checkMarkColor,
     register,
     isCheckboxChecked,
     onChange,
     disabled = false
   }) => {
    const inputRegister = register && { ...register(name) }
    return (
      <div className={`relative flex items-center ${className} golf-checkbox ${disabled ? 'disabled' : ''}`}>
        <div className="relative w-15px h-15px border border-solid border-979797 bg-FAFAFA rounded-sm">
          {isCheckboxChecked && (
            <>
              {checkMarkColor === 'green' && !disabled ? (
                <CheckMark className="absolute top-0 left-0.5" />
              ) : (
                <CheckMarkGrey className="absolute top-0.5 left-0.5" />
              )}
            </>
          )}
        </div>
        <label className="absolute left-0 top-0 w-full h-full cursor-pointer" htmlFor={htmlFor} />
        <input
          onChange={onChange}
          {...inputRegister}
          className="border-2 border-solid bolder-979797 hidden"
          id={htmlFor}
          type="checkbox"
          disabled={disabled}
        />
        <span className={`${textClass}`}>{text}</span>
      </div>
    )
  }