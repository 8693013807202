import React from 'react'

export const Notification: React.FC = ({ children }) => {
  return (
    <div className="notification top-5 right-5">
      <div>
        {children}
      </div>
    </div>
  )
}