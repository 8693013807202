import React, { MouseEventHandler } from 'react'

type PropsType = {
  className?: string
  onClick?: MouseEventHandler<HTMLSpanElement>
}

export const GreySquareButtonRight: React.FC<PropsType> = ({ onClick, className }) => {
  return (
    <span
      className={`flex items-center justify-center text-20/24 text-333333/60 bg-FAFAFA w-18px h-7 border border-solid border-E5E5E5 rounded-3px cursor-pointer ml-2.5 ${className}`}
      onClick={onClick}
    >
      {`>`}
    </span>
  )
}
