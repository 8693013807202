import React, { useState, memo } from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'

import { ReactComponent as CalendarIcon } from '../../../assets/svg/calendarIcon.svg'
import { DoubleDatePicker } from './DoubleDatePicker'

type PropsType = {
  className?: string
  name: string
  control: Control<any, object>
  firstDateLabel: string
  secondDateLabel: string
  firstName: string
  secondName: string
  required: boolean
  isError?: unknown
  position?: 'top' | 'bottom'
  disabled?: boolean
  disabledSecondDate?: boolean
  hideInputLabels?: boolean
}

type DoubleCalendarErrors = { start: FieldError, end: FieldError }

export const InputDoubleCalendar: React.FC<PropsType> = memo(
  ({
     className,
     name,
     firstName,
     secondName,
     control,
     firstDateLabel,
     secondDateLabel,
     required,
     isError,
     position = 'bottom',
     disabled = false,
     disabledSecondDate = false,
     hideInputLabels = false
   }) => {
    const [isDatePickerVisible, setDatePickerVisible] = useState(false)
    const errorType = (isError as FieldError)?.type
    let startError: FieldError, endError: FieldError

    if (errorType === 'start') {
      startError = isError as FieldError
    }
    if (errorType === 'end') {
      endError = isError as FieldError
    }

    if (errorType === undefined) {
      startError = (isError as DoubleCalendarErrors)?.start
      endError = (isError as DoubleCalendarErrors)?.end
    }

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <div className="relative max-h-20">
              <div className="flex">
                <div className="mr-8">
                  <DateInput
                    isError={startError}
                    label={hideInputLabels ? '' : `${firstDateLabel}${required ? '*' : null}`}
                    value={value && value.start ? value.start.toString() : ''}
                    name={`${name}[${firstName}]`}
                    className={className}
                    onClick={() => setDatePickerVisible(!isDatePickerVisible)}
                    control={control}
                    disabled={disabled}
                  />
                </div>
                <DateInput
                  isError={endError}
                  label={hideInputLabels ? '' : `${secondDateLabel}${required ? '*' : null}`}
                  value={value && value.end ? value.end.toString() : ''}
                  name={`${name}[${secondName}]`}
                  className={className}
                  onClick={() => setDatePickerVisible(!isDatePickerVisible)}
                  control={control}
                  disabled={disabled}
                />
              </div>
              {isDatePickerVisible && (
                <DoubleDatePicker
                  value={value}
                  onChange={onChange}
                  setDatePickerVisible={setDatePickerVisible}
                  firstDateLabel={firstDateLabel}
                  secondDateLabel={secondDateLabel}
                  position={position}
                  disabledSecondDate={disabledSecondDate}
                />
              )}
            </div>
          )
        }}
      />
    )
  }
)

type DateInputPropsType = {
  isError?: FieldError
  name: string
  value: string
  onClick: () => void
  label: string
  className?: string
  control: Control<any, object>
  disabled: boolean
}

const DateInput: React.FC<DateInputPropsType> =
  ({
     isError,
     name,
     value,
     onClick,
     label,
     className,
     control,
     disabled
   }) => {
    const handleClick = () => {
      if (!disabled) {
        onClick()
      }
    }
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={() => {
          return (
            <div>
            <span
              className={`block text-15/18 font-bold leading-[30px] ${
                isError ? 'text-C43100' : 'text-333333'
              }`}
            >
              {label}
            </span>
              <div className="relative w-40">
                <input
                  value={value}
                  name={name}
                  className={`pl-10 py-1.5 h-40px ${className} ${
                    isError ? 'golfApp__formELements_error' : 'golfApp__formELements'
                  }`}
                  readOnly
                  disabled={disabled}
                />
                <CalendarIcon className="absolute top-2 left-2 cursor-pointer" onClick={handleClick} />
              </div>
              {isError && <span className="text-C43100 text-12/14">{isError.message}</span>}
            </div>
          )
        }}
      />
    )
  }
