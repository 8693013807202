import { ReactComponent as Step1 } from '../../../assets/svg/setUpProgress/step1.svg'
import { ReactComponent as Step2 } from '../../../assets/svg/setUpProgress/step2.svg'
import { ReactComponent as Step3 } from '../../../assets/svg/setUpProgress/step3.svg'
import React, { memo, useMemo } from 'react'
import { useApiResource } from '../../../hooks/api/useApiResource'
import { getTeammates } from '../../../store/course/actions'
import { getEvents } from '../../../store/event/actions'
import { getOffers } from '../../../store/offer/actions'
import { Teammate, TeammateType } from '../../../models/Teammate'
import { useNavigate } from 'react-router-dom'
import { MY_TEAM, PROMOTE_MY_COURSE } from '../../../constants/urls'

export const SetupProgress: React.FC = memo(() => {
  const [offerLoading, offers] = useApiResource('offer', 'offers', getOffers)
  const [activitiesLoading, activities] = useApiResource('event', 'events', getEvents)
  const [teammatesLoading, allTeammates] = useApiResource('course', 'teammates', getTeammates)
  const navigate = useNavigate()

  const teammates = useMemo(() => {
    return allTeammates?.filter(({ type }: Teammate) => type !== TeammateType.INITIAL)
  }, [allTeammates])

  const progress = useMemo(() => {
    const isDataLoading = (offerLoading && !offers) || (activitiesLoading || !activities) || (teammatesLoading || !teammates)

    if (isDataLoading) {
      return 1
    }

    if (teammates && teammates.length > 0) {
      if ((offers && offers.length > 0) || (activities && activities.length > 0)) {
        return 3
      }
      return 2
    }
    return 1
  }, [offerLoading, offers, activitiesLoading, activities, teammates, teammatesLoading])

  const isActive = (step: number) => {
    return step <= progress
  }

  const Step = useMemo(() => {
    if (progress === 1) {
      return Step1
    }
    if (progress === 2) {
      return Step2
    }
    return Step3
  }, [progress])

  return (
    <div className="w-fit">
      <div className="pl-5 relative">
        <Step />
        <div className="absolute top-0 w-full h-full flex">
          <div className="h-full w-7 mr-[46px]" />
          <div className="h-full w-7 cursor-pointer mr-[46px]" onClick={() => navigate(MY_TEAM)} />
          <div className="h-full w-7 cursor-pointer" onClick={() => navigate(PROMOTE_MY_COURSE)} />
        </div>
      </div>
      <div className="mt-0.5">
        <span className={`text-8/10 leading-6 font-medium mr-4 text-4A4A4A ${isActive(1) ? '' : 'opacity-50'}`}>
          Create an account
        </span>
        <span className={`text-8/10 leading-6 font-medium mr-4 text-4A4A4A ${isActive(2) ? '' : 'opacity-50'}`}>
          Set up My Team
        </span>
        <span className={`text-8/10 leading-6 font-medium mr-4 text-4A4A4A ${isActive(3) ? '' : 'opacity-50'}`}>
          Promote My course
        </span>
      </div>
    </div>
  )
})