import React from 'react'
import { Page } from '../Components/Page'
import { OfferForm } from './OfferForm'
import { createOffer } from '../../../store/offer/actions'
import { useOutletContext } from '../../Common/CustomContext'
import { useOnlySpecificType } from '../../../hooks/useOnlySpecificType'

export const NewOfferPage: React.FC = () => {
  useOnlySpecificType('public')
  const { course } = useOutletContext()

  return (
    <Page title="Add Offer">
      <OfferForm onSubmitThunk={createOffer} course={course} />
    </Page>
  )
}
