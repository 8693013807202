import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'

import CircleCross from '../../../assets/svg/circleCross.svg'
import { CustomButton } from '../../Common/CustomButton'

type PropsType = {
  labelText: string
  name: string
  register: UseFormRegister<any>
  required?: boolean
  value?: any
  setValue?: (value: FileList | undefined) => void,
  rightButton?: boolean,
  disabled?: boolean
}

export const InputUploadImage: React.FC<PropsType> = memo(
  ({ labelText, name, register, required, value, setValue, rightButton = true, disabled = false }) => {
    let dragCounter = 0
    const inputRef = useRef<HTMLInputElement>()
    const registeredField = register(name, { required: !!required })
    const [dragged, setDragged] = useState<boolean>(false)
    const dragContainerRef = useRef<any>()

    const fileNames = useMemo(() => {
      if (!value) {
        return ''
      }
      let valueArray: { name: string }[]

      if (value instanceof FileList) {
        valueArray = Array.from(value)
      } else {
        valueArray = [value]
      }

      return valueArray.map(({ name }: { name: string }) => name).join(',')
    }, [value])

    const stopDefaultDraggabble = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const handleDrop = (e: DragEvent) => {
      stopDefaultDraggabble(e)
      if (fileNames) {
        return
      }
      const files = e.dataTransfer?.files
      if (files && files.length > 0) {
        setValue && setValue(files)
      }
      setDragged(false)
    }

    const handleMissedDrop = (e: DragEvent) => {
      stopDefaultDraggabble(e)
      setDragged(false)
    }

    const onDragStart = (e: DragEvent) => {
      stopDefaultDraggabble(e)
      dragCounter++
      if (!dragged && !fileNames && !disabled) {
        setDragged(true)
      }
    }

    const onDragEnd = (e: DragEvent) => {
      stopDefaultDraggabble(e)
      dragCounter--
      if (dragCounter > 0) return
      setDragged(false)
    }

    useEffect(() => {
      let div = dragContainerRef.current

      div?.addEventListener('drop', handleDrop)

      document.body.addEventListener('dragenter', onDragStart)
      document.body.addEventListener('dragleave', onDragEnd)
      document.body.addEventListener('dragover', stopDefaultDraggabble)
      document.body.addEventListener('drop', handleMissedDrop)

      return () => {
        document.body.removeEventListener('dragenter', onDragStart)
        document.body.removeEventListener('dragleave', onDragEnd)
        document.body.removeEventListener('dragover', stopDefaultDraggabble)
        document.body.removeEventListener('drop', handleMissedDrop)
        div?.removeEventListener('drop', handleDrop)
      }
    }, [fileNames])

    const setRef = (ref: HTMLInputElement) => {
      inputRef.current = ref
      registeredField.ref(ref)
    }

    const handleClick = () => {
      if (fileNames) {
        return
      }
      inputRef && inputRef.current && inputRef.current.click()
    }

    const handleClear = () => {
      setValue && setValue(undefined)
    }

    return (
      <>
        <span className="block text-333333 text-15/18 font-bold">Upload Image</span>
        <span className="inline-block text-right text-11/13 text-333333 opacity-50">
          {labelText}
        </span>
        <input
          className="golfApp__formELements h-40px hidden"
          type="file"
          {...registeredField}
          ref={setRef}
        />
        <div className="mt-1 flex">
          <div className="relative w-full">
            <input
              value={fileNames}
              name={`${name}-name`}
              className={`px-3 py-1.5 h-40px golfApp__formELements`}
              readOnly
              disabled={disabled}
            />
            {!fileNames && (
              <div className="text-15/18 absolute top-2.5 left-3">
                <CustomButton
                  className="secondary-button mr-2"
                  text="Select a file"
                  onClick={disabled ? () => {
                  } : handleClick}
                />
                <span className="text-333333 opacity-50">or drag here</span>
              </div>
            )}
            {fileNames && (
              <img src={CircleCross} alt="" className="clear-files-crosses" onClick={disabled ? () => {
              } : handleClear} />
            )}
          </div>
          {rightButton && (
            <div className="flex items-center px-2">
            <span
              className={`secondary-button ${fileNames ? 'opacity-30' : 'cursor-pointer'}`}
              onClick={handleClick}
            >
              Upload
            </span>
            </div>
          )}
        </div>
        <div
          className={`absolute bg-white z-50 translate-center w-4/6 h-4/6 top-1/2 left-1/2 flex justify-center items-center rounded-md border border-dashed border-979797 ${
            !dragged || fileNames ? 'hidden' : ''
          }`}
          ref={dragContainerRef}
        >
          <span className="text-333333 opacity-50 text-18/22 ">Drag your image here</span>
        </div>
      </>
    )
  }
)
