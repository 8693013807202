import React, { useState, FormEvent } from 'react'

import { PasswordComponent } from '../../Common/PasswordComponent'

import { AnyAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../../store/store'
import { useApiDispatch } from '../../../hooks/api/useApiDispatch'
import { attachPassword } from '../../../store/auth/actions'
import { useLoading } from '../../../hooks/api/useLoading'

type PropsType = {
  dispatch: AppDispatch
  setProgress: (progress: string) => AnyAction
  nextProgress: string
}

export const CreatePassword: React.FC<PropsType> = ({ dispatch, setProgress, nextProgress }) => {
  const createPassword = useApiDispatch(attachPassword)
  const loading = useLoading(attachPassword)
  const [password, setPassword] = useState('')
  const [isInputValueVisible, setInputValueVisible] = useState(false)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value
    setPassword(password)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    createPassword(password)
    .then(({ error, _payload }) => {
      if (!error) {
        dispatch(setProgress(nextProgress))
      }
    })
  }

  const isPasswordValid = password.length >= 6

  return (
    <PasswordComponent
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      isInputValueVisible={isInputValueVisible}
      setInputValueVisible={setInputValueVisible}
      isPasswordValid={isPasswordValid}
      inputClass="h-52px"
      title="Create a password"
      belowInputText="6 character min"
      name="password"
      loading={loading}
    />
  )
}
