import React from 'react'
import { Page } from '../Components/Page'
import { OfferForm } from './OfferForm'
import { useOffer } from '../../../hooks/useOffer'
import { editOffer } from '../../../store/offer/actions'
import { useParams } from 'react-router-dom'
import { useOutletContext } from '../../Common/CustomContext'
import { useOnlySpecificType } from '../../../hooks/useOnlySpecificType'

export const EditOfferPage: React.FC = () => {
  useOnlySpecificType('public')
  const { id } = useParams()
  const offer = useOffer(Number(id))
  const { course } = useOutletContext()

  return (
    <Page title="Edit Offer">
      <OfferForm editing offer={offer} onSubmitThunk={editOffer} course={course} />
    </Page>
  )
}
