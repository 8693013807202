import React from 'react'
import AddPhoto from '../../../../../../assets/svg/addPhoto.svg'
import { EventLine } from './EventLine'

type PropTypes = {
  imageUrl?: string
}

export const Photo: React.FC<PropTypes> = ({ children, imageUrl }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${imageUrl})`,
        height: '122px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
      className="p-2.5 pt-3 relative"
    >
      {!imageUrl ? (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col">
          <img src={AddPhoto} alt="" className="w-20 h-18" />
          <p className="text-8/10  text-898A8D mt-2 ">Your image goes here</p>
        </div>
      ) : (
        <></>
      )}
      {children}
      <EventLine />
    </div>
  )
}