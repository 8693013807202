import React from 'react'

import { CustomButton } from '../../Common/CustomButton'

import { AnyAction } from 'redux'
import { AppDispatch } from '../../../store/store'

type PropsType = {
  dispatch: AppDispatch
  setProgress: (progress: string) => AnyAction
  nextProgress: string
  backProgress: string
  mobile: string
}

export const EnterMobileTextSent: React.FC<PropsType> =
  ({
     dispatch,
     setProgress,
     nextProgress,
     backProgress,
     mobile
   }) => {

    const handleClick = () => {
      dispatch(setProgress(nextProgress))
    }
    const moveToPreviousPage = () => {
      dispatch(setProgress(backProgress))
    }

    return (
      <div className="relative pt-120px pl-140px pr-120px">
        <article className="mb-70px">
          <h1 className="mb-5 signUp__component_title text-4A4A4A">
            We have sent you a text to <br />
            {mobile}
          </h1>
          <p className="text-4A4A4A text-18/22 opacity-80">
            Validate the text and you're good to go. If this isn't your mobile number, please
            <span onClick={moveToPreviousPage} className="text-6EB629 cursor-pointer">
            {' '}
              reenter.
          </span>
          </p>
        </article>
        <div className="flex justify-end">
          <CustomButton
            onClick={handleClick}
            className="py-4 px-68px text-20/24 font-bold text-white bg-52A91A"
            text="Continue"
          />
        </div>
      </div>
    )
  }
