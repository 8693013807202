import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { CustomButton } from '../../Common/CustomButton'
import { Page } from '../Components/Page'
import { editTeammate, getTeammates } from '../../../store/course/actions'
import { useApi } from '../../../hooks/api/useApi'
import { MY_TEAM } from '../../../constants/urls'
import { useNavigate, useParams } from 'react-router-dom'
import { GolfInput } from '../Components/GolfInput'
import { EMAIL_PATTERN } from '../../../helpers/email'
import { Teammate } from '../../../models/Teammate'
import { useApiResource } from '../../../hooks/api/useApiResource'
import { SetupProgress } from '../Components/SetupProgress'

type Inputs = {
  first_name: string
  last_name: string
  title: string
  email: string
}

type TeammateErrors = {
  first_name?: string[]
  last_name?: string[]
  title?: string[]
  email?: string[]
}

export const EditTeammatePage: React.FC = () => {
  const [loading, save] = useApi(editTeammate)
  const navigate = useNavigate()
  const { id: teammateId } = useParams()
  const [_, teammates] = useApiResource('course', 'teammates', getTeammates)
  const teammate = teammates?.find(({ id }: Teammate) => id === Number(teammateId))
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => teammate || {}, [teammate])
  })

  useEffect(() => {
    reset(teammate)
  }, [teammate])


  const onSubmit = (data: Inputs) => {
    save(data)
    .then(({ error, payload }) => {
      if (!error) {
        return navigate(MY_TEAM)
      }
      payload.forEach((obj: TeammateErrors) => {
        const err = {}
        if (typeof obj !== 'object') {
          return
        }
        for (const [key, value] of Object.entries(obj)) {
          // @ts-ignore
          setError(`${key}`, { message: value.join(', ') })
        }

        return err
      })
    })
  }

  return (
    <Page title="Edit Teammates" relative>
      <div className="flex justify-end absolute right-[30px] top-5">
        <SetupProgress />
      </div>
      <form className="max-w-740px w-full mx-auto pb-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-10">
          <div className="flex justify-between mb-8">
            <div className="max-w-365px w-full">
              <GolfInput
                label="First Name*"
                name={`first_name`}
                htmlFor={`first_name`}
                register={register}
                isError={errors.first_name}
                required={false}
                options={{
                  required: 'First Name is required',
                  minLength: { value: 2, message: 'Must have at least 2 characters' }
                }}
              />
            </div>
            <div className="max-w-365px w-full">
              <GolfInput
                label="Last Name*"
                name={`last_name`}
                htmlFor={`last_name`}
                register={register}
                isError={errors.last_name}
                required={false}
                options={{
                  required: 'Last Name is required',
                  minLength: { value: 2, message: 'Must have at least 2 characters' }
                }}
              />
            </div>
          </div>
          <div className="mb-8">
            <GolfInput
              label="Teammate's job title"
              name={`title`}
              htmlFor={`title`}
              register={register}
              isError={errors.title}
              required={false}
              options={{
                minLength: { value: 2, message: 'Must have at least 2 characters' }
              }}
            />
          </div>
          <div className="mb-3">
            <GolfInput
              label="Teammate's Email Address*"
              labelText="The teammate will have to validate their email"
              name={`email`}
              htmlFor={`email`}
              register={register}
              isError={errors.email}
              required={false}
              options={{
                required: 'Email is required',
                pattern: {
                  value: EMAIL_PATTERN,
                  message: 'Enter a valid email'
                }
              }}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <CustomButton className="secondary-button" text="< Back" link={MY_TEAM} />
          <div>
            <CustomButton
              className={`py-4 px-68px text-20/24 font-bold text-white bg-52A91A`}
              text="Complete"
              type="submit"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </Page>
  )
}
