import React from 'react'
import { Page } from './Components/Page'
import GolfHole from '../../assets/img/golfHole.png'

export const LoggedOutPage: React.FC = () => {
  return (
    <Page title="You’ve logged out!">
      <div className="w-full flex justify-center mt-[113px]">
        <div className="w-full max-w-435px">
          <div className="w-full flex justify-center">
            <img src={GolfHole} alt="img" />
          </div>
          <p className="text-18/25 text-4A4A4A opacity-40 font-medium text-center mt-[60px]">
            We assume you meant to, but if not just login again and get back to drumming up business for your course.
          </p>
          <p className="text-18/25 text-4A4A4A font-medium text-center mt-6">
            <span className="opacity-40">Any questions for us?</span>
            <a className="text-6EB629 ml-1" href="mailto:headpro@golfbevy.com">
              headpro@golfbevy.com
            </a>
          </p>
        </div>
      </div>
    </Page>
  )
}