import React, { FormEvent } from 'react'

import { PasswordComponent } from '../../Common/PasswordComponent'
import MainBg from '../../../assets/img/SignUpBg.png'

type PropsType = {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isInputValueVisible: boolean
  setInputValueVisible: (args: boolean) => void
  isPasswordValid: boolean
  loading: boolean
}

export const NewPassword: React.FC<PropsType> =
  ({
     handleSubmit,
     handleInputChange,
     isInputValueVisible,
     setInputValueVisible,
     isPasswordValid,
     loading
   }) => {
    return (
      <section
        style={{
          background: `url(${MainBg})`,
          backgroundSize: 'cover',
          display: 'flex',
          position: 'relative',
          marginBottom: '12px',
          height: '540px'
        }}
      >
        <article className="max-w-600px w-full relative pt-48 px-85px">
          <div className="bg-black absolute left-0 top-0 w-full h-full opacity-50" />
          <h1 className="relative signUp__title mb-3">Almost There!</h1>
          <p className="relative text-18/22 mb-6 text-white">
            Once you reset your password we’ll log you in to the Operator Portal.
          </p>
        </article>
        <div className="relative w-full">
          <div className="bg-white absolute left-0 top-0 w-full h-full opacity-90" />
          <PasswordComponent
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            isInputValueVisible={isInputValueVisible}
            setInputValueVisible={setInputValueVisible}
            isPasswordValid={isPasswordValid}
            inputClass="h-52px"
            title="Reset Your Password"
            belowTitleText="Enter a new password below."
            inputLabel=""
            name="password"
            loading={loading}
          />
        </div>
        <div className="absolute left-0 -bottom-3 bg-81A46A w-full h-3" />
      </section>
    )
  }
