import { GolfAppHeader } from '../../Layouts/GolfAppLayout/GolfAppHeader'
import React from 'react'
import { Desktop } from './Desktop'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { Mobile } from './Mobile'
import { useLocation } from 'react-router-dom'
import { MobilePlaceholder } from '../../Layouts/GolfAppLayout/MobilePlaceholder'

export const AcceptedInvitationPage: React.FC = () => {
  const screenSize = useScreenSize()
  const { search } = useLocation()
  const showMobile = new URLSearchParams(search).get('showMobile')
  const email = new URLSearchParams(search).get('email')
  const logo = new URLSearchParams(search).get('logo')
  const name = new URLSearchParams(search).get('username')
  const courseTitle = new URLSearchParams(search).get('course_title')

  const [inviteLoading, invite] = [false, {
    first_name: name?.split(' ')[0] || '',
    last_name: name?.split(' ')[1] || '',
    email: email || '',
    job_title: '',
    course: { logo: logo || '', title: courseTitle || '', is_private: false }
  }]

  return (
    <div className="relative width__container">
      <GolfAppHeader user={invite || {}} loading={inviteLoading} disabledDropdown />
      {screenSize === 'sm' ?
        (
          showMobile ? <Mobile /> : <MobilePlaceholder />
        ) :
        <Desktop />
      }
    </div>
  )
}