import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CustomCheckbox } from '../../Common/CustomCheckbox'
import { GolfInput } from '../Components/GolfInput'
import { GolfSelect } from '../Components/GolfSelect'
import { GolfTextarea } from '../Components/GolfTextarea'
import { TextAreaBottomCharsCount } from '../Components/small/TextAreaBottomCharsCount'
import { InputUploadImage } from '../Components/InputUploadImage'

import { eventTypes } from './configs'
import { EventPreviews } from './EventForm/EventPreviews'
import { Event } from '../../../models/Event'
import { InputDoubleCalendar } from '../Components/InputDoubleCalendar'
import { CustomButton } from '../../Common/CustomButton'
import { EVENTS } from '../../../constants/urls'
import { ConfirmSuspendingDialog } from '../Components/dialogs/ConfirmSuspendingDialog'
import { IdContainer } from '../../Common/IdContainer'
import { useNavigate } from 'react-router-dom'
import { fileListToImage } from '../../../helpers/image'
import { AsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { useApi } from '../../../hooks/api/useApi'
import { updateEventStatus } from '../../../store/event/actions'
import { UserCourse } from '../../../models/UserCourse'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { doubleDateValidator } from '../../../helpers/date'
import { useSelectOptions } from '../../../hooks/useSelectOptions'
import { urlValidator } from "../../../helpers/url";

type PropTypes = {
  editing?: boolean
  event?: Event
  onSubmitThunk: AsyncThunk<any, any, any>
  course?: UserCourse
}


const validationSchema = Yup.object().shape({
  eventDate: doubleDateValidator,
  publishDate: doubleDateValidator.test(
    'start',
    'Publish date start cannot be after event date start',
    function(item) {
      const validator = Yup.date().max(this.parent.eventDate.start)
      return validator.isValidSync(item.start)
    }),
  signup_link: urlValidator
})

export const EventForm: React.FC<PropTypes> = ({ editing, event, onSubmitThunk, course }) => {
  const [suspendDialogOpened, setSuspendDialogOpened] = useState<boolean>(false)
  const [publishing, onSubmit] = useApi(onSubmitThunk)
  const [updateStatusLoading, updateStatus] = useApi(updateEventStatus)

  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors }
  } = useForm<Event>({
    defaultValues: useMemo(() => event ? { ...event, type: `${event.type}` } : {}, [event]),
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    reset(event ? { ...event, type: `${event.type}` } : {})
  }, [event])

  const onFormSubmit: SubmitHandler<Event> = (data) => {
    onSubmit(_.omit(data, ['status']))
    .then(({ error, payload }) => {
      if (!error) {
        return navigate(EVENTS)
      }
      if (payload && typeof payload === 'object') {
        Object.entries(payload)
        .forEach((keyValue) => {
          // @ts-ignore
          setError(keyValue[0], { message: keyValue[1].join(',') })
        })
      }
    })
  }

  const [
    name,
    description,
    image,
    oneDayEvent,
    type,
    publishDate,
    signupLink,
    eventDate
  ] = watch(['name', 'description', 'image', 'is_one_day_activity', 'type', 'publishDate', 'signup_link', 'eventDate'])

  const allRequiredFieldsAreFilled = useMemo(() => {
    const stringRequiredFields = [name, description, type]
    const stringRequiredFieldsAreFilled = stringRequiredFields
    .map((field) => field && field.toString().length > 0)
    .every((field) => !!field)

    const datesRequiredField = [eventDate, publishDate]
    const datesRequiredFieldsAreFilled = datesRequiredField
    .map((field) => field && field.start && field.start.length > 0)
    .every((field) => !!field)

    return datesRequiredFieldsAreFilled && stringRequiredFieldsAreFilled
  }, [name, description, type, eventDate, publishDate])

  const setType = (type: string) => setValue('type', type)

  const titleTextMax = 80
  const eventDescriptionTextAreaMax = 1000

  const previewURL = useMemo(() => {
    return fileListToImage(image)?.url
  }, [image])

  const confirmSuspending = () => {
    updateStatus({ event, suspended: !event?.suspended })
    .then(() => {
      setSuspendDialogOpened(false)
    })
  }

  useEffect(() => {
    if (oneDayEvent) {
      setValue('eventDate', { start: eventDate?.start, end: eventDate?.start })
    }
  }, [oneDayEvent])

  return (
    <div className="w-full flex pl-146px pr-81px pt-9">
      <form onSubmit={handleSubmit(onFormSubmit)} className="max-w-587px w-full mr-70px disabled">
        <div className="mb-8">
          <InputDoubleCalendar
            control={control}
            name="eventDate"
            firstName="start"
            secondName="end"
            firstDateLabel="Event Date Start"
            secondDateLabel="Event Date End"
            required={true}
            isError={errors.eventDate}
            disabled={event?.suspended}
            disabledSecondDate={oneDayEvent}
          />
          <div className="mt-3">
            <CustomCheckbox
              isCheckboxChecked={oneDayEvent}
              checkMarkColor="green"
              text="This Event is only for 1 day"
              name="is_one_day_activity"
              register={register}
              htmlFor="is_one_day_activity"
              textClass="ml-2 text-14/16"
              disabled={event?.suspended}
            />
          </div>
        </div>
        <div className="max-w-180px w-full mb-3.5 relative">
          <GolfSelect
            className="text-15/18 h-40px border-979797"
            name="type"
            text="Event Type*"
            selectedOption={type}
            setSelectedOption={setType}
            isError={errors.type}
            register={register}
            placeholder="Select"
            optionsModalClass="h-24 top-16"
            isHoverBg
            optionClass="h-8 py-1"
            disabled={event?.suspended}
            {...useSelectOptions(eventTypes)}
          />
        </div>
        <div className="w-full mb-3.5">
          <GolfInput
            inputClass="w-full"
            label="Event Name*"
            htmlFor="name"
            name="name"
            isError={errors.name}
            register={register}
            labelText="5 Character Min"
            maxLength={titleTextMax}
            disabled={event?.suspended}
          />
          <div className="text-right text-15/18 text-333333 opacity-50">
            <span>{name?.length || 0}</span>/<span>{titleTextMax}</span>
          </div>
        </div>
        <div className="w-full mb-3.5">
          <GolfTextarea
            text="Event Description*"
            labelText="80 Character Min"
            className="h-95px"
            name="description"
            isError={errors.description}
            register={register}
            disabled={event?.suspended}
          />
          <TextAreaBottomCharsCount
            maxTextLength={eventDescriptionTextAreaMax}
            messageLength={description?.length || 0}
          />
        </div>
        <div className="w-full">
          <InputUploadImage
            labelText="Standard image will appear unless another uploaded."
            register={register}
            name="image"
            value={image}
            setValue={(value: FileList | undefined) => setValue('image', value)}
            rightButton={false}
            disabled={event?.suspended}
          />
        </div>
        <div className="w-full mt-4 mb-8">
          <GolfInput
            inputClass="w-full"
            label="Content Link"
            htmlFor="signup_link"
            name="signup_link"
            isError={errors.signup_link}
            register={register}
            labelText="Where the golfer signs up for your event"
            required={false}
            disabled={event?.suspended}
          />
        </div>
        <div className="w-full">
          <span className="block text-333333 text-15/18 font-bold">Publish Date</span>
          <span className="inline-block text-right text-11/13 text-333333 opacity-50 mb-4">
            When you want this Event to show up in the Feed
          </span>
          <InputDoubleCalendar
            control={control}
            name="publishDate"
            firstName="start"
            secondName="end"
            firstDateLabel="Publish Date To Feed Start"
            secondDateLabel="Publish Date To Feed End"
            required={true}
            isError={errors.publishDate}
            position="top"
            disabled={event?.suspended}
          />
        </div>
        <div className="flex justify-between mt-14">
          <div className="flex items-center">
            <CustomButton className="secondary-button" text="< Back" link={EVENTS} />
          </div>
          <div>
            {editing && (
              <CustomButton
                text={event?.suspended ? 'Unsuspend Event' : 'Suspend Event'}
                className="text-16/19 text-C43100 font-medium mr-50px"
                onClick={() => setSuspendDialogOpened(true)}
              />
            )}
            <CustomButton
              className="py-2 px-7 main-button"
              text="Publish Event"
              type="submit"
              disabled={!allRequiredFieldsAreFilled}
              loading={publishing}
            />
          </div>
        </div>
      </form>
      <div className="flex flex-col justify-center items-center max-w-225px w-full overflow-hidden">
        <IdContainer name="Event" id={event?.id?.toString() || '000001'} className="mb-10" />
        <EventPreviews
          imageUrl={previewURL}
          type={eventTypes.find(({ id }) => type === id)?.name}
          name={name}
          date={publishDate && publishDate.start ? new Date(publishDate.start) : undefined}
          contentLink={signupLink}
          description={description}
          course={course}
        />
      </div>
      <ConfirmSuspendingDialog
        title={`Are You Sure You Want To ${event?.suspended ? 'Unsuspend' : 'Suspend'} This Event?`}
        submitText={event?.suspended ? 'Unsuspend' : 'Suspend'}
        opened={suspendDialogOpened}
        onReject={() => setSuspendDialogOpened(false)}
        onSubmit={confirmSuspending}
        suspending={updateStatusLoading}
      />
    </div>
  )
}
