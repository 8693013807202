import React, { useEffect, useRef } from 'react'
import MaskedInput from 'react-text-mask'
import { Control, Controller, FieldError } from 'react-hook-form'

import USA_flag from '../../assets/img/USA_FlagSmall.png'
import { phoneNumberMask } from '../../constants/phoneMask'

type PropsType = {
  isError?: FieldError | undefined
  name: string
  inputClass: string
  inputErrorClass: string
  flagBoxClass: string
  id?: string
  control: Control<any, object>,
  flagBoxPosition?: string
  countryCode?: string
  mask?: any
}

export const InputPhoneMask: React.FC<PropsType> =
  ({
     isError,
     inputClass,
     inputErrorClass,
     flagBoxClass,
     name,
     control,
     flagBoxPosition = 'top',
     countryCode = '+1',
     mask = phoneNumberMask
   }) => {
    const localRef = useRef<any>()

    useEffect(() => {
      if (localRef.current) {
        localRef.current.inputElement.value += ' '
      }
    }, [localRef.current])

    return (
      <div className="relative mb-4">
        <div
          className={`absolute h-40px left-2.5 ${flagBoxPosition}-0 flex items-center border-r border-solid ${flagBoxClass}`}
        >
          <img className="inline-block" src={USA_flag} alt="flag" />
          <span className="text-979797 text-base pl-1 pr-2">{countryCode}</span>
        </div>
        <Controller
          control={control}
          name={name}
          defaultValue=""
          rules={{
            required: true,
            minLength: 14
          }}
          render={({ field: { onChange, value, name, ref } }) => (
            <MaskedInput
              className={`px-3 py-1.5 h-40px pl-20 mt-1 ${
                isError ? `${inputErrorClass}` : `${inputClass}`
              }`}
              onChange={onChange}
              value={value}
              name={name}
              guide={false}
              mask={mask}
              ref={(e) => {
                ref(e)
                localRef.current = e
              }}
            />
          )}
        />
      </div>
    )
  }
