import React from 'react'
import { ReactComponent as Desktop } from '../../../assets/svg/desktop.svg'

export const MobilePlaceholder: React.FC = () => {
  return (
    <section className="mobile-placeholder-container">
      <div>
        <figure className="w-full flex justify-center">
          <Desktop />
        </figure>
        <div className="w-full flex items-center flex-col mt-[38px]">
          <p className="text-center text-[#000000] font-semibold text-[30px] leading-[36px]">
            FORE!
          </p>
          <p className="max-w-[281px] text-center text-[#000000] font-semibold text-[30px] leading-[36px]">
            Please open the Operator Portal on a desktop device
          </p>
        </div>
      </div>
      <div className="font-medium text-16/19 flex justify-center">
        <span className="text-9C9C9C mr-1">Need help?</span>
        <a href="mailto:headpro@golfbevy.com" className="no-underline text-52A91A">headpro@golfbevy.com</a>
      </div>
    </section>
  )
}