import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { GolfInput } from '../Components/GolfInput'
import { EMAIL_PATTERN } from '../../../helpers/email'

type PropsType = {
  index: number
  register: UseFormRegister<any>
  isCheckboxActive?: boolean
  errors: any
  last: boolean
  disabled: boolean
}

export const OneTeammateInfo: React.FC<PropsType> =
  ({
     index,
     register,
     errors,
     last,
     disabled
   }) => {
    const error = last ? {} : ((errors.teammates && errors.teammates[index]) || {})
    return (
      <div className="mb-10">
        <div className="flex justify-between mb-8">
          <div className="max-w-365px w-full">
            <GolfInput
              label="First Name*"
              name={`teammates[${index}][first_name]`}
              htmlFor={`teammates[${index}][first_name]`}
              register={register}
              isError={error.first_name}
              required={false}
              disabled={disabled}
              options={!last ? {
                required: 'First Name is required',
                minLength: { value: 2, message: 'Must have at least 2 characters' }
              } : undefined}
            />
          </div>
          <div className="max-w-365px w-full">
            <GolfInput
              label="Last Name*"
              name={`teammates[${index}][last_name]`}
              htmlFor={`teammates[${index}][last_name]`}
              register={register}
              isError={error.last_name}
              required={false}
              disabled={disabled}
              options={!last ? {
                required: 'Last Name is required',
                minLength: { value: 2, message: 'Must have at least 2 characters' }
              } : undefined}
            />
          </div>
        </div>
        <div className="mb-8">
          <GolfInput
            label="Teammate's job title"
            name={`teammates[${index}][title]`}
            htmlFor={`teammates[${index}][title]`}
            register={register}
            isError={error.title}
            required={false}
            disabled={disabled}
            options={!last ? {
              minLength: { value: 2, message: 'Must have at least 2 characters' },
              required: false
            } : undefined}
          />
        </div>
        <div className="mb-3">
          <GolfInput
            label="Teammate's Email Address*"
            labelText="The teammate will have to validate their email"
            name={`teammates[${index}][email]`}
            htmlFor={`teammates[${index}][email]`}
            register={register}
            isError={error.email}
            required={false}
            disabled={disabled}
            options={!last ? {
              required: 'Email is required',
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Enter a valid email'
              }
            } : undefined}
          />
        </div>
      </div>
    )
  }
