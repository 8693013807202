import React from 'react'

export const DoubleFields: React.FC<{ children: JSX.Element[] }> = ({ children }) => {
  return (
    <div className="flex justify-between w-full max-w-[657px] mt-2">
      <div className="w-full max-w-300px">
        {children[0]}
      </div>
      {children[1] && <div className="w-full max-w-300px">
        {children[1]}
      </div>}
    </div>
  )
}