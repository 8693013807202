import { createAsyncThunk } from '@reduxjs/toolkit'
import { sleep } from '../../utils/sleep'
import { invite } from '../../mocks/invite.mock'
import request from '../../utils/agent'

export const getInviteInfo = createAsyncThunk('user/invite/get',
  async (token: string) => {
    await sleep(1000)
    return invite
  }
)
export const approveInvite = createAsyncThunk('user/invite/approve',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: `operator_portal/team_members/activate/`,
        data
      })

      localStorage.setItem('authToken', response.data.token)

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)