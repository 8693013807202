import React from 'react'
import { ReactComponent as Step1 } from '../../../assets/svg/signUpProgress/step1.svg'
import { ReactComponent as Step2 } from '../../../assets/svg/signUpProgress/step2.svg'
import { ReactComponent as Step3 } from '../../../assets/svg/signUpProgress/step3.svg'
import { SignUpProgress } from '../../../constants/pagesProgress'

export const SignUpProgressStatus: React.FC<{ progress: string }> = ({ progress }) => {
  return (
    <div className="relative w-full flex justify-center">
      <Step progress={progress} />
    </div>
  )
}

const Step: React.FC<{ progress: string }> = ({ progress }) => {
  switch (progress) {
    case SignUpProgress.SignUpMain: {
      return <Step1 />
    }
    case SignUpProgress.SignUpMobile: {
      return <Step1 />
    }
    case SignUpProgress.SignUpMobileText: {
      return <Step2 />
    }
    case SignUpProgress.SignUpValidationQuestions: {
      return <Step1 />
    }
    case SignUpProgress.SignUpMobileCode: {
      return <Step2 />
    }
    case SignUpProgress.SignUpCreatePassword: {
      return <Step3 />
    }
  }
  return <Step3 />
}