import React, { useEffect, useState } from 'react'
import { CourseInternalPage } from './CourseInternalPage'
import { CourseHistoryForm } from './CourseHistoryForm'
import { useDispatch, useSelector } from 'react-redux'
import { getArchitects, getHistory, updateHistory } from '../../../store/course/actions'
import { usePaginatedApi } from '../../../hooks/api/usePaginatedApi'
import { useApi } from '../../../hooks/api/useApi'

export const CourseHistoryPage: React.FC = () => {
  const [loading, onFormSubmit] = useApi(updateHistory)
  const store = useSelector((state: any) => state.course)
  const dispatch = useDispatch()
  const [architectSearch, setArchitectSearch] = useState<string>()
  const architectsPagination = usePaginatedApi(getArchitects)
  const searchArchitectsPagination = usePaginatedApi(getArchitects, architectSearch)

  const [history, setHistory] = useState(store.history)

  useEffect(() => {
    if (store.architects === undefined) {
      dispatch(getHistory())
    }
  }, [])


  useEffect(() => {
    setHistory(store.history)
  }, [store.history])

  const onArchitectSearch = (search: string) => {
    setArchitectSearch(search)
  }

  return (
    <CourseInternalPage title="Course History">
      <CourseHistoryForm
        architectsPagination={architectsPagination}
        history={history}
        onSubmit={onFormSubmit}
        onArchitectSearch={onArchitectSearch}
        loading={loading}
        searchArchitectPagination={searchArchitectsPagination}
      />
    </CourseInternalPage>
  )
}
