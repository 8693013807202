import { useDispatch, useSelector } from 'react-redux'

export const useEntityOrGet = (sliceName: string, entityName: string, method: Function) => {
  const store = useSelector((state: any) => state[sliceName])
  const dispatch = useDispatch()

  const entity = store[entityName]

  if (entity === undefined) {
    dispatch(method)
  }

  return entity
}