import React from 'react'
import { CustomButton } from '../../Common/CustomButton'
import Info from '../../../assets/svg/info.svg'
import Crosses from '../../../assets/svg/crosses-small.svg'
import { ReactComponent as Letter } from '../../../assets/svg/letter.svg'

type PropsType = {
  isOpen: boolean
  onClose: () => void
  onConfirmed: () => void
  loading: boolean
  isSuccess: boolean
}

export const ResendInviteModal: React.FC<PropsType> = ({ isOpen, onClose, onConfirmed, loading, isSuccess }) => {

  if (!isOpen) {
    return <></>
  }

  return (
    <Container onClose={onClose}>
      {isSuccess ?
        <SuccessContent /> :
        (<>
          <div className="max-w-294px w-full pt-4 px-6 pb-6 text-center">
            <p className="text-17/22 font-sfui font-medium text-center">
              Resend Invitation
            </p>
            <p className="text-13/15 font-sfui mt-2">
              Teammate has not validated their email so invitation has expired. To resend the invitation, click below.
            </p>
          </div>
          <div className="flex justify-center p-[11px] border-t-1.5 border-solid border-4A4A4A">
            <CustomButton
              className="text-52A91A text-18/22 text font-sfui"
              text="Resend"
              onClick={onConfirmed}
              loading={loading}
            />
          </div>
        </>)
      }
    </Container>
  )
}

const Container: React.FC<{ onClose: () => void }> = ({ children, onClose }) => {
  return (
    <div className="absolute w-full h-full top-0 left-0">
      <div className="modal">
        <div className="flex justify-between p-2">
          <img src={Info} alt="" />
          <img src={Crosses} alt="" className="cursor-pointer" onClick={onClose} />
        </div>
        {children}
      </div>
    </div>
  )
}

const SuccessContent: React.FC = () => {
  return (
    <div className="max-w-294px w-full px-6 pb-11">
      <figure className="flex justify-center mb-18px">
        <Letter />
      </figure>
      <p className="text-17/22 font-sfui text-center tracking-[-0.4px]">
        Your invitation has been resent
      </p>
    </div>
  )
}