import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { CustomButton } from '../../Common/CustomButton'
import { Input } from '../../Common/Input'
import { CustomCheckbox } from '../../Common/CustomCheckbox'

import { AppDispatch } from '../../../store/store'
import { AnyAction } from '@reduxjs/toolkit'
import { getDomain, URL_PATTERN } from '../../../helpers/url'
import { SignUpProgress } from '../../../constants/pagesProgress'
import { useApiDispatch } from '../../../hooks/api/useApiDispatch'
import { startRegistration } from '../../../store/auth/actions'
import { useLoading } from '../../../hooks/api/useLoading'
import { setIsApproved } from "../../../store/user/userSlice";

type Inputs = {
  course_url: string
  first_name: string
  last_name: string
  email: string
  privacy_policy: boolean
}

type PropsType = {
  dispatch: AppDispatch
  setProgress: (progress: string) => AnyAction
  nextProgress: string
}

const validationSchema = Yup.object().shape({
  course_url: Yup.string()
  .matches(URL_PATTERN, 'This URL is not valid')
  .required('Course url is required'),
  first_name: Yup.string()
  .required('First name is required')
  .min(2, 'Must have at least 2 characters'),
  last_name: Yup.string()
  .required('Last name is required')
  .min(2, 'Must have at least 2 characters'),
  email: Yup.string().required('Email address is required')
})

export const RegistrationFrom: React.FC<PropsType> = ({ dispatch, setProgress, nextProgress }) => {
  const sendMainData = useApiDispatch(startRegistration)
  const loading = useLoading(startRegistration)
  const {
    handleSubmit,
    register,
    watch,
    setError,
    formState: { errors }
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema)
  })
  const [isSubmitCheckBoxActive, email, url] = watch(['privacy_policy', 'email', 'course_url'])

  const emailFromOtherDomain = useMemo(() => {
    const emailDomain = email?.split('@').reverse()[0]
    const urlDomain = url && getDomain(url, true)
    return email && url ? urlDomain !== emailDomain : false
  }, [email, url])


  const emailWarning = useMemo(() => {
    return emailFromOtherDomain ? 'If you don’t have an email that matches the URL domain, we’ll need extra validation.' : undefined
  }, [emailFromOtherDomain])

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    sendMainData(data)
    .then(({ error, payload }) => {
      if (error) {
        const errors = payload?.errors || {}
        if (payload === 'There is no course with such URL') {
          errors['course_url'] = [payload]
        }
        if (payload === 'Email already in use') {
          errors['email'] = [payload]
        }
        Object.entries(errors)
        .forEach((keyValue) => {
          // @ts-ignore
          setError(keyValue[0], { message: keyValue[1].join(',') })
        })
      } else {
        if (payload.user.identity_verify_status === 'Not needed') {
          dispatch(setProgress(nextProgress))
        } else {
          dispatch(setIsApproved(false))
          dispatch(setProgress(SignUpProgress.SignUpValidationQuestions))
        }
      }
    })
  }

  const submitDisabled = !isSubmitCheckBoxActive || Object.keys(errors).length > 0

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pl-140px pr-120px pt-60px relative">
      <div className="mb-18px">
        <Input
          name="course_url"
          inputClass="mb-2.5"
          label="Course URL*"
          placeholder="domain.com"
          htmlFor="course"
          error={errors.course_url}
          register={register}
        />
        <p className="text-14/20">
          If you manage more than one course, contact us at
          <a className="text-6EB629" href="mailto:headpro@golfbevy.com">
            {' '}
            headpro@golfbevy.com
          </a>
        </p>
      </div>
      <div className="flex justify-between mb-18px">
        <div className="max-w-255px w-full mr-2">
          <Input
            name="first_name"
            label="First Name*"
            htmlFor="first_name"
            error={errors.first_name}
            register={register}
          />
        </div>
        <div className="max-w-255px w-full">
          <Input
            name="last_name"
            label="Last Name*"
            htmlFor="last_name"
            error={errors.last_name}
            register={register}
          />
        </div>
      </div>
      <div className="mb-18px">
        <Input
          name="email"
          label="Work Email*"
          htmlFor="workEmail"
          inputClass="mb-2.5"
          error={errors.email}
          register={register}
          warning={emailWarning}
        />
        <p className="text-9C9C9C text-12/14">
          Best if same as course URL. We'll send a validation code that will expire shortly.Webmail
          and management company domains require additional validation.
        </p>
      </div>
      <div className="flex items-center text-14/20 mb-12">
        <div>
          <CustomCheckbox
            className="mr-3"
            name="privacy_policy"
            htmlFor="privacy_policy"
            textClass="text-base text-black"
            checkMarkColor="green"
            register={register}
            isCheckboxChecked={isSubmitCheckBoxActive}
          />
        </div>
        <p>
          By continuing to register you agree to our
          <span className="text-6EB629 cursor-pointer">
            {' '}
            <Link to="/terms-of-conditions">Terms of Service and Privacy Policy.</Link>
          </span>
        </p>
      </div>
      <div className="flex justify-end">
        <CustomButton
          className={`py-4 px-68px text-20/24 font-bold text-white ${
            !submitDisabled ? 'bg-52A91A' : 'bg-52A91A/30'
          }`}
          disabled={submitDisabled}
          text="Submit"
          type="submit"
          loading={loading}
        />
      </div>
    </form>
  )
}
