import { createSlice } from '@reduxjs/toolkit'
import { FeedOffer, GridOffer, Offer } from '../../models/Offer'
import {
  createOffer, deleteFeedOffer, deleteGridOffer,
  deleteOffer,
  editOffer,
  getFeedOffers, getGridOffers,
  getOffers,
  publishOfferToFeed, publishOfferToGrid, republishFeedOffer, unpublishFeedOffer, updateFeedOffer,
  updateOfferStatus
} from './actions'

type OfferState = {
  offers?: Offer[]
  feedOffers?: FeedOffer[]
  gridOffers?: GridOffer[]
}

const initialState: OfferState = {
  offers: undefined
}

const onUpdateOffer = (state: any, action: any) => {
  const offer = action.payload as Offer
  const offers = state.offers || []
  const offerIndex = offers.findIndex(({ id }: Offer) => id === offer.id)
  state.offers = [
    ...offers.slice(0, offerIndex),
    offer,
    ...offers.slice(offerIndex + 1, offers.length)
  ]
}

const onUpdateFeedOffer = (state: any, action: any) => {
  const offer = action.payload as FeedOffer
  const offers = state.feedOffers || []
  const offerIndex = offers.findIndex(({ id }: FeedOffer) => id === offer.id)
  state.feedOffers = [
    ...offers.slice(0, offerIndex),
    offer,
    ...offers.slice(offerIndex + 1, offers.length)
  ]
}

export const offerSlice = createSlice({
  name: 'offer',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getOffers.fulfilled, (state, action) => {
      state.offers = action.payload as Offer[]
    })
    .addCase(createOffer.fulfilled, (state, action) => {
      const offer = action.payload as Offer
      state.offers = [...state.offers || [], offer]
    })
    .addCase(editOffer.fulfilled, onUpdateOffer)
    .addCase(updateOfferStatus.fulfilled, onUpdateOffer)
    .addCase(deleteOffer.fulfilled, (state, action) => {
      const offerId = action.payload
      const offers = state.offers || []
      const offerIndex = offers.findIndex(({ id }: Offer) => id === offerId)
      state.offers = [
        ...offers.slice(0, offerIndex),
        ...offers.slice(offerIndex + 1, offers.length)
      ]
    })
    .addCase(getFeedOffers.fulfilled, (state, action) => {
      state.feedOffers = action.payload as unknown as FeedOffer[]
    })
    .addCase(publishOfferToFeed.fulfilled, (state, action) => {
      const offer = action.payload as FeedOffer
      offer.name = state.offers?.find(({ id }) => id === offer.offer)?.name || ''
      state.feedOffers = [...state.feedOffers || [], offer]
    })
    .addCase(unpublishFeedOffer.fulfilled, onUpdateFeedOffer)
    .addCase(republishFeedOffer.fulfilled, onUpdateFeedOffer)
    .addCase(updateFeedOffer.fulfilled, onUpdateFeedOffer)
    .addCase(deleteFeedOffer.fulfilled, (state, action) => {
      const offerId = action.payload
      const offers = state.feedOffers || []
      const offerIndex = offers.findIndex(({ id }: FeedOffer) => id === offerId)
      state.feedOffers = [
        ...offers.slice(0, offerIndex),
        ...offers.slice(offerIndex + 1, offers.length)
      ]
    })
    .addCase(getGridOffers.fulfilled, (state, action) => {
      state.gridOffers = action.payload as unknown as GridOffer[]
    })
    .addCase(publishOfferToGrid.fulfilled, (state, action) => {
      const offer = action.payload as GridOffer
      offer.name = state.offers?.find(({ id }) => id === offer.offer)?.name || ''
      state.gridOffers = [...state.gridOffers || [], offer]
    })
    .addCase(deleteGridOffer.fulfilled, (state, action) => {
      const offerId = action.payload
      const offers = state.gridOffers || []
      const offerIndex = offers.findIndex(({ id }: GridOffer) => id === offerId)
      state.gridOffers = [
        ...offers.slice(0, offerIndex),
        ...offers.slice(offerIndex + 1, offers.length)
      ]
    })
  }
})

export default offerSlice.reducer
