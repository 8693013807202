import React from 'react'

type PropsType = {
  className?: string
  containerClassName?: string
  title: string
}

export const PageTitle: React.FC<PropsType> = ({ className, containerClassName, title }) => {
  return (
    <div className={`flex justify-center items-center pb-7 ${containerClassName}`}>
      <h1 className={`text-25/30 font-bold ${className}`}>{title}</h1>
    </div>
  )
}
