import commonSlice from './common/commonSlice'
import userSlice from './user/userSlice'
import offerSlice from './offer/offerSlice'
import eventSlice from './event/eventSlice'
import courseSlice from './course/courseSlice'
import apiSlice from './api/apiSlice'
import inviteSlice from './invite/inviteSlice'
import authSlice from './auth/authSlice'

import { configureStore } from '@reduxjs/toolkit'


export const store = configureStore({
  reducer: {
    common: commonSlice,
    user: userSlice,
    offer: offerSlice,
    event: eventSlice,
    course: courseSlice,
    api: apiSlice,
    invite: inviteSlice,
    auth: authSlice
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
