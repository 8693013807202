import React from 'react'
import { useNavigate } from 'react-router-dom'

type PropsType = {
  title: string
  text: string
  link?: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>
  component?: React.FC<{ className: string }>
  type: 'private' | 'public' | 'all' | string
  isPrivate: boolean
  linkDisabled: boolean
}

export const CategoriesCard: React.FC<PropsType> = ({ title, text, icon, component, link, type, isPrivate, linkDisabled }) => {
  const Icon = icon
  const ComingSoonComponent = component
  const navigate = useNavigate()

  const openLink = () => {
    if (link && !linkDisabled) {
      navigate(link)
    }
  }

  if (type === 'private' && !isPrivate) {
    return <></>
  }
  
  if (type === 'public' && isPrivate) {
    return <></>
  }


  return (
    <li className="howItWorks__categoriesCard">
      <div>
        <figure className="w-85px h-85px bg-EEF1EB rounded-full mr-9 flex justify-center items-center">
          <Icon />
        </figure>
      </div>
      <article className={`relative ${ComingSoonComponent && 'pt-6'}`}>
        {ComingSoonComponent && <ComingSoonComponent className="absolute left-0 -top-1 text-10/10 px-1.5 py-5px" />}
        <h2
          className={`mb-1 text-18/22 text-52A91A font-bold ${link ? 'cursor-pointer' : ''}`}
          onClick={openLink}
        >
          {title}
        </h2>
        <p className="text-14/16 text-333333">{text}</p>
      </article>
    </li>
  )
}
