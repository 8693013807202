import React, { useEffect, useState } from 'react'

import { CustomButton } from '../../../Common/CustomButton'
import { CropImage } from '../../../Common/CropImage'
import { CustomCheckbox } from '../../../Common/CustomCheckbox'
import { UploadLogo } from '../../../Common/UploadLogo'

import MainBg from '../../../../assets/img/SignUpBg.png'
import { ReactComponent as EditPencil } from '../../../../assets/svg/editPencil.svg'
import { ReactComponent as Logo } from '../../../../assets/svg/headerLogo.svg'
import { AppDispatch } from '../../../../store/store'
import { useDispatch } from 'react-redux'
import { onImgChange } from '../../../../helpers/onImageChange'
import { SignUpProgressStatus } from '../SignUpProgressStatus'
import { SignUpProgress } from '../../../../constants/pagesProgress'
import { useApiDispatch } from '../../../../hooks/api/useApiDispatch'
import { attachImages } from '../../../../store/auth/actions'
import { useLoading } from '../../../../hooks/api/useLoading'
import { onImgDragAndDown } from "../../../../helpers/onImageDragAndDrop";

type PropsType = {
  dispatch: AppDispatch
  setFullScreenBg: (args: boolean) => void
  setProgress: (progress: string) => void
  nextProgress: string
  courseLogo: string
}

export const PhotoLogo: React.FC<PropsType> = ({ setFullScreenBg, setProgress, nextProgress, courseLogo }) => {
  const sendImages = useApiDispatch(attachImages)
  const loading = useLoading(attachImages)
  const dispatch = useDispatch()
  const [logoImg, setLogoImg] = useState<any>()
  const [photoImg, setPhotoImg] = useState<any>()
  const [isCheckboxChecked, setCheckboxChecked] = useState<boolean>(false)
  const [croppedLogo, setCroppedLogo] = useState<any>(null)
  const [croppedImage, setCroppedImage] = useState<any>(null)
  const [cropModalOpened, setCropModalOpened] = useState<boolean>(false)

  useEffect(() => {
    setCroppedLogo({url: courseLogo})
  }, [courseLogo])

  const isSubmit = (isCheckboxChecked || croppedImage) && croppedLogo
  const title = logoImg ? 'Upload Logo' : 'Upload Course Photo'

  const handleSubmit = () => {
    sendImages({
      logo: { image: croppedLogo?.file, name: logoImg?.name },
      photo: { image: croppedImage?.file, name: photoImg?.name }
    })
    .then((data) => {
      const {error, payload} = data
      if (!error || payload.skipped) {
        dispatch(setProgress(nextProgress))
      }
    })
  }

  const handleCheckboxClick = () => {
    setCheckboxChecked(!isCheckboxChecked)
  }

  const onUploadModalClose = () => {
    setCropModalOpened(false)
    setFullScreenBg(false)
  }

  useEffect(() => {
    if (logoImg || photoImg) {
      setCropModalOpened(true)
      setFullScreenBg(true)
    }
  }, [logoImg, photoImg])

  return (
    <section className="relative pt-10 pl-52 pr-64 flex justify-between">
      {cropModalOpened && (
        <CropImage
          className="uploadImage__modal"
          logoImg={logoImg?.url || ''}
          photoImg={photoImg?.url || ''}
          title={title}
          onUploadModalClose={onUploadModalClose}
          setCroppedImage={setCroppedImage}
          setCroppedLogo={setCroppedLogo}
        />
      )}
      <div>
        <div className="mb-4">
          <UploadLogo
            imageTitle="Course Logo*"
            imageBlockClass="w-140px h-130px"
            onImgChange={onImgChange}
            croppedLogo={croppedLogo?.url}
            isText={true}
            setLogoImg={setLogoImg}
            setPhotoImg={setPhotoImg}
            onImgDragAndDrop={onImgDragAndDown}
          />
        </div>
        <div className="max-w-330px w-full">
          <div className="flex mb-1">
            <span className="text-14/20 text-black">Course Picture</span>
            <label className="cursor-pointer ml-2" htmlFor="photo">
              <EditPencil />
              <input
                onChange={(event) => onImgChange(event, setLogoImg, setPhotoImg)}
                className="hidden"
                type="file"
                id="photo"
                accept="image/jpeg, image/png"
              />
            </label>
            <span className="text-11/20 text-808080 opacity-80 ml-1">
              PNG or JPG format and 20MB max
            </span>
          </div>
          <div className="relative max-w-330px full h-200px bg-white shadow-md mb-2 flex justify-center items-center">
            {croppedImage ? (
              <img
                className="absolute left-0 top-0 w-full h-200px object-cover"
                src={croppedImage?.url || '' as string}
                alt="img"
              />
            ) : (
              <>
                <img
                  className={`absolute left-0 top-0 w-full h-200px object-cover ${
                    !isCheckboxChecked && 'opacity-30'
                  }`}
                  src={MainBg as string}
                  alt="img"
                />
                {!isCheckboxChecked && (
                  <div className="relative">
                    <Logo className="w-100px opacity-30 mx-auto" />
                    <span className="block z-10 text-base text-898A8D tracking-3px uppercase">
                      Update picture
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex mb-10">
            <CustomCheckbox
              className="mr-3"
              onChange={handleCheckboxClick}
              isCheckboxChecked={isCheckboxChecked}
              name="submit"
              htmlFor="submit"
              textClass="text-base text-black"
              checkMarkColor="green"
            />
            <span className="text-12/14 text-808080">
            {`Check here to use a generic photo until you can upload your own. (Go to Operator Portal--> My Course)`}
          </span>
          </div>
        </div>
      </div>
      <div className="max-w-400px w-full">
        <SignUpProgressStatus progress={SignUpProgress.SignUpLogoPhoto} />
        <article className="mb-8 mt-89px">
          <h1 className="mb-5 signUp__component_title text-4A4A4A">Check these assets</h1>
          <p className="text-4A4A4A text-18/22 opacity-80">
            If these are not the right logo and photo for your course, click on them to replace.
          </p>
        </article>
        <div className="flex justify-start pl-1">
          <CustomButton
            onClick={handleSubmit}
            className={`py-4 px-18px text-20/24 font-bold text-white mb-6 ${
              isSubmit ? 'bg-52A91A' : 'bg-52A91A/30'
            }`}
            text="Yep, They're Good"
            disabled={!isSubmit}
            loading={loading}
          />
        </div>
      </div>
    </section>
  )
}
