import React from 'react'
import { Page } from './Components/Page'
import { useOutletContext } from '../Common/CustomContext'
import { ChangePasswordForm } from './ProfilePage/ChangePasswordForm'
import { MainForm } from './ProfilePage/MainForm'


export const ProfilePage: React.FC = () => {
  const { user } = useOutletContext()

  return (
    <Page title="My Profile" relative>
      <MainForm user={user} />
      <ChangePasswordForm />
    </Page>
  )
}
