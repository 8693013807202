import React from 'react'
import MobileAppImage from '../../../../assets/img/MobillAppMedium.png'

export const MobileAppMedium: React.FC = ({ children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${MobileAppImage})`,
        width: '247px',
        height: '498px',
        paddingTop: '62px',
        paddingBottom: '73px',
        paddingLeft: '13.5px',
        paddingRight: '13px'
      }}
    >
      <div className="w-full h-full bg-white">{children}</div>
    </div>
  )
}
