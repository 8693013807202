import { createAsyncThunk } from '@reduxjs/toolkit'
import request from '../../utils/agent'
import { fromEventDto, fromEventDtos, getEventStatus, toEventDto } from '../../helpers/event'
import { Event } from '../../models/Event'
import { EventStatus } from '../../models/EventStatus'


export const deleteEvent = createAsyncThunk(
  'events/delete',
  async (eventId: string | number, { rejectWithValue }) => {
    try {
      await request({
        method: 'DELETE',
        url: `operator_portal/activity/${eventId}/`
      })

      return eventId
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getEvents = createAsyncThunk(
  'events/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/activity/'
      })

      return fromEventDtos(response.data.results)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createEvent = createAsyncThunk(
  'events/create',
  async (event: Event, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/activity/',
        data: await toEventDto(event)
      })

      return fromEventDto(response.data)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const updateEvent = createAsyncThunk(
  'events/update',
  async (event: Event, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'PATCH',
        url: `operator_portal/activity/${event.id}/`,
        data: await toEventDto(event)
      })

      return fromEventDto(response.data)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const updateEventStatus = createAsyncThunk(
  'events/status/update',
  async ({ event, suspended }: { event: Event, suspended: boolean }, { rejectWithValue }) => {

    let status: EventStatus

    if (suspended) {
      status = EventStatus.SUSPENDED
    } else {
      status = getEventStatus(event)
    }

    try {
      const response = await request({
        method: 'PATCH',
        url: `operator_portal/activity/${event.id}/`,
        data: { status }
      })

      return fromEventDto(response.data)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)