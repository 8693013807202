import React from 'react'

type PropTypes = {
  text: string
  className?: string
}

export const InputLabel: React.FC<PropTypes> = ({ text, className }) => {
  return (
    <span
      className={`block text-11/13 sm:text-15/18  text-333333 font-bold text-333333 sm:leading-[30px] ${className ? className : ''}`}
    >
        {text}
      </span>
  )
}