import React, { useMemo, useState } from 'react'
import { Page } from './Components/Page'
import { AddCourseForm } from './AddCoursePage/AddCourseForm'
import { Course } from '../../models/Course'
import { useEntityOrGet } from '../../hooks/useEntityOrGet'
import { attachPictureToCourse, createCourse, getStates } from '../../store/course/actions'
import { AttachImages } from './AddCoursePage/AttachImages'
import { useApi } from '../../hooks/api/useApi'
import { Finish } from './AddCoursePage/Finish'

enum STEPS {
  MAIN,
  PICTURES,
  FINISH,
}

export const AddCoursePage: React.FC = () => {
  // TODO: We need implement pagination instead of hardcode page
  const states = useEntityOrGet('course', 'states', getStates({ page: 1, pageSize: 50, search: '' }))
  const [loadingMain, saveMain] = useApi(createCourse)
  const [loadingPictures, savePictures] = useApi(attachPictureToCourse)
  const [course, setCourse] = useState<any>()
  const [step, setStep] = useState<STEPS>(STEPS.MAIN)

  const mainStep = (course: Partial<Course>) => {
    setCourse(course)
    return saveMain(course)
  }

  const pictureStep = (data: any) => {
    setCourse({ ...course, ...data })
    return savePictures(data)
  }

  const currentStepContent = useMemo(() => {
    switch (step) {
      case STEPS.MAIN:
        return <AddCourseForm onSubmit={mainStep} states={states} onSuccess={() => setStep(STEPS.PICTURES)} />
      case STEPS.PICTURES:
        return <AttachImages onSubmit={pictureStep} onSuccess={() => setStep(STEPS.FINISH)} />
      case STEPS.FINISH:
        return <Finish course={course} addCourse={() => setStep(STEPS.MAIN)} />
      default:
        return 'No step'
    }
  }, [step, states, course])

  return (
    <Page title="Add Course">
      <div className="w-full flex justify-center">
        {currentStepContent}
      </div>
    </Page>
  )
}
