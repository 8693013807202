import React from 'react'
import { ReactComponent as Events } from '../../../assets/svg/golfAppEvents-small.svg'
import { ReactComponent as Calendar } from '../../../assets/svg/golfAppCalendar-small.svg'
import { ReactComponent as Offers } from '../../../assets/svg/golfAppOffers-small.svg'
import { ReactComponent as Passbook } from '../../../assets/svg/golfAppPassbook-small.svg'
import { ReactComponent as Team } from '../../../assets/svg/golfAppMyTeam-small.svg'
import { ReactComponent as Desktop } from '../../../assets/svg/desktop-small.svg'

const profitnessList = [
  {
    icon: <Events />,
    content: <p className="text-14/20">Promote <span className="text-52A91A">Events</span> at your course</p>
  },
  {
    icon: <Calendar />,
    content: (
      <p className="text-14/20">
        See when golfers who favorite your course are <span className="text-52A91A">available</span> to play
      </p>
    )
  },
  {
    icon: <Offers />,
    content: (
      <p className="text-14/20">
        Put <span className="text-52A91A">Offers</span> in their Feed
      </p>
    )
  },
  {
    icon: <Passbook />,
    content:
      (
        <div>
          <span
            className="rounded-[2px] border border-solid border-1A8805 text-1A8805 text-10/10 px-2 py-[5px] mb-1.5 inline-block">
            Coming Soon
          </span>
          <p className="text-14/20">
            Create coupons to our exclusive digital <span className="text-52A91A">Passbook</span>
          </p>
        </div>
      )
  },
  {
    icon: <Team />,
    content: (
      <p className="text-14/20">
        Add <span className="text-52A91A">team members</span> you want to manage these features
      </p>
    )
  }
]

export const Mobile: React.FC = () => {
  return (
    <>
      <section className="pt-8 px-9">
        <div className="pl-3 pr-2.5">
          <h1 className="font-semibold text-[30px] leading-[36px] text-center mb-18px">
            Great! You’re Ready To Roll
          </h1>
        </div>
        <p className="text-center text-14/20">
          Your GolfBevy account is set up at [Course Name].
        </p>
        <p className="text-center text-14/20 mb-18px">
          It’s time to start promoting your course.
        </p>
        <h2 className="text-center font-medium text-18/22 mb-5">
          Go to your Operator Portal to:
        </h2>
        {profitnessList.map((props, index) => <Profitness {...props} key={index} />)}
        <p className="text-15/18 h-[74px] leading-[74px] text-center">
          ...and much more
        </p>
      </section>
      <section className="h-[167px] bg-EEF1EB flex flex-col items-center justify-center px-9">
        <Desktop />
        <p className="text-15/18 leading-[20px] text-center max-w-[257px] mt-7 font-semibold">
          To go to My Portal, you have to log on to a desktop device.
        </p>
      </section>
      <section className="px-9 pt-[35px] pb-5">
        <p className="text-9C9C9C font-medium text-16/19 text-center">
          Need help?
          <a className="text-6EB629" href="mailto:headpro@golfbevy.com">
            {' '}
            headpro@golfbevy.com
          </a>
        </p>
      </section>
    </>

  )
}

const Profitness: React.FC<{ icon: any, content: any }> = ({ icon, content }) => {
  return (
    <div className="flex py-4 px-1 border-b-[3px] border-solid border-light-green">
      <div className="flex justify-start items-center w-full max-w-[71px]">
        {icon}
      </div>
      <div className="flex items-center">
        {content}
      </div>
    </div>
  )
}
