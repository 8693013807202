import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { CustomButton } from '../../Common/CustomButton'
import { Input } from '../../Common/Input'
import { sendResetPasswordEmail } from '../../../store/auth/actions'
import { useApiDispatch } from '../../../hooks/api/useApiDispatch'
import { useLoading } from '../../../hooks/api/useLoading'

export const PasswordResetPage: React.FC = () => {
  const sendEmail = useApiDispatch(sendResetPasswordEmail)
  const loading = useLoading(sendResetPasswordEmail)
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)


  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
    setSubmitted(false)
  }

  const emailError = email.length === 0 && submitted ? 'Email address is required' : ''

  const handleButtonClick = () => {
    setSubmitted(true)
    if (email.length > 0) {
      sendEmail(email)
    }
  }

  return (
    <>
      <span className="absolute bottom-8 left-11 text-6EB629 cursor-pointer">
        <Link to="/login">{'< '}Return to Login</Link>
      </span>
      <form className="max-w-520px w-full mx-auto relative pt-85px">
        <h1 className="signUp__component_title text-white mb-3.5">Reset Your Password</h1>
        <p className="text-18/22 text-white mb-70px">
          Please enter your email that you created your account with.
        </p>
        <div className="relative mb-8">
          <label htmlFor="password" className="cursor-pointer text-18/22 text-white">
            Email Address
          </label>
          <Input name="password" inputClass="pr-14 h-52px" placeholder="Email Address"
                 error={emailError ? { type: 'password', message: emailError } : undefined}
                 onChange={onEmailChange} />
        </div>
        <div className="flex justify-end">
          <CustomButton
            onClick={handleButtonClick}
            className="py-4 px-6 text-20/24 font-bold text-white bg-52A91A"
            text="Reset My Password"
            loading={loading}
          />
        </div>
      </form>
    </>
  )
}
