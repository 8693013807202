import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import ReactSlider from 'react-slider'

import { CustomButton } from './CustomButton'

import getCroppedImg from '../../helpers/canvasUtils'
import { ReactComponent as Crosses } from '../../assets/svg/crosses.svg'
import { ReactComponent as ZoomIn } from '../../assets/svg/zoomIn.svg'
import { ReactComponent as ZoomOut } from '../../assets/svg/zoomOut.svg'
import { handleZoomOut, handleZoomIn } from '../../helpers/photosLogos'

type PropsType = {
  logoImg?: unknown
  photoImg?: unknown
  photoName?: string
  title: string
  onUploadModalClose: () => void
  className: string
  setCroppedImage?: (img: unknown) => void
  setCroppedLogo?: (logo: unknown) => void
  cropSize?: { width: number, height: number }
  showBackground?: boolean
}

export const CropImage: React.FC<PropsType> = (
  {
    logoImg,
    photoImg,
    photoName,
    title,
    onUploadModalClose,
    className,
    setCroppedImage,
    setCroppedLogo,
    cropSize,
    showBackground = false
  }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<unknown>()

  const onCropComplete = (croppedArea: unknown, croppedAreaPixels: unknown) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const showCroppedImage = async () => {
    const img = logoImg ? logoImg : photoImg
    try {
      const croppedImage = await getCroppedImg(img as string, croppedAreaPixels)
      if (logoImg) {
        setCroppedLogo && setCroppedLogo(croppedImage)
        onUploadModalClose()
      }
      if (photoImg) {
        // @ts-ignore
        setCroppedImage && setCroppedImage({ ...croppedImage, name: photoName ? photoName : '' })
        onUploadModalClose()
      }
    } catch (e) {
      console.error('error', e)
    }
  }

  return (
    <Container showBackground={showBackground}>
      <div className={className}>
        <div className="flex justify-between px-5 py-6">
          <span className="text-20/24 text-333333">{title}</span>
          <Crosses onClick={onUploadModalClose} className="cursor-pointer" />
        </div>
        <div className={`h-450px relative ${logoImg ? 'cropAreaBlock' : 'cropAreaHidden'}`}>
          <Cropper
            image={logoImg ? (logoImg as string | undefined) : (photoImg as string | undefined)}
            crop={crop}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={false}
            cropSize={cropSize || (logoImg ? { width: 390, height: 390 } : { width: 550, height: 450 })}
          />
        </div>
        <div className="py-4 px-5 flex justify-between">
          <div className="max-w-330px w-full flex items-center justify-between">
            <ZoomOut onClick={handleZoomOut(setZoom, zoom)} className="cursor-pointer" />
            <ReactSlider
              step={1}
              min={1}
              max={3}
              className="range__line w-full h-3.5px pr-2 my-4 rounded-md cursor-grab max-w-255px cursor-pointer"
              thumbClassName="absolute w-9px h-9px cursor-grab bg-52A91A rounded-full -top-0.5"
              value={zoom}
              onChange={(value: number) => {
                setZoom(value)
              }}
            />
            <ZoomIn onClick={handleZoomIn(setZoom, zoom)} className="cursor-pointer" />
          </div>
          <div>
            <CustomButton
              onClick={showCroppedImage}
              className="py-2.5 px-12 bg-52A91A text-white font-bold"
              text="Upload"
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container: React.FC<{ showBackground: boolean }> = ({ showBackground, children }) => {
  if (!showBackground) {
    return <>{children}</>
  }

  return (
    <div className="absolute top-0 left-0 w-full h-full">
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-80 z-10" />
      {children}
    </div>
  )

}