import React from 'react'
import { PageTitle } from './small/PageTitle'

type PropsType = {
  title: string
  relative?: boolean
}

export const Page: React.FC<PropsType> = ({ title, children, relative }) => {
  return (
    <section className={`golfApp__section_100vh py-11 px-30px overflow-auto ${relative ? 'relative' : ''}`}>
      <PageTitle title={title} />
      {children}
    </section>
  )
}
