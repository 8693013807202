import React from 'react'
import { UseFormRegister } from 'react-hook-form'

import { ReactComponent as CheckMark } from '../../assets/svg/golfAppCheckMark.svg'
import { ReactComponent as CheckMarkGrey } from '../../assets/svg/golfAppCheckMarkGrey.svg'

type PropsType = {
  containerClassName?: string,
  className?: string
  textClass?: string
  name: string
  checkMarkColor: string
  register?: UseFormRegister<any>
  disabled?: boolean
  value: any
  options: { text: string, value: any }[]
}

export const CustomRadioButton: React.FC<PropsType> = (
  {
    containerClassName = 'flex',
    className,
    textClass,
    name,
    checkMarkColor,
    register,
    value,
    options,
    disabled = false
  }) => {
  const inputRegister = register && { ...register(name) }
  return (
    <div className={containerClassName}>
      {options.map(({ text, value: optionValue }) => {
        return (
          <Option
            className={className || ''}
            disabled={disabled}
            isSelected={optionValue === value}
            checkMarkColor={checkMarkColor}
            textClass={textClass || ''}
            inputRegister={inputRegister}
            value={optionValue}
            text={text}
            key={optionValue}
          />
        )
      })}
    </div>
  )
}

type OptionPropTypes = {
  className: string,
  disabled: boolean,
  isSelected: boolean,
  checkMarkColor: string,
  textClass: string,
  inputRegister: any,
  value: string | number,
  text: string
}
const Option: React.FC<OptionPropTypes> = (
  {
    className,
    disabled,
    isSelected,
    checkMarkColor,
    inputRegister,
    value,
    textClass,
    text
  }
) => {
  return (
    <div className={`relative ${className} golf-checkbox ${disabled ? 'disabled' : ''}`}>
      <label className="w-full h-full cursor-pointer flex items-center">
        <div className="relative w-15px h-15px border border-solid border-979797 bg-FAFAFA rounded-sm">
          {isSelected && (
            <>
              {checkMarkColor === 'green' && !disabled ? (
                <CheckMark className="absolute top-0 left-0.5" />
              ) : (
                <CheckMarkGrey className="absolute top-0.5 left-0.5" />
              )}
            </>
          )}
        </div>
        <input
          {...inputRegister}
          className="border-2 border-solid bolder-979797 hidden"
          type="radio"
          disabled={disabled}
          value={value}
        />
        <span className={`${textClass} w-fit`}>{text}</span>
      </label>
    </div>
  )
}