import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  progress: 'SignUpMain'
}


export const commonSlice = createSlice({
  name: 'common',
  initialState: initialState,
  reducers: {
    setProgress: (state, action) => {
      const result = action.payload
      state.progress = result
    }
  }
})

export const { setProgress } = commonSlice.actions
export default commonSlice.reducer
