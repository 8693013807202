export const handleZoomIn = (setZoom: (zoom: number) => void, zoom: number) => {
  return () => {
    if (zoom < 3) {
      setZoom(zoom + 1)
    }
  }
}

export const handleZoomOut = (setZoom: (zoom: number) => void, zoom: number) => {
  return () => {
    if (zoom !== 1) {
      setZoom(zoom - 1)
    }
  }
}
