import { useSelector } from 'react-redux'
import { RootState } from '../store/store'
import { getOffers } from '../store/offer/actions'
import { useApi } from './api/useApi'

export const useOffers = () => {
  const offers = useSelector((state: RootState) => state.offer.offers)
  const [loading, load] = useApi(getOffers)

  if (!offers && !loading) {
    load()
  }

  return offers || []
}
