import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { HOW_IT_WORKS } from '../constants/urls'

export const useOnlyUnauthorized = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const authToken = localStorage.getItem('authToken')
    if (authToken) {
      navigate(HOW_IT_WORKS)
    }
  }, [])
}