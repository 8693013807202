import React, { memo, useMemo } from 'react'

import { FieldError, UseFormRegister } from 'react-hook-form'

type PropsType = {
  label?: string
  htmlFor?: string
  placeholder?: string
  inputClass?: string
  type?: string
  name: string
  maxLength?: number
  labelText?: string
  required?: boolean
  register?: UseFormRegister<any>
  error?: FieldError
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  warning?: string
  labelClass?: string
}

export const Input: React.FC<PropsType> = memo(
  ({
     label,
     placeholder,
     htmlFor,
     inputClass,
     type,
     name,
     maxLength,
     labelText,
     register,
     required = true,
     error,
     warning,
     onChange,
     labelClass
   }) => {
    const inputRegister = register && register(name, { required })

    const additionalInputClass = useMemo(() => {
      if (error) {
        return 'registration__input_error'
      }
      if (warning) {
        return 'registration__input_warning'
      }
      return 'registration__input'
    }, [error, warning])

    return (
      <>
        <label className={`text-18 opacity-80 cursor-pointer ${labelClass}`} htmlFor={htmlFor}>
          {label}
        </label>
        {labelText && <span className="text-9C9C9C text-12/14 ml-2">{labelText}</span>}
        {error && <span className="text-C43100 text-12/14 ml-3">{error.message}</span>}
        {!error && warning && <span className="text-FFA800 text-12/14 ml-3">{warning}</span>}
        {
          <input
            onChange={onChange && onChange}
            {...inputRegister}
            className={`mt-1 px-3 py-1.5 h-40px ${additionalInputClass} ${inputClass}`}
            placeholder={placeholder}
            id={htmlFor}
            type={type}
            maxLength={maxLength}
          />
        }
      </>
    )
  }
)
