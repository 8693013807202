import React, { useEffect, useState } from 'react'
import { CustomDropdown } from '../../Common/CustomDropdown'
import { CustomButton } from '../../Common/CustomButton'
import Dots from '../../../assets/svg/dots.svg'

type PropTypes = {
  onEdit: () => void
  onRemove: () => void
  onResend: () => void
  onOpen: () => void
  onClose: () => void
  canResend: boolean
  canRemove: boolean
  canEdit: boolean
}

export const ActionButton: React.FC<PropTypes> = (
  { onEdit, onRemove, onResend, canResend, canRemove, canEdit, onOpen, onClose }
) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const onCallback = (method: () => void) => {
    setOpen(false)
    method()
  }

  useEffect(() => {
    if (isOpen) {
      return onOpen()
    }
  }, [isOpen])

  const atLeastOneActionAvailable = canRemove || canEdit || canResend

  if (!atLeastOneActionAvailable) {
    return <></>
  }

  return (
    <CustomDropdown
      isOpen={isOpen}
      setOpen={setOpen}
      onClose={onClose}
      classNames={{ bodyContainer: 'w-[109px] left-[-109px] cursor-default border border-solid border-[#E8E9E9] rounded' }}
    >
      {{
        header: <CustomButton className="w-5 flex justify-center" icon={Dots} />,
        body:
          <div className="flex flex-col px-[3px]">
            {canEdit &&
              <div className="w-full flex justify-center py-1.5">
                <CustomButton className="text-12/14 text-9C9C9C" text="Edit" onClick={() => onCallback(onEdit)} />
              </div>
            }

            {canResend &&
              <div className="w-full flex justify-center py-1.5">
                <CustomButton
                  className="text-12/14 text-9C9C9C"
                  text="Resend Invite"
                  onClick={() => onCallback(onResend)}
                />
              </div>
            }
            {canRemove &&
              <div className="w-full border-t border-solid border-[#E8E9E9] flex justify-center py-1.5">
                <CustomButton
                  className="text-12/14 text-C43100 font-sfui"
                  text="Remove"
                  onClick={() => onCallback(onRemove)}
                />
              </div>
            }

          </div>
      }}
    </CustomDropdown>
  )
}