import React from 'react'

import { ReactComponent as Dollar } from '../../../assets/svg/dollar.svg'

export const BuyItNowButton: React.FC = () => {
  return (
    <button className="border border-solid border-black px-4 py-2.5 rounded h-10 w-118px absolute">
      <div className="flex justify-between items-center">
        <Dollar />
        <span className="block text-black text-14/20 ml-2">Buy It Now</span>
      </div>
    </button>
  )
}
