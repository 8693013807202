export const fromUserDTO = (userDTO: any) => {
  const { identity_verify_status: verificationStatus } = userDTO

  const approvedStatuses = ["Not needed", "Approved"]

  return {
    ...userDTO,
    course: userDTO.active_course,
    is_approved: approvedStatuses.includes(verificationStatus)
  }
}