import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getMe, updateMyProfile } from './actions'
import { attachImages, attachPhoneNumber, startRegistration, validation } from '../auth/actions'
import { User } from '../../models/User'

type UserState = {
  user: {
    courseUrl: string,
    first_name: string,
    last_name: string,
    email: string,
    mobile: string,
    course: any
    is_approved: boolean
  },
  me?: User
  otp?: {
    pk: number
    email: string
  }
}

const initialState: UserState = {
  user: {
    courseUrl: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    course: {},
    is_approved: true
  },
  me: undefined,
  otp: undefined
}

const updateUser = (state: UserState, action: PayloadAction<any>) => {
  state.user = {...action.payload, is_approved: state.user.is_approved}
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserData: (state, action) => {
      const result = action.payload
      state.user = result
    },
    setIsApproved: (state, action) => {
      state.user.is_approved = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getMe.fulfilled, (state, action) => {
      state.me = action.payload as User
    })
    .addCase(updateMyProfile.fulfilled, (state, action) => {
      state.me = { ...state.me, ...action.payload as User }
    })
    .addCase(startRegistration.fulfilled, (state, action) => {
      const user = action.payload.user
      state.user = {...user, is_approved: state.user.is_approved, course: user.active_course}
      state.otp = action.payload.otp
    })
    .addCase(attachImages.fulfilled, updateUser)
  }
})

export const { setUserData, setIsApproved } = userSlice.actions
export default userSlice.reducer

export type UserType = typeof initialState.user
