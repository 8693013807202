import React, { useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import format from 'date-fns/format'

import { GolfInput } from '../Components/GolfInput'
import { DateTimeBlock } from '../Components/DateTimeBlock'
import { UploadLogo } from '../../Common/UploadLogo'
import { LongGreenCheckbox } from '../Components/LongGreenCheckbox'
import { CustomButton } from '../../Common/CustomButton'
import { CouponCard } from './CouponCard'
import { GolfTextarea } from '../Components/GolfTextarea'
import { TextAreaBottomCharsCount } from '../Components/small/TextAreaBottomCharsCount'

import { hours, minutes } from '../../../constants/minMaxTime'
import { textFieldsMaxLength } from '../../../constants/textFieldsMaxLength'
import { Page } from '../Components/Page'
import { useOnlySpecificType } from '../../../hooks/useOnlySpecificType'

type Inputs = {
  coupon: boolean
  course_name: string
  percent: string
  dollar: string
  restrictions: string
  promo_code: string
  code: boolean
  start_coupon_date: string
  end_coupon_date: string
  upload__logo: string
}

const isReady = false

export const Passbook: React.FC = () => {
  useOnlySpecificType('public')
  const [couponStartHoursValue, setCouponStartHoursValue] = useState<any>(hours.max)
  const [couponEndHoursValue, setCouponEndHoursValue] = useState<any>(hours.max)
  const [couponStartMinutesValue, setCouponStartMinutesValue] = useState<any>(minutes.max)
  const [couponEndMinutesValue, setCouponEndMinutesValue] = useState<any>(minutes.max)
  const [couponStartTimePeriod, setCouponStartTimePeriod] = useState('AM')
  const [couponEndTimePeriod, setCouponEndTimePeriod] = useState('AM')
  const [logoImg, setLogoImg] = useState<unknown>()

  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: {
      start_coupon_date: format(new Date(), 'LLL dd,yyyy'),
      end_coupon_date: format(new Date(), 'LLL dd,yyyy'),
      course_name: '',
      restrictions: ''
    }
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data)

  const watchFields = watch([
    'course_name',
    'restrictions',
    'start_coupon_date',
    'end_coupon_date',
    'coupon',
    'code'
  ])

  const isCodeCheckboxActive = getValues().code
  const isCouponCheckboxActive = getValues().coupon

  if (!isReady) {
    return (
      <Page title="GolfBevy Premium">
        <div className="flex">
          <p className="text-52A91A text-200/240 opacity-0.15 text-center mt-40">
            COMING SOON
          </p>
        </div>
      </Page>
    )
  }

  return (
    <section className="pt-18px">
      <h1 className="mainApp__pageTitle_25 w-full text-center mb-8">Passbook</h1>
      <div className="w-full pl-130px pr-100px flex justify-between">
        <form className="max-w-600px w-full" onSubmit={handleSubmit(onSubmit)}>
          <LongGreenCheckbox
            name="coupon"
            text="Coupon can only be used once"
            textClass="text-white ml-2 font-bold text-xs"
            checkMarkColor="gray"
            register={register}
            isCheckboxChecked={isCouponCheckboxActive}
          />
          <div className="w-full">
            <GolfInput
              inputClass="w-full"
              label="Course Name*"
              htmlFor="course_name"
              name="course_name"
              isError={errors.course_name}
              register={register}
            />
            <div className="text-right text-15/18 text-333333 opacity-50">
              <span>{watchFields[0]?.length}</span>
              <span>/{textFieldsMaxLength.passbook.course_name}</span>
            </div>
          </div>
          <div className="mb-5">
            <Controller
              name="upload__logo"
              control={control}
              render={({ field }) => {
                return (
                  <UploadLogo
                    editPencil={true}
                    imageBlockClass="w-95px h-85px border border-solid border-979797 rounded-5px"
                    onImgChange={field.onChange}
                    croppedLogo=""
                    isText={false}
                    setLogoImg={setLogoImg}
                    imageTitle="Course Logo*"
                  />
                )
              }}
            />
          </div>
          <DateTimeBlock
            className="mb-5"
            hourInputValue={couponStartHoursValue}
            setHourInputValue={setCouponStartHoursValue}
            minutesInputValue={couponStartMinutesValue}
            setMinutesInputValue={setCouponStartMinutesValue}
            timePeriods={couponStartTimePeriod}
            setTimePeriods={setCouponStartTimePeriod}
            inputCalendarText="Coupon Starts...*"
            inputCalendarName="start_coupon_date"
            control={control}
          />
          <DateTimeBlock
            className="mb-5"
            hourInputValue={couponEndHoursValue}
            setHourInputValue={setCouponEndHoursValue}
            minutesInputValue={couponEndMinutesValue}
            setMinutesInputValue={setCouponEndMinutesValue}
            timePeriods={couponEndTimePeriod}
            setTimePeriods={setCouponEndTimePeriod}
            inputCalendarText="Coupon Expires...*"
            inputCalendarName="end_coupon_date"
            control={control}
          />
          <div className="flex items-end mb-5">
            <div className="relative max-w-165px w-full">
              <GolfInput
                inputClass="text-center"
                label="Coupon Deal*"
                htmlFor="dollar"
                name="dollar"
                isError={errors.dollar}
                register={register}
                type="number"
              />
              <span className="absolute left-2.5 top-35px text-base text-black font-bold">$</span>
            </div>
            <span className="flex items-center mx-15px h-40px">or</span>
            <div className="relative max-w-165px w-full">
              <GolfInput
                inputClass="text-center"
                htmlFor="percent"
                name="percent"
                isError={errors.percent}
                register={register}
                type="number"
              />
              <span className="absolute right-2.5 top-9px text-base text-black font-bold">%</span>
            </div>
          </div>
          <div className="w-full max-w-600px">
            <GolfTextarea
              text="Restrictions*"
              className="h-100px"
              labelText="Specify days and/or times coupon is valid."
              name="restrictions"
              isError={errors.restrictions}
              register={register}
            />
            <TextAreaBottomCharsCount
              maxTextLength={textFieldsMaxLength.passbook.restrictions}
              messageLength={watchFields[1]?.length}
            />
          </div>
          <div className="mb-2.5">
            <span className="block text-15/18 text-333333 font-bold mb-2">Promo Activation*</span>
            <LongGreenCheckbox
              name="code"
              text="Code is active in my Tee Sheet"
              textClass="text-white ml-2 font-bold text-xs"
              checkMarkColor="gray"
              register={register}
              isCheckboxChecked={isCodeCheckboxActive}
            />
          </div>
          <div className="flex mb-8">
            <div className="relative max-w-210px w-full">
              <GolfInput
                htmlFor="promo_code"
                name="promo_code"
                isError={errors.promo_code}
                register={register}
              />
            </div>
            <span className="flex items-center mx-15px h-40px">or</span>
            <span className="flex items-center h-40px text-52A91A cursor-pointer">
              Generate bar code
            </span>
          </div>
          <div className="flex justify-end mb-10">
            <CustomButton
              className="py-2 px-7 text-base font-bold text-white bg-52A91A"
              text="Create Coupon"
              type="submit"
            />
          </div>
        </form>
        <CouponCard
          title={watchFields[0]}
          couponStartsHours={couponStartHoursValue}
          couponStartsMinutes={couponStartMinutesValue}
          couponStartsTimePeriod={couponStartTimePeriod}
          couponEndsHours={couponEndHoursValue}
          couponEndsMinutes={couponEndMinutesValue}
          couponEndsTimePeriod={couponEndTimePeriod}
          couponDateStarts={watchFields[2]}
          couponDateEnds={watchFields[3]}
          isBarcode={false}
        />
      </div>
    </section>
  )
}
