import React, { useState } from 'react'

import { CustomButton } from '../../Common/CustomButton'

import { offerTableConfig } from './configs'
import { Page } from '../Components/Page'
import { EDIT_OFFER, NEW_OFFER } from '../../../constants/urls'
import { MainOffer, Offer } from '../../../models/Offer'
import { Link, useNavigate } from 'react-router-dom'
import { ConfirmDeletingDialog } from '../Components/dialogs/ConfirmDeletingDialog'
import DeleteIcon from '../../../assets/svg/delete-small.svg'
import { CustomTable } from '../Components/CustomTable'
import { useOffers } from '../../../hooks/useOffers'
import { useApi } from '../../../hooks/api/useApi'
import { deleteOffer } from '../../../store/offer/actions'
import { DefaultTableStyles } from '../../../models/TableStyles'
import { SetupProgress } from '../Components/SetupProgress'
import { useOnlySpecificType } from '../../../hooks/useOnlySpecificType'

const tableStyles = {
  ...DefaultTableStyles,
  emptyPlaceholderClasses: 'flex justify-center mb-14 mt-[123px]'
}

export const OffersPage: React.FC = () => {
  useOnlySpecificType('public')
  const [selectedOfferId, setSelectedOfferId] = useState<string | number | undefined>(undefined)
  const [openedConfirmDialog, setOpenedConfirmDialog] = useState<boolean>(false)
  const [deleteLoading, deleteOfferApi] = useApi(deleteOffer)

  const navigate = useNavigate()
  const offers = useOffers()

  const onRowClick = (rowData: Offer) => {
    navigate(EDIT_OFFER.replace(':id', rowData.id?.toString() || ''))
  }

  const startDeletingOffer = (e: Event | undefined, offer: Offer) => {
    e?.stopPropagation()
    setSelectedOfferId(offer?.id)
    setOpenedConfirmDialog(true)
  }

  const finishDeleting = () => {
    setSelectedOfferId(undefined)
    setOpenedConfirmDialog(false)
  }

  const confirmDeleting = () => {
    selectedOfferId && deleteOfferApi(selectedOfferId).then(finishDeleting)
  }

  const actions = [
    {
      component: (offer: Offer) => <DeleteOfferButton offer={offer} onClick={startDeletingOffer} />,
      className: 'w-5'
    }
  ]

  return (
    <Page title="My Offers" relative>
      <div className="flex justify-end absolute right-[30px] top-5">
        <SetupProgress />
      </div>
      <h2 className="text-16/16 text-333333">Click on Offer to edit or reuse it.</h2>
      <div className="flex justify-end mb-5px">
        <CustomButton
          className="mb-1 text-16/16 text-52A91A font-semibold"
          text="+ Add New Offer"
          link={NEW_OFFER}
        />
      </div>
      <CustomTable
        onRowClick={onRowClick}
        data={offers.map((offer: MainOffer) => ({
          ...offer,
          last_published_to_feed: offer.last_published_to_feed || '',
          last_published_to_av_grid: offer.last_published_to_av_grid || ''
        }))}
        config={offerTableConfig}
        actions={actions}
        selectedItemId={selectedOfferId}
        emptyPlaceholderText={<EmptyPlaceholder />}
        styles={tableStyles}
        sorterable
      />
      <ConfirmDeletingDialog
        title="Are You Sure You Want To Delete This Offer?"
        opened={openedConfirmDialog}
        onSubmit={confirmDeleting}
        onReject={finishDeleting}
        loading={deleteLoading}
      />
    </Page>
  )
}

type DeleteOfferPropTypes = {
  offer: Offer
  onClick: (e: Event | undefined, offer: Offer) => void
}
const DeleteOfferButton: React.FC<DeleteOfferPropTypes> = ({ offer, onClick }) => {
  return (
    <CustomButton
      icon={DeleteIcon}
      className="text-14/16"
      onClick={(event: Event) => onClick(event, offer)}
    />
  )
}

const EmptyPlaceholder: React.FC = () => {
  return (
    <div className="text-center">
      <span className="text-4A4A4A text-18/22 text-center opacity-40">
        There are no Offers here yet.{' '}
      </span>
      <span className="mb-1 text-18/22 text-52A91A font-semibold">
        <Link to={NEW_OFFER}>+ Add New Offer</Link>
      </span>
    </div>
  )
}
