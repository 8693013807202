import React, { ReactElement, useState, useEffect, useMemo } from 'react'
import { TableHeader } from './TableHeader'
import { SeparatedTableBody } from './SeparatedTableBody'
import GolfHole from '../../../assets/img/golfHole.png'
import { TableAction } from '../../../models/TableAction'
import { TableConfig } from '../../../models/TableConfig'
import { DefaultTableStyles, TableStyles } from '../../../models/TableStyles'

type PropTypes = {
  config: TableConfig[]
  actions: TableAction[]
  data: any[]
  onRowClick?: (tableRow: any) => void
  selectedItemId?: number | string
  emptyPlaceholderText?: ReactElement
  styles?: TableStyles,
  sorterable?: boolean
}

const sortAsc = (config: TableConfig[], data: any[], activeArrowIndex: number) => {
  const dataCopy = [...data]

  return dataCopy.sort((a, b) => a[config[activeArrowIndex].id] > b[config[activeArrowIndex].id] ? 1 : -1)
}

const sortDesc = (config: TableConfig[], data: any[], activeArrowIndex: number) => {
  const dataCopy = [...data]

  return dataCopy.sort((a, b) => a[config[activeArrowIndex].id] < b[config[activeArrowIndex].id] ? 1 : -1)
}

export const CustomTable: React.FC<PropTypes> = (
  {
    config,
    actions,
    data,
    onRowClick,
    selectedItemId,
    emptyPlaceholderText,
    styles = DefaultTableStyles,
    sorterable
  }
) => {
  const defaultSortIndex = config.findIndex((el) => el.sortedByDefault)

  const [activeArrow, setActiveArrow] = useState({
    index: defaultSortIndex !== -1 ? defaultSortIndex : 0,
    active: false
  })
  const [sortedData, setSortedData] = useState(data)

  const sortedDataByDefault = useMemo(() => {
    if (config[defaultSortIndex]?.sortedByDefault?.direction === 'DESC') {
      return sortDesc(config, data, defaultSortIndex)
    } else if (config[defaultSortIndex]?.sortedByDefault?.direction === 'ASC') {
      return sortAsc(config, data, defaultSortIndex)
    } else {
      return data
    }
  }, [data])

  const sortedDataByArrow = useMemo(() => {
    if (activeArrow.active) {
      return sortAsc(config, data, activeArrow.index)
    }
    return sortDesc(config, data, activeArrow.index)
  }, [activeArrow])

  useEffect(() => {
    setSortedData(sortedDataByDefault)
  }, [data])

  useEffect(() => {
    setSortedData(sortedDataByArrow)
  }, [activeArrow])

  return (
    <>
      <div className="text-left">
        <TableHeader
          className={`${styles.headerClasses} ${styles.headerSpace}`}
          columnsArr={config}
          activeArrow={activeArrow}
          setActiveArrow={setActiveArrow}
          actions={actions}
          sorterable={!!sorterable}
        />
        <SeparatedTableBody
          tableData={sortedData}
          config={config}
          onRowClick={onRowClick}
          actions={actions}
          selectedItemId={selectedItemId}
          rowsClassNames={styles.rowClasses}
          rowColors={styles.rowColors}
          bodyClassNames={styles.bodyClasses}
          getColumnClasses={styles.getColumnClasses}
        />
      </div>
      {data.length === 0 && (
        <>
          <div className={styles.emptyPlaceholderClasses}>
            <img src={GolfHole} alt="img" />
          </div>
          {emptyPlaceholderText}
        </>
      )}
    </>
  )
}
