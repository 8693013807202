import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as FooterLogo } from '../../assets/svg/footerLogo.svg'
import { ReactComponent as FaceBook } from '../../assets/svg/faceBookFooter.svg'
import { ReactComponent as Instagram } from '../../assets/svg/instagramFooter.svg'
import { ReactComponent as Twitter } from '../../assets/svg/twitterFooter.svg'

export const Footer: React.FC = () => {
  return (
    <footer className="width__container bg-EEF1EB flex justify-between px-60px py-5">
      <div className="max-w-310px w-full mt-3">
        <a href="https://golfbevy.com">
          <FooterLogo className="mb-18px" />
        </a>
        <p className="font-bold text-base text-4A4A4A">
          Find a game with your friends at any course
        </p>
      </div>
      <div className="max-w-700px w-full flex flex-col items-end">
        <ul className="flex justify-end text-12/14 font-normal text-898A8D capitalize mb-70px">
          <li className="uppercase pr-2.5 mr-3 border__right">
            <a href="https://golfbevy.com/faq.html">faq</a>
          </li>
          <li className="pr-2.5 mr-3 border__right">
            <a href="https://golfbevy.com/about.html">about us</a>
          </li>
          <li>
            <a href="mailto:headpro@golfbevy.com">Support</a>
          </li>
        </ul>
        <div className="flex items-center">
          <div className="flex items-center">
            <a href="https://www.facebook.com/golfbevy" className="mr-5 cursor-pointer">
              <FaceBook />
            </a>
            <a href="https://www.instagram.com/golfbevy" className="mr-5 cursor-pointer">
              <Instagram />
            </a>
            <a href="https://twitter.com/golfbevy" className="mr-5 cursor-pointer">
              <Twitter />
            </a>
            <p className="ml-2 text-3A3A3A text-base font-normal">
              @ 2023 Find A Game, All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
