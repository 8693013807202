import { useSelector } from 'react-redux'
import { AsyncThunk } from '@reduxjs/toolkit'
import { useApi } from './useApi'
import { useMemo } from 'react'

export const useApiResource = (sliceName: string, entityName: string, thunk: AsyncThunk<any, any, any>, payload: any = undefined, skip: boolean = false) => {
  const store = useSelector((state: any) => state[sliceName])
  const [loading, get] = useApi(thunk)

  const entity = store[entityName]

  if (entity === undefined && !loading && !skip) {
    get(payload)
  }

  return useMemo(() => {
    return [loading, entity]
  }, [loading, entity])
}