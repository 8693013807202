import { TableConfig } from '../../../models/TableConfig'
import { Teammate } from '../../../models/Teammate'
import { statusToName } from '../../../helpers/teammate'

export const teammatesTableConfig: TableConfig[] = [
  {
    name: 'Name',
    id: 'name',
    width: 'max-w-180px',
    classNames: { data: 'truncate' },
    getData: (row: Teammate) => `${row.first_name} ${row.last_name}`
  },
  { name: 'Title', id: 'title', width: 'max-w-135px', getData: (row: Teammate) => row.title || '' },
  { name: 'Email', id: 'email', width: 'max-w-135px' },
  { name: 'Mobile', id: 'mobile', width: 'max-w-135px' },
  { name: 'Status', id: 'status', width: 'max-w-180px', getData: (row: Teammate) => statusToName(row.status) }
]