import React from 'react'

type PropsType = {
  className?: string
}

export const ComingSoonText: React.FC<PropsType> = ({ className = 'text-10/10 px-1.5 py-5px' }) => {
  return (
    <span
      className={`text-1A8805 bg-white border border-solid border-1A8805 rounded-sm ${className}`}
    >
      Coming Soon!
    </span>
  )
}
