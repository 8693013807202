import React from 'react'
import { EventForm } from './EventForm'
import { Page } from '../Components/Page'
import { createEvent } from '../../../store/event/actions'
import { useOutletContext } from '../../Common/CustomContext'

export const NewEventPage: React.FC = () => {
  const { course } = useOutletContext()

  return (
    <Page title="Add Event">
      <EventForm onSubmitThunk={createEvent} course={course} />
    </Page>
  )
}
