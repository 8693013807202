import { useDispatch, useSelector } from 'react-redux'
import { getEvents } from '../store/event/actions'
import { RootState } from '../store/store'

export const useEvents = () => {
  const event = useSelector((state: RootState) => state.event)
  const dispatch = useDispatch()

  const { events } = event

  if (!events) {
    dispatch(getEvents())
  }

  return events || []
}
