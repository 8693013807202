import React from 'react'

type PropsType = {
  firstName: string
}

export const CreatePasswordText: React.FC<PropsType> = ({ firstName }) => {
  return (
    <div className="relative pt-110px px-85px text-white">
      <h1 className="signUp__title mb-6">Great Job, {firstName}</h1>
      <p className="text-18/22">
        Your email has been confirmed. Just a few more steps and you can begin using GolfBevy to
        promote your course to more golfers in your area.
      </p>
    </div>
  )
}
