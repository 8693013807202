import { CustomButton } from '../../Common/CustomButton'
import React from 'react'

type PropTypes = {
  opened: boolean
  onClose: () => void
}

export const CongratsModal: React.FC<PropTypes> = ({ opened, onClose }) => {
  return opened ? (
    <div className="absolute w-full h-full top-0 left-0">
      <div className="modal top-[30%!important]">
        <div
          className="bg-black h-5 w-5 rounded-full flex items-center justify-center cursor-pointer absolute right-20 right-[-10px] top-[-10px]"
          onClick={onClose}
        >
          <span className="text-white text-10/12">X</span>
        </div>
        <div className="border-b-1.5 border-solid border-4A4A4A">
          <div className="w-[294px] pt-[42px] px-7 pb-6 text-center">
            <p className="text-[30px] font-sfui font-medium mb-3">Congrats!</p>
            <p className="text-13/15 font-sfui">Teammate was added</p>
          </div>
        </div>
        <div className="flex justify-center p-[11px]">
          <CustomButton className="text-52A91A text-17/22" text="Cool!" onClick={onClose} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}