import { useApi } from '../../../hooks/api/useApi'
import { changePassword } from '../../../store/user/actions'
import { useForm } from 'react-hook-form'
import { handleApiErrors } from '../../../helpers/api'
import { GolfInput } from '../Components/GolfInput'
import { InputDescription } from '../Components/small/InputDescription'
import { CustomButton } from '../../Common/CustomButton'
import React from 'react'
import { DoubleFields } from './DoubleFields'

type Password = {
  old_password: string
  new_password1: string
  new_password2: string
}

export const ChangePasswordForm: React.FC = () => {
  const [loading, save] = useApi(changePassword)
  const { register, watch, handleSubmit, setError, formState: { errors } } = useForm<Password>({})

  const [password, newPassword, confirmPassword] = watch(['old_password', 'new_password1', 'new_password2'])
  const disableSubmit = [password, newPassword, confirmPassword].map((field) => field && field.length > 0).filter((field) => !field).length > 0

  const updatePassword = (data: Password) => {
    save(data)
    .then(({ error, payload }) => {
      if (!error) {
        return
      }
      handleApiErrors(setError, payload)
    })
  }
  return (
    <div className="w-full flex justify-center">
      <form className="w-full max-w-[657px] mt-[51px]" onSubmit={handleSubmit(updatePassword)}>
        <h2 className="text-333333 leading-[30px] text-20/24 font-medium ">
          Change Your Password
        </h2>
        <DoubleFields>
          {[<div key="key">
            <GolfInput
              name="old_password"
              label="Current Password"
              register={register}
              secure
              isError={errors.old_password}
            />
            <InputDescription
              className="mt-3 mb-2"
              text="If you don’t know your current password, log out and use the “Forgot my password” option"
            />
          </div>]}
        </DoubleFields>
        <DoubleFields>
          <GolfInput
            name="new_password1"
            label="New Password"
            register={register}
            secure
            isError={errors.new_password1}
          />
          <GolfInput
            name="new_password2"
            label="Retype Your New Password"
            register={register}
            secure
            isError={errors.new_password2}
          />
        </DoubleFields>
        <div className="flex justify-end mt-[29px]">
          <CustomButton
            className="main-button w-[186px] h-10"
            text="Change Password"
            disabled={disableSubmit}
            loading={loading}
            type="submit"
          />
        </div>
      </form>
    </div>
  )
}
