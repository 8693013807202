import React, { memo } from 'react'
import { MobileApp } from '../../../Components/MobileApp'
import format from 'date-fns/format'
import { CustomButton } from '../../../../Common/CustomButton'
import { Description } from './Components/Description'
import { Photo } from './Components/Photo'
import { ClubInfo } from './Components/ClubInfo'
import { UserCourse } from '../../../../../models/UserCourse'

type PropTypes = {
  imageUrl?: string
  type?: string
  name?: string
  date?: Date
  description?: string,
  contentLink?: string,
  course?: UserCourse
}

export const DetailPreview: React.FC<PropTypes> =
  memo(({
          imageUrl,
          type,
          name,
          date,
          description,
          contentLink,
          course
        }) => {
    const formattedDate = date && format(date, 'MMMM do')

    const onClick = () => {
      const openedWindow = window.open(contentLink, '_blank')
      if (openedWindow) {
        openedWindow.opener = null
      }
    }

    return (
      <>
        <MobileApp size="sm">
          <div className="w-full h-full bg-white">
            <Photo imageUrl={imageUrl}>
              {imageUrl && <div className="flex justify-end">
                <span className="rounded-sm bg-white text-6/7 p-0.5">Sign Up Now</span>
              </div>}
            </Photo>
            <ClubInfo type={type} course={course} />
            <div className="p-2">
              {name && <p className="text-9.5/10 mb-2">{name}</p>}
              {formattedDate && <p className="text-9.5/10 opacity-50 mb-2">{formattedDate}</p>}
              <Description description={description} />
              {contentLink &&
                <CustomButton
                  className="w-146px bg-black text-white rounded-3px h-4 text-6/7"
                  text="Sign Up Now"
                  onClick={onClick}
                />
              }
            </div>
          </div>
        </MobileApp>
        <p className="text-center text-333333 opacity-50 text-14/16">{type} Detail Preview</p>
      </>
    )
  })