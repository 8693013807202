import { useOffers } from '../../../../hooks/useOffers'
import { Page } from '../../Components/Page'
import { OfferSelect } from '../OfferSelect'
import React, { useEffect, useState } from 'react'
import { Form } from './Form'
import { Table } from './Table'
import { CustomButton } from '../../../Common/CustomButton'
import { NEW_OFFER } from '../../../../constants/urls'
import { useLocation } from 'react-router-dom'
import { SetupProgress } from '../../Components/SetupProgress'
import { useOnlySpecificType } from '../../../../hooks/useOnlySpecificType'
import { useOutletContext } from '../../../Common/CustomContext'
import { Offer } from '../../../../models/Offer'
import { useApi } from '../../../../hooks/api/useApi'
import { publishOfferToGrid } from '../../../../store/offer/actions'
import { AvailabilityDay } from '../../../../models/AvailabilityDay'
import { TODAY } from '../../../../helpers/date'

const DAYS_COUNT = 14

const days = [...Array(DAYS_COUNT)]

const defaultAvailabilityDays: AvailabilityDay[] = []
let date = new Date(TODAY)

days.forEach(() => {
  defaultAvailabilityDays.push({ date: new Date(date), pm: false, am: false })
  date.setTime(date.getTime() + (24 * 60 * 60 * 1000))
})

export const OffersAvailabilityGridPage: React.FC = () => {
  useOnlySpecificType('public')
  const [selectedOfferId, setSelectedOfferId] = useState<number>()
  const offers = useOffers()
  const { search } = useLocation()
  const offerId = new URLSearchParams(search).get('offerId')
  const { course } = useOutletContext()
  const [loading, publish] = useApi(publishOfferToGrid)
  useEffect(() => {
    setSelectedOfferId(Number(offerId))
  }, [offerId])

  const selectedOffer = offers.find(({ id }) => id === selectedOfferId)

  const onSuccess = () => {
    setSelectedOfferId(undefined)
  }

  return (
    <Page title="Availability Grid" relative>
      {selectedOfferId ?
        undefined :
        <div className="flex justify-end absolute right-[30px] top-5">
          <SetupProgress />
        </div>
      }
      <div className="w-full availability-grid">
        <p className="text-14/16 text-33333 mb-5 h-30px leading-[30px]">
          Improve your yield by sending targeted Offers to golfers who’ve expressed interest in playing your course.
        </p>
        <div className="w-full">
          <div className="w-full relative">
            {offers.length ?
              <OfferSelect offers={offers || []} selectedOfferId={selectedOfferId} onChange={setSelectedOfferId} /> :
              <div className="flex justify-end mb-5px">
                <CustomButton
                  className="mb-1 text-16/16 text-52A91A font-semibold"
                  text="+ Add New Offer"
                  link={NEW_OFFER}
                />
              </div>
            }
          </div>
          {selectedOfferId ?
            <Form
              offerId={selectedOfferId}
              course={course}
              offer={selectedOffer as Offer}
              loading={loading}
              submit={publish}
              onSuccess={onSuccess}
              initialDays={defaultAvailabilityDays}
            /> :
            <Table />}
        </div>
      </div>
    </Page>
  )
}