import React from 'react'
import { Outlet } from 'react-router-dom'

import { Footer } from './Footer'
import { Header } from './Header'

type PropsType = {
  isFullScreenBg: boolean
}

export const SignUpLayout: React.FC<PropsType> = ({ isFullScreenBg }) => {
  return (
    <div className="relative width__container">
      <Header isLoginLayout={false} />
      <main className="">
        <Outlet />
      </main>
      <Footer />
      {isFullScreenBg && (
        <div className="bg-black opacity-60 absolute left-0 top-0 w-full h-full z-10" />
      )}
    </div>
  )
}
