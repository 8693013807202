import React from 'react'

type PropTypes = {
  description?: string
}

export const Description: React.FC<PropTypes> = ({ description }) => {
  if (description) {
    return <p className="max-h-8 h-full text-ellipsis overflow-hidden text-7/8">{description}</p>
  }

  return <p className="max-h-8 h-full text-ellipsis overflow-hidden text-7/8 opacity-50">Your text goes here</p>
}