import React from 'react'
import { MobileAppMedium } from './MobileApp/MobileAppMedium'
import { MobileAppSmall } from './MobileApp/MobileAppSmall'

type PropTypes = {
  size?: 'sm' | 'md'
}

export const MobileApp: React.FC<PropTypes> = ({ children, size = 'md' }) => {
  if (size === 'md') {
    return <MobileAppMedium>{children}</MobileAppMedium>
  }
  return <MobileAppSmall>{children}</MobileAppSmall>
}
