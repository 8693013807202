import { useOnlySpecificType } from '../../../../hooks/useOnlySpecificType'
import { MainOffer, Offer } from '../../../../models/Offer'
import { useOffers } from '../../../../hooks/useOffers'
import { useOutletContext } from '../../../Common/CustomContext'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Page } from '../../Components/Page'
import { OfferSelect } from '../OfferSelect'
import { Form } from './Form'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useApi } from '../../../../hooks/api/useApi'
import {
  deleteGridOffer,
  getGridOffers,
  publishOfferToGrid,
  unpublishGridOffer,
  updateGridOffer
} from '../../../../store/offer/actions'
import EditIcon from '../../../../assets/svg/editPencil.svg'
import UnpublishIcon from '../../../../assets/svg/unpublish-black.svg'
import DeleteIcon from '../../../../assets/svg/delete-unfilled-black.svg'
import { ConfirmDeletingDialog } from '../../Components/dialogs/ConfirmDeletingDialog'
import { OFFERS_AVAILABILITY_GRID } from '../../../../constants/urls'
import { ConfirmUnpublishingDialog } from '../../Components/dialogs/ConfirmUnpublishingDialog'

export const EditAvailabilityGridOfferPage = () => {
  useOnlySpecificType('public')
  const [editable, setEditable] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [unpublishing, setUnpublishing] = useState(false)
  const navigate = useNavigate()
  const offers: MainOffer[] = useOffers()
  const gridOffers = useSelector((state: RootState) => state.offer.gridOffers)
  const { course } = useOutletContext()
  const { id } = useParams()
  const [selectedOfferId, setSelectedOfferId] = useState<number>()
  const [offersLoading, loadOffers] = useApi(getGridOffers)
  const [loading, publish] = useApi(updateGridOffer)
  const [deleteLoading, deleteOfferApi] = useApi(deleteGridOffer)
  const [unpublishingLoading, unpublishOfferApi] = useApi(unpublishGridOffer)

  const gridOffer = useMemo(() => {
    return gridOffers?.find((offer) => offer.id === Number(id))
  }, [gridOffers, id])
  const offerId = gridOffer?.offer

  useEffect(() => {
    if (!gridOffers && !offersLoading) {
      loadOffers()
    }
  }, [offersLoading, gridOffers])

  useEffect(() => {
    setSelectedOfferId(Number(offerId))
  }, [offerId])

  const selectedOffer = offers.find(({ id }) => id === selectedOfferId)

  const onSuccess = () => {
    navigate(OFFERS_AVAILABILITY_GRID)
  }

  const confirmDeleting = () => {
    gridOffer && deleteOfferApi(gridOffer.id).then(() => {
      finishDeleting()
      navigate(OFFERS_AVAILABILITY_GRID)
    })
  }

  const finishDeleting = () => {
    setDeleting(false)
  }

  const confirmUnpublishing = () => {
    gridOffer && unpublishOfferApi(gridOffer.id).then(finishUnpublishing)
  }

  const finishUnpublishing = () => {
    setUnpublishing(false)
  }

  return (
    <Page title="Availability Grid" relative>
      <div className="flex justify-end absolute right-[30px] top-5">
        <div className="flex">
          <button className="mr-5" onClick={() => setEditable(true)}>
            <figure className="w-full flex justify-center">
              <img src={EditIcon} alt="" />
            </figure>
            <span className="text-[9px] leading-3 text-333333 text-center">
              Edit
            </span>
          </button>
          <button className="mr-5" onClick={() => setUnpublishing(true)}>
            <figure className="w-full flex justify-center">
              <img src={UnpublishIcon} alt="" />
            </figure>
            <span className="text-[9px] leading-3 text-333333 text-center">
              Unpublish
            </span>
          </button>
          <button className="" onClick={() => setDeleting(true)}>
            <figure className="w-full flex justify-center">
              <img src={DeleteIcon} alt="" />
            </figure>
            <span className="text-[9px] leading-3 text-333333 text-center">
              Delete
            </span>
          </button>
        </div>
      </div>
      <p className="text-14/16 text-33333 mb-2 h-30px leading-[30px]">
        Improve your yield by sending targeted Offers to golfers who’ve expressed interest in playing your course.
      </p>
      <div className="w-full relative">
        <OfferSelect offers={offers || []} selectedOfferId={selectedOfferId} onChange={setSelectedOfferId} disabled />
      </div>
      <Form
        offerId={selectedOfferId || 0}
        offer={selectedOffer as Offer}
        agOffer={gridOffer}
        course={course}
        editable={editable}
        loading={loading}
        submit={publish}
        onSuccess={onSuccess}
      />
      <ConfirmDeletingDialog
        title="Are You Sure You Want To Delete This Offer?"
        opened={deleting}
        onSubmit={confirmDeleting}
        onReject={finishDeleting}
        loading={deleteLoading}
      />
      <ConfirmUnpublishingDialog
        title="Are You Sure You Want To Unpublish This Offer?"
        opened={unpublishing}
        onSubmit={confirmUnpublishing}
        onReject={finishUnpublishing}
        loading={unpublishingLoading}
      />
    </Page>
  )
}