import React, { useEffect, useState } from 'react'

import { useApi } from '../../../../hooks/api/useApi'
import { GridOffer } from '../../../../models/Offer'
import { CustomTable } from '../../Components/CustomTable'
import { ConfirmDeletingDialog } from '../../Components/dialogs/ConfirmDeletingDialog'
import { formatId } from '../../../../helpers/id'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { Link, useNavigate } from 'react-router-dom'
import { EDIT_AVAILABILITY_GRID, NEW_OFFER } from '../../../../constants/urls'
import { DefaultTableStyles } from '../../../../models/TableStyles'
import { deleteGridOffer, getGridOffers, unpublishGridOffer } from '../../../../store/offer/actions'
import { toFormDateTime } from '../../../../helpers/date'
import { ActionButton } from './ActionButton'
import { ConfirmUnpublishingDialog } from '../../Components/dialogs/ConfirmUnpublishingDialog'

const tableConfig = [
  { name: 'ID#', id: 'offer', width: 'max-w-100px', getData: (row: GridOffer) => formatId(row.offer) },
  {
    name: 'Title',
    id: 'title',
    classNames: { data: 'truncate' },
    getData: (row: GridOffer) => row['name']
  },
  {
    name: 'Date',
    id: 'date',
    width: 'max-w-135px',
    getData: (row: GridOffer) => {
      const date = row?.availability?.dates[0]?.date

      if (!date) {
        return ''
      }

      return toFormDateTime(date, 'MM/DD/YYYY')
    }
  }
]

const tableStyles = {
  ...DefaultTableStyles,
  emptyPlaceholderClasses: 'flex justify-center mb-14 mt-[123px]'
}

export const Table: React.FC = () => {
  const navigate = useNavigate()
  const [selectedOfferId, setSelectedOfferId] = useState<number>()
  const [openedConfirmDialog, setOpenedConfirmDialog] = useState<boolean>(false)
  const [deleteLoading, deleteOfferApi] = useApi(deleteGridOffer)
  const [offersLoading, loadOffers] = useApi(getGridOffers)
  const [unpublishing, setUnpublishing] = useState(false)
  const [unpublishingLoading, unpublishOfferApi] = useApi(unpublishGridOffer)

  const offers = useSelector((state: RootState) => state.offer.gridOffers)

  useEffect(() => {
    if (!offers && !offersLoading) {
      loadOffers()
    }
  }, [offers, offersLoading])

  const startDeletingOffer = (offer: GridOffer) => {
    setSelectedOfferId(offer?.id)
    setOpenedConfirmDialog(true)
  }

  const finishDeleting = () => {
    setSelectedOfferId(undefined)
    setOpenedConfirmDialog(false)
  }

  const confirmDeleting = () => {
    selectedOfferId && deleteOfferApi(selectedOfferId).then(finishDeleting)
  }

  const confirmUnpublishing = () => {
    selectedOfferId && unpublishOfferApi(selectedOfferId).then(finishUnpublishing)
  }

  const finishUnpublishing = () => {
    setUnpublishing(false)
  }


  const actions = [
    {
      component: (offer: GridOffer) => {
        const onEdit = () => {
          navigate(EDIT_AVAILABILITY_GRID.replace(':id', String(offer?.id)))
        }

        return <ActionButton
          onRemove={() => startDeletingOffer(offer)}
          onOpen={() => setSelectedOfferId(offer.id)}
          onClose={() => setSelectedOfferId(undefined)}
          onEdit={onEdit}
          onUnpublish={() => setUnpublishing(true)}
        />
      },
      className: 'w-5'
    }
  ]

  return (
    <div className="mt-4">
      <CustomTable
        data={offers || []}
        config={tableConfig}
        actions={actions}
        selectedItemId={selectedOfferId}
        emptyPlaceholderText={<EmptyPlaceholder />}
        styles={tableStyles}
        sorterable
      />
      <ConfirmDeletingDialog
        title="Are You Sure You Want To Delete This Offer?"
        opened={openedConfirmDialog}
        onSubmit={confirmDeleting}
        onReject={finishDeleting}
        loading={deleteLoading}
      />
      <ConfirmUnpublishingDialog
        title="Are You Sure You Want To Unpublish This Offer?"
        opened={unpublishing}
        onSubmit={confirmUnpublishing}
        onReject={finishUnpublishing}
        loading={unpublishingLoading}
      />
    </div>
  )
}


const EmptyPlaceholder: React.FC = () => {
  return (
    <div className="text-center">
      <span className="text-4A4A4A text-18/22 text-center opacity-40">
        There are no Offers here yet.{' '}
      </span>
      <span className="mb-1 text-18/22 text-52A91A font-semibold">
        <Link to={NEW_OFFER}>+ Add New Offer</Link>
      </span>
    </div>
  )
}