import { createSlice } from '@reduxjs/toolkit'
import { getInviteInfo } from './actions'

type InviteState = {
  invite?: any
}

const initialState: InviteState = {
  invite: undefined
}

export const inviteSlice = createSlice({
  name: 'invite',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getInviteInfo.fulfilled, (state, action) => {
      state.invite = action.payload
    })
  }
})

export default inviteSlice.reducer
