import { Event } from '../../../models/Event'
import format from 'date-fns/format'
import { getFormattedRange } from '../../../helpers/date'
import { TableConfig } from '../../../models/TableConfig'
import { formatId } from '../../../helpers/id'
import { eventTypeToName } from '../../../helpers/event'
import { EventType } from '../../../models/EventType'

export const eventTableConfig: TableConfig[] = [
  {
    name: 'ID#',
    id: 'id',
    width: 'max-w-100px',
    getData: ({ id }: Event) => formatId(id)
  },
  {
    name: 'Event Date(s)',
    id: 'date_start',
    width: 'max-w-165px',
    getData: ({ eventDate: date }: Event) => getFormattedRange(new Date(date.start), new Date(date.end)),
    sortedByDefault: { direction: 'DESC' }
  },
  {
    name: 'Title',
    id: 'title',
    width: 'max-w-280px',
    classNames: { data: 'font-semibold truncate' },
    getData: ({ name }: Event) => name
  },
  {
    name: 'Publish Start',
    id: 'date_publish_start',
    width: 'max-w-135px',
    getData: ({ publishDate: date }: Event) => format(new Date(date.start), 'MM/dd/yyyy')
  },
  {
    name: 'Publish End',
    id: 'date_publish_end',
    width: 'max-w-135px',
    getData: ({ publishDate: date }: Event) => format(new Date(date.end), 'MM/dd/yyyy')
  },
  {
    name: 'Type',
    id: 'type',
    width: 'max-w-100px',
    getData: ({ type }: any) => eventTypeToName(type as EventType)
  },
  {
    name: 'Status',
    id: 'status',
    width: 'max-w-100px'
  }
]

export const eventTypes = [
  { id: '1', name: 'News' },
  { id: '2', name: 'Tournament' },
  { id: '3', name: 'Event' }
]
