import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { CustomButton } from '../../Common/CustomButton'
import { InputPhoneMask } from '../../Common/InputPhoneMask'

import { AnyAction } from 'redux'
import { AppDispatch } from '../../../store/store'

import { SignUpProgress } from '../../../constants/pagesProgress'
import { setSkipMobile } from '../../../store/auth/authSlice'
import { useApiDispatch } from '../../../hooks/api/useApiDispatch'
import { attachPhoneNumber } from '../../../store/auth/actions'
import { useLoading } from '../../../hooks/api/useLoading'

type Inputs = {
  phone_number: string
}

type PropsType = {
  dispatch: AppDispatch
  setProgress: (progress: string) => AnyAction
  nextProgress: string
}

const COUNTRY_CODE = '+1'

export const EnterMobile: React.FC<PropsType> = ({ dispatch, setProgress, nextProgress }) => {
  const saveMobile = useApiDispatch(attachPhoneNumber)
  const loading = useLoading(attachPhoneNumber)

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors }
  } = useForm<Inputs>()

  const skip = () => {
    dispatch(setProgress(SignUpProgress.SignUpMobileCode))
    dispatch(setSkipMobile(true))
  }

  const onSubmit: SubmitHandler<Inputs> = (data: { phone_number: string }) => {
    const phoneNumber = COUNTRY_CODE + data.phone_number.replace(/\D/g, '')
    saveMobile({ phone_number: phoneNumber })
    .then(({ error, payload }) => {
      if (error) {
        const errors = payload?.errors || {}
        Object.entries(errors)
        .forEach((keyValue) => {
          // @ts-ignore
          setError(keyValue[0], { message: keyValue[1].join(',') })
        })
      } else {
        dispatch(setProgress(nextProgress))
      }
    })
  }

  // @ts-ignore
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative pt-120px pl-140px pr-100px">
      <article className="mb-5">
        <h1 className="mb-5 signUp__component_title text-4A4A4A">Enter Your Mobile Number</h1>
        <p className="text-4A4A4A text-18/22 opacity-80">
          Why? To text you important information and to help if you forget your login credentials.
        </p>
      </article>
      <span className="text-18/22 mb-1.5 opacity-80">Mobile*</span>
      {errors.phone_number && (
        <span className="text-C43100 text-12/14 ml-3">
          {errors.phone_number?.message || 'Please check the number you entered and make sure it\'s correct.'}
        </span>
      )}
      <InputPhoneMask
        name="phone_number"
        inputClass="registration__input"
        inputErrorClass="registration__input_error"
        flagBoxClass="border-BCC5E8"
        isError={errors.phone_number}
        control={control}
        countryCode={COUNTRY_CODE}
      />
      <p className="text-9C9C9C text-12/14 mb-5">
        Requires validation. Normal text and phone rates apply.
      </p>
      <div className="flex justify-between items-center">
        <div>
          <CustomButton text="Skip" className="text-52A91A text-20/24 cursor-pointer mr-3" onClick={skip} />
          <span className="text-9C9C9C text-12/14">Can be added later in your Profile.</span>
        </div>
        <CustomButton
          className={`py-4 px-68px text-20/24 font-bold text-white ${
            false ? 'bg-52A91A/30' : 'bg-52A91A'
          }`}
          text="Submit"
          type="submit"
          loading={loading}
        />
      </div>
    </form>
  )
}
