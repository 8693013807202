import React from 'react'
import { CustomButton } from '../../../Common/CustomButton'

type PropTypes = {
  opened: boolean
  onConfirmed: () => void
}

export const CongratsModal: React.FC<PropTypes> = ({ opened, onConfirmed }) => {
  return opened ? (
    <div className="absolute w-full h-full top-0 left-0">
      <div className="modal">
        <div className="border-b-1.5 border-solid border-4A4A4A">
          <div className="max-w-294px w-full pt-3 px-5 pb-6 text-center">
            <p className="text-17/22 font-sfui font-medium mb-3">
              This Offer has been Published to the Availability Grid
            </p>
            <p className="text-13/15 font-sfui">Recipients will see <br /> the Offer 2 days before it’s valid.</p>
          </div>
        </div>
        <div className="flex justify-center p-[11px]">
          <CustomButton className="text-[#000000] text-18/22" text="OK" onClick={onConfirmed} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
