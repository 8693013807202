import React, { useEffect, useState } from 'react'
import { InputLabel } from '../../Components/small/InputLabel'
import { AvailabilityTable } from './AvailabilityTable'
import { CustomButton } from '../../../Common/CustomButton'
import { AvailabilityDay } from '../../../../models/AvailabilityDay'
import { toApiDateTime } from '../../../../helpers/date'
import { InputDescription } from '../../Components/small/InputDescription'
import { GolfTextarea } from '../../Components/GolfTextarea'
import { TextAreaBottomCharsCount } from '../../Components/small/TextAreaBottomCharsCount'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { UserCourse } from '../../../../models/UserCourse'
import { Offer } from '../../../../models/Offer'
import { OfferPreview } from '../OfferForm/OfferPreview'
import { usePrevious } from '../../../../hooks/usePrevious'
import { CongratsModal } from './CongratsModal'

type Inputs = {
  description: string,
}

const validationSchema = Yup.object().shape({
  description: Yup.string()
  .required('Offer Description is required')
  .min(50, 'Must have at least 50 characters')
})

type PropsType = {
  offerId: number
  editable?: boolean
  course?: UserCourse
  offer: Offer,
  agOffer?: any,
  loading: boolean,
  submit: any,
  onSuccess: () => void
  initialDays?: AvailabilityDay[]
}

const convertApiDays = (days: any): AvailabilityDay[] => {
  return days.map((day: any) => {
    return {
      date: new Date(day.date),
      am: day.am === 'yes',
      pm: day.pm === 'yes'
    }
  })
}

export const Form: React.FC<PropsType> = (
  {
    offerId,
    editable = true,
    course,
    offer,
    agOffer,
    submit,
    loading,
    onSuccess,
    initialDays = []
  }
) => {
  const [availabilityDays, setAvailabilityDays] = useState<AvailabilityDay[]>(initialDays)
  const [initialized, setInitialized] = useState(false)
  const prevOfferId = usePrevious(offerId)
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)

  const {
    formState: { errors },
    register,
    watch,
    setValue,
    trigger
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    if ((!initialized || offerId !== prevOfferId) && offer && !agOffer) {
      setValue('description', offer.message)
      setInitialized(true)
    }
  }, [offer])

  useEffect(() => {
    if (agOffer) {
      setValue('description', agOffer.description)
      setAvailabilityDays(convertApiDays(agOffer.availability?.dates || []))
    }
  }, [agOffer])


  const oneDayIsSelected = availabilityDays.some(({ am, pm }) => am || pm)
  const [description] = watch(['description'])

  const save = async () => {
    const isValid = await trigger()
    if (!isValid) {
      return
    }
    const formattedDates = availabilityDays
    .map(({ date, am, pm }: AvailabilityDay) => ({
      date: toApiDateTime(date),
      am: am ? 'yes' : 'no',
      pm: pm ? 'yes' : 'no'
    }))

    const data: Record<string, any> = {
      offer: offerId,
      availability: { dates: formattedDates },
      description
    }

    if (agOffer) {
      data.id = agOffer.id
    }

    submit(data)
    .then(({ error, payload }: any) => {
      if (!error) {
        setIsModalOpened(true)
      }
    })
  }

  const disabled = !editable

  const previewDate = 'Preview Date'

  const onCongrats = () => {
    setIsModalOpened(false)
    onSuccess()
  }

  return (
    <div className="flex mt-[27px] availability-grid">
      <div className="w-full max-w-[749px] mr-[77px]">
        <form action="">
          <InputLabel text="Offer Description*" className="" />
          <div className="flex justify-between mb-2">
            <InputDescription
              text="Include Offer details plus when it is applicable (dates, days of the week, time of day, etc.)"
              className="" />
            <InputDescription
              text="50 Character Min"
              className="" />
          </div>
          <GolfTextarea
            text=""
            name="description"
            className="h-100px"
            isError={errors.description}
            register={register}
            disabled={disabled}
            maxLength={300}
            labelClassName="block mb-2 text-333333 opacity-50 text-xs"
          />
          <TextAreaBottomCharsCount
            maxTextLength={300}
            messageLength={description?.length || 0}
          />
        </form>
        <InputLabel text="Select Recipients*" />
        <p className="text-12/14 text-333333">
          Choose which golfers receive Offer based on time of day (AM or PM) and days over the next two weeks
          they've
          indicated they can play.
        </p>
        <div className="mt-[23px]">
          <AvailabilityTable
            availabilityDays={availabilityDays}
            setAvailabilityDays={setAvailabilityDays}
            disabled={disabled}
          />
        </div>
        <div className="w-full flex justify-end mt-11">
          <CustomButton
            className="py-2 px-7 main-button w-169px"
            text="Send Offer"
            disabled={!oneDayIsSelected || disabled}
            onClick={save}
            loading={loading}
          />
        </div>
      </div>
      <div className="pt-[143px]">
        <OfferPreview
          course={course}
          imageUrl={offer?.image?.url}
          name={offer?.name}
          promoCode={offer?.code}
          description={description}
          dates={previewDate}
        />
      </div>
      <CongratsModal opened={isModalOpened} onConfirmed={onCongrats} />
    </div>
  )
}