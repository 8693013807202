import { Page } from '../Components/Page'
import React from 'react'
import { GolfInput } from '../Components/GolfInput'
import { useForm } from 'react-hook-form'
import { InputLabel } from '../Components/small/InputLabel'
import { InputDescription } from '../Components/small/InputDescription'
import { InputPhoneMask } from '../../Common/InputPhoneMask'
import { GolfTextarea } from '../Components/GolfTextarea'
import { CustomCheckbox } from '../../Common/CustomCheckbox'
import { CustomButton } from '../../Common/CustomButton'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

type Inputs = {
  name: string
  admin: {
    first_name: string
    last_name: string
    email: string
  }
  phone_number: string
  description: string
  is_public: boolean
  is_private: boolean
  is_hidden: boolean
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Group Name is required').min(5, 'Must have at least 5 characters'),
  admin: Yup.object().shape({
    first_name: Yup.string().required('First Name is required').min(2, 'Must have at least 2 characters'),
    last_name: Yup.string().required('Last Name is required').min(2, 'Must have at least 2 characters'),
    email: Yup.string().required('Email is required').email('Enter a valid email')

  })
  // message: Yup.string().required('Offer Description is required'),
  // code: Yup.string().required('Offer Code is required')
})

export const GroupsPage: React.FC = () => {
  const { register, control, watch, handleSubmit, formState: { errors } } = useForm<Inputs>({
    resolver: yupResolver(validationSchema)
  })

  const [description, isPrivate, isPublic, isHidden] = watch(['description', 'is_private', 'is_public', 'is_hidden'])
  const onSubmit = (data: Inputs) => {
    console.log(data)
  }

  console.log({ errors })

  return (
    <Page title="Groups and Associations">
      <form action="" className="w-full max-w-550px" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <GolfInput register={register} isError={errors.name} name="name" label="Group Name*" />
        </div>
        <InputLabel text="Group Admin" className="leading-[22px!important]" />
        <InputDescription text="Who is overseeing this group?" className="mb-0.5" />
        <div className="w-full flex justify-between mt-2 mb-4">
          <div className="w-full max-w-235px">
            <GolfInput
              register={register}
              isError={errors.admin?.first_name}
              name="admin[first_name]"
              label="First Name*"
            />
          </div>
          <div className="w-full max-w-235px">
            <GolfInput
              register={register}
              isError={errors.admin?.last_name}
              name="admin[last_name]"
              label="Last Name*"
            />
          </div>
        </div>
        <div className="mb-4">
          <GolfInput
            register={register}
            isError={errors.admin?.email}
            name="admin[email]"
            label="Email*"
          />
        </div>
        <InputLabel text="Phone Number" />
        <InputPhoneMask
          name="phone_number"
          inputClass="myCourse__input"
          inputErrorClass="myCourse__input_error"
          flagBoxClass="border-979797"
          id="phone_number"
          isError={errors.phone_number}
          control={control}
        />
        <div className="w-full mt-6">
          <InputLabel text="Group Description*" />
          <div className="w-full flex justify-between mb-3">
            <InputDescription
              text="Who is the group for, when does it play, what formats are used, etc."
            />
            <InputDescription text="50 Character Min" />
          </div>
          <GolfTextarea
            name="about"
            register={register}
            className="w-full h-[123px]"
            text=""
            maxLength={500}
            isError={errors.description}
          />
          <div className="w-full flex justify-end mb-3">
            <InputDescription text={`${description?.length || 0}/500`} />
          </div>
        </div>
        <GroupStatus isPrivate={isPrivate} isPublic={isPublic} isHidden={isHidden} register={register} />
        <div className="w-full max-w-235px mt-[30px]">
          <InputLabel text="Cost" className="leading-[22px!important]" />
          <InputDescription text="If any, and specify by month, year, event, etc." className="mb-5 mt-1" />
          <DollarInput register={register} name="cost" />
        </div>
        <div className="flex justify-end mt-[42px]">
          <CustomButton
            text="Submit"
            className="bg-52A91A text-20/24 text-white font-bold h-14 w-[198px]"
            type="submit"
          />
        </div>
      </form>
    </Page>
  )
}

type GroupStatusPropTypes = {
  isPrivate: boolean
  isPublic: boolean
  isHidden: boolean
  register: any
}

const GroupStatus: React.FC<GroupStatusPropTypes> = ({ isHidden, isPublic, isPrivate, register }) => {
  return (
    <div className="w-full">
      <div className="flex items-center mb-3">
        <InputLabel text="Group Status*" className="leading-[22px!important]" />
        <InputDescription text="Choose One" className="h-4 pt-1 ml-1" />
      </div>
      <div className="flex items-center mb-3">
        <CustomCheckbox
          checkMarkColor="green"
          text="Public"
          name="is_public"
          htmlFor="is_public"
          disabled={isPrivate || isHidden}
          isCheckboxChecked={isPublic}
          className="w-[89px]"
          textClass="ml-3 leading-[30px]"
          register={register}
        />
        <InputDescription text="Anyone can join" />
      </div>
      <div className="flex items-center mb-3">
        <CustomCheckbox
          checkMarkColor="green"
          text="Private"
          name="is_private"
          htmlFor="is_private"
          disabled={isPublic || isHidden}
          isCheckboxChecked={isPrivate}
          className="w-[89px]"
          textClass="ml-3"
          register={register}
        />
        <InputDescription text="Group will be listed, but membership is by invitation" />
      </div>
      <div className="flex items-center">
        <CustomCheckbox
          checkMarkColor="green"
          text="Hidden"
          name="is_hidden"
          htmlFor="is_hidden"
          disabled={isPublic || isPrivate}
          isCheckboxChecked={isHidden}
          className="w-[89px]"
          textClass="ml-3"
          register={register}
        />
        <InputDescription text="Group will not be listed, membership by invitation" />
      </div>
    </div>
  )
}

type DollarInputType = {
  disabled?: boolean
  name: string
  register: any
}

const DollarInput: React.FC<DollarInputType> = ({ disabled = false, name, register }) => {
  return (
    <div className={`golfApp__formELements h-10 w-full ${disabled ? 'disabled' : ''}`}>
      <span className="h-full text-18/22 mx-3 font-bold text-9C9C9C">$</span>
      <input type="text" className="h-full" disabled={disabled} {...register(name)} />
    </div>
  )
}