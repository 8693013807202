const FORMATTED_ID_LENGTH = 6
export const formatId = (id: string | number | undefined): string => {
  let formattedId: string
  if (typeof id === 'number') {
    formattedId = `${id}`
  } else if (id) {
    formattedId = id
  } else {
    formattedId = '000000'
  }
  return `${new Array(FORMATTED_ID_LENGTH + 1 - formattedId.length).join('0')}${formattedId}`
}