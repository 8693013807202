import { returnColumnStyle } from '../helpers/returnColumnsStyles'

export interface TableStyles {
  rowClasses: string,
  headerClasses: string
  headerSpace?: string,
  rowColors: string[],
  bodyClasses: string,
  emptyPlaceholderClasses: string,
  getColumnClasses: (id: string, value: string) => string
}

export const DefaultTableStyles: TableStyles = {
  rowClasses: 'shadow mb-2.5 border border-solid border-EEF1EB rounded-5px cursor-pointer hovered-row',
  headerClasses: 'text-white bg-81A46A text-15/18',
  headerSpace: 'mb-15px',
  rowColors: ['white'],
  bodyClasses: '',
  emptyPlaceholderClasses: 'flex justify-center mb-14',
  getColumnClasses: returnColumnStyle
}