import { GolfAppHeader } from '../Layouts/GolfAppLayout/GolfAppHeader'
import React from 'react'
import { approveInvite, getInviteInfo } from '../../store/invite/actions'
import { AcceptInvitationForm } from '../GolfApp/AcceptInvitation/AcceptInvitationForm'
import { ACCEPTED_INVITE } from '../../constants/urls'
import { useApiResource } from '../../hooks/api/useApiResource'
import { useApi } from '../../hooks/api/useApi'
import { useNavigateSearch } from '../../hooks/useNavigateSearch'
import { useLocation } from 'react-router-dom'

export const AcceptInvitationPage: React.FC = () => {
  const navigate = useNavigateSearch()

  const { search } = useLocation()
  const uid = new URLSearchParams(search).get('uidb64')
  const token = new URLSearchParams(search).get('token')
  const email = new URLSearchParams(search).get('email')
  const courseId = new URLSearchParams(search).get('course_id')
  const logo = new URLSearchParams(search).get('logo')
  const name = new URLSearchParams(search).get('username')
  const courseTitle = new URLSearchParams(search).get('course_title')

  const [inviteLoading, invite] = [false, {
    first_name: name?.split(' ')[0] || '',
    last_name: name?.split(' ')[1] || '',
    email: email || '',
    job_title: '',
    course: { logo: logo || '', title: courseTitle || '', is_private: false }
  }]
  const [loading, handleSubmit] = useApi(approveInvite)

  const onSubmit = (data: { password: string }) => {
    handleSubmit({ token, invite, uid, email, course_id: courseId, new_password: data.password })
    .then(({ error }) => {
      if (!error) {
        navigate(ACCEPTED_INVITE, { showMobile: true, username: name, logo: logo, course_title: courseTitle, email })
      }
    })
  }

  return (
    <div className="relative width__container">
      <GolfAppHeader user={invite || {}} loading={inviteLoading} disabledDropdown />
      <section className="w-full flex justify-center pt-10 sm:pt-[70px] px-7">
        <div className="w-full max-w-550px">
          <p className="w-full text-center text-4A4A4A font-medium text-20/24 sm:text-36/50">
            Hi {invite?.first_name}!
          </p>
          <p className="w-full text-center text-4A4A4A font-medium text-20/24 sm:text-36/50">
            Create a Password
          </p>
          <AcceptInvitationForm invite={invite} onSubmit={onSubmit} loading={loading} />
        </div>
      </section>
    </div>
  )
}