export const TERMS_OF_CONDITION = '/terms-of-conditions'
export const SIGN_UP = '/sign-up'
export const NOT_APPROVED = '/not-approved'
export const LOGIN = '/login'
export const LOGGED_OUT = '/logged-out'
export const RESET_PASSWORD = '/reset-password'
export const NEW_PASSWORD = '/new-password'
export const CONGRATULATIONS = '/congratulations'
export const HOW_IT_WORKS = '/how-it-works'
export const PASSBOOK = '/passbook'
export const EVENTS = '/events'
export const PROMOTE_MY_COURSE = '/promote-my-course'
export const NEW_EVENT = '/events/new'
export const EDIT_EVENT = '/events/edit/:id'
export const MY_COURSE = '/my-course-information'
export const MY_COURSE_HISTORY = '/my-course/history'
export const MY_COURSE_PHOTOS = '/my-course/photos'
export const MY_COURSE_TOURNAMENTS = '/my-course/tournaments'
export const MY_TEAM = '/my-team'
export const ADD_NEW_TEAMMATES = '/my-team/add'
export const EDIT_TEAMMATE = '/my-team/edit/:id'
export const OFFERS = '/offers'
export const NEW_OFFER = '/offers/new'
export const EDIT_OFFER = '/offers/edit/:id'
export const OFFERS_AVAILABILITY_GRID = '/availability-grid-offers'
export const EDIT_AVAILABILITY_GRID = '/availability-grid-offers/edit/:id'
export const OFFERS_FEED = '/feed-offers'
export const EDIT_FEED_OFFER = '/feed-offers/edit/:id'
export const BIN = '/bin'
export const ACCEPT_INVITE = '/accept-invite'
export const ACCEPTED_INVITE = '/accepted-invite'
export const MY_PROFILE = '/profile'
export const ADD_COURSE = '/course/add'
export const GROUPS_AND_ASSOCIATIONS = '/groups'
