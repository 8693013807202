import { useOnlySpecificType } from '../../../../hooks/useOnlySpecificType'
import { MainOffer, Offer } from '../../../../models/Offer'
import { useOffers } from '../../../../hooks/useOffers'
import { useOutletContext } from '../../../Common/CustomContext'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Page } from '../../Components/Page'
import { OfferSelect } from '../OfferSelect'
import { Form } from './Form'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useApi } from '../../../../hooks/api/useApi'
import {
  deleteFeedOffer,
  getFeedOffers,
  republishFeedOffer,
  unpublishFeedOffer,
  updateFeedOffer
} from '../../../../store/offer/actions'
import EditIcon from "../../../../assets/svg/editPencil.svg";
import UnpublishIcon from "../../../../assets/svg/unpublish-black.svg";
import UnpublishGreenIcon from "../../../../assets/svg/unpublish-green.svg";
import DeleteIcon from "../../../../assets/svg/delete-unfilled-black.svg";
import { ConfirmDeletingDialog } from "../../Components/dialogs/ConfirmDeletingDialog";
import { ConfirmUnpublishingDialog } from "../../Components/dialogs/ConfirmUnpublishingDialog";
import { OFFERS_AVAILABILITY_GRID, OFFERS_FEED } from "../../../../constants/urls";
import { getFeedOfferStatus, getOfferStatus, statusToUnpublished } from "../../../../helpers/offer";

export const EditFeedOfferPage = () => {
  useOnlySpecificType('public')
  const navigate = useNavigate()
  const [editable, setEditable] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [unpublishing, setUnpublishing] = useState(false)
  const offers: MainOffer[] = useOffers()
  const feedOffers = useSelector((state: RootState) => state.offer.feedOffers)
  const { course } = useOutletContext()
  const { id } = useParams()
  const [selectedOfferId, setSelectedOfferId] = useState<number>()
  const [offersLoading, loadOffers] = useApi(getFeedOffers)
  const [loading, publish] = useApi(updateFeedOffer)
  const [deleteLoading, deleteOfferApi] = useApi(deleteFeedOffer)
  const [unpublishingLoading, unpublishOfferApi] = useApi(unpublishFeedOffer)
  const [republishingLoading, republishOfferApi] = useApi(republishFeedOffer)

  const feedOffer = useMemo(() => {
    return feedOffers?.find((offer) => offer.id === Number(id))
  }, [feedOffers, id])
  const offerId = feedOffer?.offer

  useEffect(() => {
    if (!feedOffers && !offersLoading) {
      loadOffers()
    }
  }, [offersLoading, feedOffers])

  useEffect(() => {
    setSelectedOfferId(Number(offerId))
  }, [offerId])

  const selectedOffer = offers.find(({ id }) => id === selectedOfferId)

  const onSuccess = () => {
    setEditable(false)
  }

  const confirmDeleting = () => {
    feedOffer && deleteOfferApi(feedOffer.id).then(() => {
      finishDeleting()
      navigate(OFFERS_FEED)
    })
  }

  const finishDeleting = () => {
    setDeleting(false)
  }

  const confirmUnpublishing = () => {
    feedOffer && unpublishOfferApi(feedOffer).then(finishUnpublishing)
  }

  const finishUnpublishing = () => {
    setUnpublishing(false)
    navigate(OFFERS_FEED)
  }

  const republishOffer = () => {
    republishOfferApi(feedOffer).then(() => {
      setEditable(true)
    })
  }

  const unpublished = !!(feedOffer && statusToUnpublished(feedOffer.status))
  return (
    <Page title="Feed" relative>
      <div className="flex justify-end absolute right-[30px] top-5">
        <div className="flex">
          <button className="mr-5" onClick={() => setEditable(true)}>
            <figure className="w-full flex justify-center">
              <img src={EditIcon} alt="" />
            </figure>
            <span className="text-[9px] leading-3 text-333333 text-center">
              Edit
            </span>
          </button>
          {unpublished ?
            (
              <button className="mr-5" onClick={republishOffer}>
                <figure className="w-full flex justify-center">
                  <img src={UnpublishGreenIcon} alt=""/>
                </figure>
                <span className="text-[9px] leading-3 text-52A91A text-center">
                  Unpublish
                </span>
              </button>
            ) :
            (
              <button className="mr-5" onClick={() => setUnpublishing(true)}>
                <figure className="w-full flex justify-center">
                  <img src={UnpublishIcon} alt=""/>
                </figure>
                <span className="text-[9px] leading-3 text-333333 text-center">
                  Unpublish
                </span>
              </button>
            )
          }
          <button className="" onClick={() => setDeleting(true)}>
            <figure className="w-full flex justify-center">
              <img src={DeleteIcon} alt="" />
            </figure>
            <span className="text-[9px] leading-3 text-333333 text-center">
              Delete
            </span>
          </button>
        </div>
      </div>
      <p className="text-14/16 text-33333 mb-2 h-30px leading-[30px]">
        Improve your yield by sending targeted Offers to golfers who’ve expressed interest in playing your course.
      </p>
      <div className="w-full relative">
        <OfferSelect offers={offers || []} selectedOfferId={selectedOfferId} onChange={setSelectedOfferId} disabled />
      </div>
      <Form
        offedId={selectedOfferId || 0}
        offer={selectedOffer as Offer}
        feedOffer={feedOffer}
        course={course}
        editable={editable}
        loading={loading}
        submit={publish}
        onSuccess={onSuccess}
        onBack={() => navigate(OFFERS_FEED)}
      />
      <ConfirmDeletingDialog
        title="Are You Sure You Want To Delete This Offer?"
        opened={deleting}
        onSubmit={confirmDeleting}
        onReject={finishDeleting}
        loading={deleteLoading}
      />
      <ConfirmUnpublishingDialog
        title="Are You Sure You Want To Unpublish This Offer?"
        opened={unpublishing}
        onSubmit={confirmUnpublishing}
        onReject={finishUnpublishing}
        loading={unpublishingLoading}
      />
    </Page>
  )
}