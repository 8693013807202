import React, { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { CropImage } from '../../../Common/CropImage'
import { CoursePhoto } from './CoursePhoto'
import { WarningMessage } from './WarningMessage'

import { readFile } from '../../../../helpers/readFile'
import { useLoading } from '../../../../hooks/api/useLoading'
import { useApiDispatch } from '../../../../hooks/api/useApiDispatch'
import { ReactComponent as AddPhoto } from '../../../../assets/svg/addPhoto.svg'
import { CourseInternalPage } from '../CourseInternalPage'
import { CustomButton } from '../../../Common/CustomButton'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../store/store'
import { deletePhotos, getPhotos, updatePhotos } from '../../../../store/course/actions'
import { useSaveButtonConfig } from '../../../../hooks/useSaveButtonConfig'

type PropsType = {
  setFullScreenBg: (args: boolean) => void
}

const IS_READY = false

export const CoursePhotosPage: React.FC<PropsType> = ({ setFullScreenBg }) => {
  const handleDelete = useApiDispatch(deletePhotos)
  const handleUpdate = useApiDispatch(updatePhotos)
  const loading = useLoading([deletePhotos, updatePhotos])
  const saveButtonConfig = useSaveButtonConfig(loading)
  const [photoImg, setPhotoImg] = useState<unknown>()
  const [photoName, setPhotoName] = useState<string>('')
  const [croppedImage, setCroppedImage] = useState<any>(null)
  const [deletedPhotosId, setDeletedPhotosId] = useState<Array<number | string>>([])
  const [croppedCoursePhotos, setCroppedCoursePhotos] = useState<
    { image: unknown; id: number | string }[]
  >([])
  const dispatch = useDispatch<AppDispatch>()

  const isInputFileActive = croppedCoursePhotos.length >= 10
  const isInputFileWarning = croppedCoursePhotos.length === 10
  const isCoursePhotosEmpty = croppedCoursePhotos.length === 0

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  async function onDrop(acceptedFiles: any) {
    if (isInputFileActive) return
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      let imageDataUrl = await readFile(file)
      setPhotoImg(imageDataUrl)
      setPhotoName(acceptedFiles[0].name)
    }
  }

  const onUploadModalClose = () => {
    setPhotoImg('')
    setFullScreenBg(false)
  }

  const deleteOnePhoto = useCallback(
    (deletedPhoto: unknown, id: number | string) => {
      const updatedPhotos = croppedCoursePhotos.filter((photo) => {
        return photo.image !== deletedPhoto
      })

      croppedCoursePhotos.filter((photo) => {
        if (photo.id === id) {
          setDeletedPhotosId((prev: any) => {
            return [...prev, photo.id]
          })
        }
      })

      setCroppedCoursePhotos(updatedPhotos)
    },
    [croppedCoursePhotos]
  )

  useEffect(() => {
    dispatch(getPhotos()).then((res: any) => {
      setCroppedCoursePhotos(res.payload)
    })
  }, [])

  useEffect(() => {
    if (photoImg) {
      setFullScreenBg(true)
    } else {
      setFullScreenBg(false)
    }
  }, [photoImg, setFullScreenBg])

  useEffect(() => {
    if (croppedImage !== null) {
      setCroppedCoursePhotos((prev: any) => {
        return [
          ...prev,
          { data: croppedImage.file, image: croppedImage.url, name: croppedImage.name }
        ]
      })
    }
  }, [croppedImage])

  const save = () => {
    if (deletedPhotosId.length) {
      handleDelete(deletedPhotosId)
    }
    handleUpdate(croppedCoursePhotos).then((res) => {
      setCroppedCoursePhotos(res.payload)
    })
  }

  if (!IS_READY) {
    return (
      <CourseInternalPage title="Course Photos">
        <div className="flex">
          <p className="text-52A91A text-200/240 opacity-0.15 text-center mt-40">COMING SOON</p>
        </div>
      </CourseInternalPage>
    )
  }

  return (
    <CourseInternalPage title="Course Photos">
      {photoImg && (
        <CropImage
          className="uploadImage__modal bottom-0"
          photoImg={photoImg}
          photoName={photoName}
          title="Upload Course Photo"
          onUploadModalClose={onUploadModalClose}
          setCroppedImage={setCroppedImage}
        />
      )}
      <div className="w-full">
        <p className="mb-7 text-4A4A4A text-12/14 opacity-60">
          You can upload up to 10 photos of your course, which will randomly appear with your course
          listing. Photos must be PNG or JPG format and 20MB max.
        </p>
        <div
          className={`rounded-4px border border-solid border-81A46A ${
            isCoursePhotosEmpty && 'pt-70px pb-110px'
          }`}
          {...getRootProps()}
        >
          <input disabled={isInputFileActive} {...getInputProps()} accept="image/jpeg, image/png" />
          <WarningMessage isWarning={isInputFileWarning} />
          {!isCoursePhotosEmpty ? (
            <div className="course-photos-container">
              {croppedCoursePhotos.map((photo, index) => {
                return (
                  <CoursePhoto
                    id={photo.id}
                    img={photo.image}
                    deleteOnePhoto={deleteOnePhoto}
                    index={index}
                    key={photo.image as string}
                  />
                )
              })}
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <AddPhoto className="mb-5" />
              <div>
                <span className="text-18/22 text-4A4A4A opacity-40">Drag and Drop or</span>
                <span className="text-18/22 text-52A91A font-semibold cursor-pointer">
                  {' '}
                  Upload Photos
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end mt-9">
          {!isCoursePhotosEmpty && (
            <CustomButton
              type="button"
              className="main-button h-10 w-32"
              onClick={save}
              loading={loading}
              text={saveButtonConfig.text}
              disabled={isCoursePhotosEmpty || saveButtonConfig.disabled}
            />
          )}
        </div>
      </div>
    </CourseInternalPage>
  )
}
