import * as Yup from 'yup'
import { doubleDateValidator, toApiDateTime, toFormDateTime } from '../../../../helpers/date'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputLabel } from '../../Components/small/InputLabel'
import { InputDescription } from '../../Components/small/InputDescription'
import { InputDoubleCalendar } from '../../Components/InputDoubleCalendar'
import { CustomButton } from '../../../Common/CustomButton'
import React, { useEffect, useMemo, useState } from 'react'
import { GolfTextarea } from '../../Components/GolfTextarea'
import { TextAreaBottomCharsCount } from '../../Components/small/TextAreaBottomCharsCount'
import { CustomCheckbox } from '../../../Common/CustomCheckbox'
import { OfferPreview } from '../OfferForm/OfferPreview'
import { UserCourse } from '../../../../models/UserCourse'
import { Offer } from '../../../../models/Offer'
import format from 'date-fns/format'

type Inputs = {
  date?: { start: string; end: string },
  valid_date?: { start: string; end: string },
  description: string,
  not_applicable: boolean,
}

const validationSchema = Yup.object().shape({
  date: doubleDateValidator,
  valid_date: doubleDateValidator,
  description: Yup.string()
  .required('Offer Description is required')
  .min(50, 'Must have at least 50 characters')
})

type FormPropTypes = {
  offedId: number
  course?: UserCourse
  offer: Offer,
  loading: boolean,
  submit: any,
  onSuccess: (payload: any) => void
  editable: boolean,
  feedOffer?: any,
  onBack?: () => void
}

const toApiFormat = (data: Inputs, offerId: number) => {
  const { date, description, valid_date, not_applicable } = data
  const date_publish_start = date && toApiDateTime(date.start)
  const date_publish_end = date && toApiDateTime(date.end)
  const valid_date_start = valid_date && toApiDateTime(valid_date.start)
  const valid_date_end = valid_date && toApiDateTime(valid_date.end)

  return {
    date_publish_start,
    date_publish_end,
    offer: offerId,
    valid_date_start,
    valid_date_end,
    description,
    not_applicable
  }
}

const fromApiFormat = (data: any): Inputs => {
  const {
    date_publish_start,
    date_publish_end,
    valid_date_start,
    valid_date_end,
    description,
    not_applicable
  } = data || {}

  let date, valid_date
  if (date_publish_start && date_publish_end) {
    date = {
      start: toFormDateTime(date_publish_start),
      end: toFormDateTime(date_publish_end)
    }
  }

  if (valid_date_start && valid_date_end) {
    valid_date = {
      start: toFormDateTime(valid_date_start),
      end: toFormDateTime(valid_date_end)
    }
  }

  return {
    date,
    valid_date,
    description,
    not_applicable
  }
}

export const Form: React.FC<FormPropTypes> = (
  { offedId, course, offer, loading, submit, onSuccess, editable, feedOffer, onBack }
) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    reset
  } = useForm<Inputs>({
    defaultValues: useMemo(() => fromApiFormat(feedOffer) || {}, [feedOffer]),
    resolver: yupResolver(validationSchema)
  })
  useEffect(() => {
    reset(fromApiFormat(feedOffer))
  }, [feedOffer])

  const [initialized, setInitialized] = useState(false)

  const onSubmit = (data: Inputs) => {
    const preparedData: Record<string, any> = toApiFormat(data, offedId)
    if (feedOffer) {
      preparedData.id = feedOffer.id
    }
    submit(preparedData)
    .then(({ error, payload }: any) => {
      if (!error) {
        onSuccess(payload)
      }
    })
  }

  useEffect(() => {
    if (!initialized && offer && !feedOffer) {
      setValue('description', offer.message)
      setInitialized(true)
    }
  }, [offer])

  const [description, notApplicable, validDate] = watch(['description', 'not_applicable', 'valid_date'])
  const previewDate = useMemo(() => {
    if (!(validDate && validDate.start && validDate.end)) {
      return ''
    }
    const start = new Date(validDate.start)
    const end = new Date(validDate.end)
    return `${format(start, 'LLL dd')} - ${format(end, 'LLL dd')}`
  }, [validDate])

  const disabled = useMemo(() => {
    return !editable
  }, [editable])

  return (
    <>
      <div className="flex mt-9">
        <form className="w-full max-w-650px mr-[121px]" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <InputLabel text="Offer Description*" className="" />
            <div className="flex justify-between mb-2">
              <InputDescription
                text="Include Offer details plus when it is applicable (dates, days of the week, time of day, etc.)"
                className="" />
              <InputDescription
                text="50 Character Min"
                className="" />
            </div>
            <GolfTextarea
              text=""
              name="description"
              className="h-100px"
              isError={errors.description}
              register={register}
              disabled={disabled}
              maxLength={300}
              labelClassName="block mb-2 text-333333 opacity-50 text-xs"
            />
            <TextAreaBottomCharsCount
              maxTextLength={300}
              messageLength={description?.length || 0}
            />
          </div>
          <InputLabel text="Valid Dates*" className="mt-[19px] mb-3" />
          <InputDoubleCalendar
            control={control}
            name="valid_date"
            firstName="start"
            secondName="end"
            firstDateLabel="Valid Date Start"
            secondDateLabel="Valid Date End"
            required={true}
            isError={errors.valid_date}
            disabled={disabled}
            hideInputLabels
          />
          <div className="mt-4">
            <CustomCheckbox
              isCheckboxChecked={notApplicable}
              checkMarkColor="green"
              text="Not applicable"
              name="not_applicable"
              register={register}
              htmlFor="not_applicable"
              textClass="ml-2 text-12/14"
              disabled={disabled}
            />
          </div>
          <InputLabel text="Publishing Dates*" className="mt-[35px]" />
          <InputDescription text="When you want this Offer to show up in the Feed" className="mb-[19px]" />
          <InputDoubleCalendar
            control={control}
            name="date"
            firstName="start"
            secondName="end"
            firstDateLabel="Publish To Feed Start"
            secondDateLabel="Publish To Feed End"
            required={true}
            isError={errors.date}
            disabled={disabled}
          />
          <div className="w-full flex justify-between mt-[106px]">
            <CustomButton
              text="< Back"
              className="secondary-button text-16/19 font-medium"
              onClick={onBack}
            />
            <CustomButton
              type="submit"
              className="py-2 px-7 main-button w-169px"
              text="Publish"
              loading={loading}
              disabled={disabled}
            />
          </div>
        </form>
        <div className="pt-8">
          <OfferPreview
            course={course}
            imageUrl={offer?.image?.url}
            name={offer?.name}
            promoCode={offer?.code}
            description={description}
            dates={previewDate}
          />
        </div>
      </div>
    </>
  )
}