import { ComingSoonText } from '../../Common/ComingSoonText'

import { ReactComponent as Offers } from '../../../assets/svg/golfAppOffers.svg'
import { ReactComponent as Calendar } from '../../../assets/svg/golfAppCalendar.svg'
import { ReactComponent as Passbook } from '../../../assets/svg/golfAppPassbook.svg'
import { ReactComponent as BuyItNow } from '../../../assets/svg/golfAppBuyItNow.svg'
import { ReactComponent as Course } from '../../../assets/svg/golfAppMyCourse.svg'
import { ReactComponent as MyTeam } from '../../../assets/svg/golfAppMyTeam.svg'
import { ReactComponent as Events } from '../../../assets/svg/golfAppEvents.svg'
import { ReactComponent as Feed } from '../../../assets/svg/golfAppFeed.svg'
import { ReactComponent as Groups } from '../../../assets/svg/golfAppGroups.svg'
import { ReactComponent as Sheets } from '../../../assets/svg/golfAppSheets.svg'
import { BIN, EVENTS, MY_COURSE, MY_TEAM, OFFERS, OFFERS_AVAILABILITY_GRID, OFFERS_FEED } from '../../../constants/urls'

export const categoriesList = [
  {
    title: 'Events',
    text: 'Hosting a tournament or holding a party? Promote these events, course news, and more through the content Feed on the GolfBevy app. Give the Event a title, set its date and time, and specify when you want the details published. Be sure you have a photo, which shows up prominently in the Feed, and a link to your website where people can sign up.',
    icon: Events,
    link: EVENTS,
    type: 'public'
  },
  {
    title: 'Offers (Public Courses Only)',
    text: 'Put discounts and deals in front of all the golfers who’ve favorited your course by putting them in the Feed, or target specific individuals by using the Availability Grid. You can create a new Offer or re-use an existing one. Specify when the offer begins and ends, explain the offer, create a Promo Code that is applied when they purchase a tee time, and enter a link to the offer on your website.',
    icon: Offers,
    link: OFFERS,
    type: 'public'
  },
  {
    title: 'Feed',
    text: 'Use the Feed to publish an Offer that will be seen by all the golfers who’ve favorited your course. Also use the Feed to publish news about Events at your course. Offers and Events will appear alongside the articles and news that golfers come to GolfBevy for every day. ',
    icon: Feed,
    link: OFFERS_FEED,
    type: 'public'
  },
  {
    title: 'Availability Grid',
    text: 'Golfers fill out My Availability, sharing with their friends when they want to play up to 2 weeks in advance. You can see this information and then send targeted offers that help fill open times on your tee sheet as soon as today. Click on the names of golfers you want to target. Create new offers or re-use existing ones by clicking on the “Select Offer” drop-down menu.',
    icon: Calendar,
    link: OFFERS_AVAILABILITY_GRID,
    type: 'public'
  },
  {
    title: 'GolfBevy Premium (Public Courses Only)',
    text: 'GolfBevy Premium updates the paper-only coupon book for the digital age with additional functionality to attract more golfers to your course. We are creating GolfBevy Premium right now based on input from public-course Operators.',
    icon: Passbook,
    component: ({ className }: { className: string }) => <ComingSoonText className={className} />,
    type: 'public'
  },
  {
    title: 'Buy It Now (Private Clubs Only)',
    text: 'Looking to generate new-member leads or extra revenue? Let prospects play your course through your club “ambassadors” program (if you have one, which we suggest you do) or as a “member for a day.” You specify when the offer is available and the cost; GolfBevy connects interested golfers with your membership office, taking a small percentage as our fee.',
    icon: BuyItNow,
    link: BIN,
    component: ({ className }: { className: string }) => <ComingSoonText className={className} />,
    type: 'private'
  },
  {
    title: 'Groups and Associations',
    text: 'One way to get golfers playing more often is by getting them to join Groups and Associations at your course. Men’s Clubs, Thursday 9 and Wine, Harry’s Tuesday Afternoon Shoot-out… all these encourage more participation and give you more data. List the Groups and Associations the course manages, then golfers can join them through the app with one click. You should encourage golfers to list their own groups, as well.',
    icon: Groups,
    component: ({ className }: { className: string }) => <ComingSoonText className={className} />,
    type: 'all'
  },
  {
    title: 'My Course',
    text: 'Make sure all the golfers on the GolfBevy app have the latest information about your course, from its website, phone number, and physical address to the architects who designed it and the tournaments that have been, and will be, held there. This is also where you can enter up to 10 photographs, which will randomly appear with your course description on the app.',
    icon: Course,
    link: MY_COURSE,
    type: 'all'
  },
  {
    title: 'My Team',
    text: 'Use this page to designate those staffers at your club who you want to have the responsibility to set up and manage the offers and events explained here. Just give us their name and email: We’ll notify them and help them get registered to handle all those jobs for you.',
    icon: MyTeam,
    link: MY_TEAM,
    type: 'all'
  },
  {
    title: 'Tip Sheets',
    text: 'Here’s where you’ll find tips, ideas, and other information to help you make the most of GolfBevy, from best practices and marketing ideas to real-world examples, for public courses and private clubs.',
    icon: Sheets,
    type: 'all'
  }
]
