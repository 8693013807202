import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { AsyncThunk } from '@reduxjs/toolkit'
import { isIntersect } from '../../helpers/array'
import { useMemo } from 'react'

export const useLoading = (thunks: AsyncThunk<any, any, any> | AsyncThunk<any, any, any>[]) => {
  const api = useSelector((state: RootState) => state.api)
  const { loadingRequests } = api
  return useMemo(() => {
    let workedThunks = thunks
    if (!(workedThunks instanceof Array)) {
      workedThunks = [workedThunks]
    }

    const thunkPrefixes: string[] = workedThunks.map(({ typePrefix }) => typePrefix)

    return isIntersect(thunkPrefixes, loadingRequests)
  }, [loadingRequests, thunks])

}
