import { MainOffer } from '../../../models/Offer'
import { TableConfig } from '../../../models/TableConfig'

export const offerTableConfig: TableConfig[] = [
  { name: 'ID#', id: 'id', width: 'max-w-100px' },
  {
    name: 'Title',
    id: 'name',
    width: 'max-w-480px',
    classNames: { data: 'truncate' }
  },
  {
    name: 'Last Published to Feed',
    id: 'last_published_to_feed',
    width: 'max-w-[220px]',
    getData: (row: MainOffer) => row.last_published_to_feed || '',
    classNames: { all: 'text-center' }
  },
  {
    name: 'Last Published to Av. Grid',
    id: 'last_published_to_av_grid',
    width: 'max-w-[220px]',
    getData: (row: MainOffer) => row.last_published_to_av_grid || '',
    classNames: { all: 'text-center' }
  }
]
