import React, { useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { CustomButton } from '../../Common/CustomButton'
import { Input } from '../../Common/Input'
// import { SupportEmailText } from '../../Common/SupportEmailText'
import { CropImage } from '../../Common/CropImage'
import { UploadLogo } from '../../Common/UploadLogo'

import { onImgChange } from '../../../helpers/onImageChange'
import { onImgDragAndDown } from '../../../helpers/onImageDragAndDrop'
import { AppDispatch } from '../../../store/store'
import { AnyAction } from '@reduxjs/toolkit'
import { validation } from '../../../store/auth/actions'
import { useApiDispatch } from '../../../hooks/api/useApiDispatch'
import { useLoading } from '../../../hooks/api/useLoading'

type Inputs = {
  dl_picture: string
  business_card: string
  linkedin_url: string
  emails: string
}

type PropsType = {
  setFullScreenBg: (isFull: boolean) => void
  dispatch: AppDispatch
  setProgress: (progress: string) => AnyAction
  nextProgress: string
  prevProgress: string
  user: any
}

export const ValidationQuestions: React.FC<PropsType> =
  ({ setFullScreenBg, dispatch, setProgress, prevProgress, nextProgress, user }) => {
    const sendMainData = useApiDispatch(validation)
    const loading = useLoading(validation)

    const [dlImg, setDLImg] = useState<any>()
    const [businessCardImg, setBusinessCardImg] = useState<any>()
    const [dlCroppedLogo, setDLCroppedLogo] = useState<any>(null)
    const [businessCardCroppedLogo, setbusinessCardCroppedLogo] = useState<any>(null)
    const [dlFileName, setDLFileName] = useState<any>('')
    const [businessCardFileName, setBusinessCardFileName] = useState<any>('')
    const [notFieldErrorMessage, setNotFieldErrorMessage] = useState<string | null>()

    const {
      handleSubmit,
      register,
      setError,
      control,
      watch,
      formState: { errors }
    } = useForm<Inputs>()

    const onUploadModalClose = () => {
      if (dlImg) {
        setDLImg('')
        setDLFileName(dlImg?.name)
      }
    }

    const onUploadModalClose2 = () => {
      if (businessCardImg) {
        setBusinessCardImg('')
        setBusinessCardFileName(businessCardImg?.name)
      }
    }

    useEffect(() => {
      if (dlImg) {
        setFullScreenBg(true)
      } else {
        setFullScreenBg(false)
      }
    }, [dlImg, setFullScreenBg])

    const onSubmit: SubmitHandler<Inputs> = (data) => {
      sendMainData({
        dlPicture: dlCroppedLogo ? { image: dlCroppedLogo?.file, name: dlFileName } : undefined,
        businessCardPicture: businessCardCroppedLogo ? { image: businessCardCroppedLogo?.file, name: businessCardFileName } : undefined,
        linkedIn: data.linkedin_url,
        businessColleagues: data.emails
      })
      .then(({ error, payload }) => {
        if (!error) {
          return dispatch(setProgress(nextProgress))
        }
        if (error) {
          const errors = payload?.errors || {}
          if (payload === 'This field may not be blank.') {
            errors['linkedin_url'] = [payload]
          }
          if (payload === 'Should be 2 emails') {
            errors['emails'] = [payload]
          }
          Object.entries(errors)
            .forEach((keyValue) => {
              if (keyValue[0] === 'non_field_errors') {
                setNotFieldErrorMessage((keyValue[1] as Array<string>).join(','))
              } else {
                // @ts-ignore
                setError(keyValue[0], { message: keyValue[1].join(',') })
              }
            })
        }
      }).catch(console.log)
    }

    const goBack = () => {
      dispatch(setProgress(prevProgress))
    }

    const [linkedIn, emails] = watch(["linkedin_url", "emails"])

    const isAllRequiredInfoEntered = useMemo(() => {
      return !!dlCroppedLogo && (!!businessCardCroppedLogo || !!linkedIn)
    }, [dlCroppedLogo, businessCardCroppedLogo, linkedIn])


    useEffect(() => {
      setNotFieldErrorMessage(null)
    }, [linkedIn, emails, businessCardCroppedLogo, dlCroppedLogo])

    return (
      <>
        {dlImg && (
          <CropImage
            className="uploadImage__modal"
            logoImg={dlImg.url}
            title="Business card and DL picture"
            onUploadModalClose={onUploadModalClose}
            setCroppedLogo={setDLCroppedLogo}
          />
        )}

        {businessCardImg && (
          <CropImage
            className="uploadImage__modal"
            logoImg={businessCardImg.url}
            title="Business card"
            onUploadModalClose={onUploadModalClose2}
            setCroppedLogo={setbusinessCardCroppedLogo}
          />
        )}

        <div className="relative pt-4">
          <div className="flex ml-140px pr-120px">
            <article className="mb-3">
              <h1 className="signUp__component_title text-4A4A4A">Help Us Validate You</h1>
              <p className="text-4A4A4A text-14/16 opacity-80">
                We need to know that you manage {user.course.name}. One of the first two responses is required: The
                more you share, the faster you'll be validated.
              </p>
            </article>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pl-140px py-6 pr-5 bg-52A91A/15 mb-2">
              {notFieldErrorMessage && <span className="text-C43100 text-12/14">{notFieldErrorMessage}</span>}
              <div className="mb-1.5">
                <Controller
                  name="dl_picture"
                  control={control}
                  render={({ field }) => {
                    return (
                      <UploadLogo
                        editPencil={true}
                        imageTitle="Business card and DL picture"
                        imageTitleText="DL mandatory and (Business card photo or linked profile or team page URL)"
                        imageBlockClass={`w-220px pt-3 pb-3 pl-12 pr-12 border border-solid rounded-5px mt-0 ${
                          errors.dl_picture ? 'border-C43100' : 'border-BCC5E8'
                        }`}
                        onImgChange={onImgChange}
                        onImgDragAndDrop={onImgDragAndDown}
                        croppedLogo={dlCroppedLogo?.url}
                        isText={false}
                        setLogoImg={setDLImg}
                        isUploadedImageRounded={true}
                        inputName="dlPicture"
                        handleChange={field.onChange}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-1.5 flex items-center">
                <div className="">
                  <Controller
                    name="business_card"
                    control={control}
                    render={({ field }) => {
                      return (
                        <UploadLogo
                          oneRow={false}
                          editPencil={false}
                          imageTitle="Business card"
                          imageTitleText=""
                          imageBlockClass={`w-220px pt-3 pb-3 pl-12 pr-12 border border-solid rounded-5px mt-0 ${
                            errors.business_card ? 'border-C43100' : 'border-BCC5E8'
                          }`}
                          onImgChange={onImgChange}
                          onImgDragAndDrop={onImgDragAndDown}
                          croppedLogo={businessCardCroppedLogo?.url}
                          isText={false}
                          setLogoImg={setBusinessCardImg}
                          isUploadedImageRounded={true}
                          inputName="business_card"
                          handleChange={field.onChange}
                        />
                      )
                    }}
                  />
                </div>
                <div className="max-w-75px w-full mt-4">
                  <span className="block text-base text-black opacity-80 text-center">- OR -</span>
                </div>
                <div className="max-w-550px w-80">
                  <Input
                    name="linkedin_url"
                    labelClass="text-14/20"
                    label="Your LinkedIn profile URL"
                    htmlFor="linkedin_url"
                    error={errors.linkedin_url}
                    register={register}
                    required={false}
                  />
                </div>
              </div>
            </div>
            <div className="max-w-550px w-full ml-140px mb-20px">
              <Input
                name="emails"
                inputClass="mb-3"
                label="At least 2 emails of your business colleagues"
                labelText="Separate by commas"
                htmlFor="emails"
                error={errors.emails}
                required={false}
                register={register}
              />
              <div className="flex justify-between items-center">
                {/*<p className="inline-block">*/}
                {/*  Need help?*/}
                {/*  <SupportEmailText />*/}
                {/*</p>*/}
                <CustomButton
                  className="secondary-button text-20/24"
                  text="< Back"
                  onClick={goBack}
                />
                <CustomButton
                  className={`py-4 px-68px text-20/24 font-bold text-white bg-52A91A`}
                  text="Submit"
                  type="submit"
                  loading={loading}
                  disabled={!isAllRequiredInfoEntered}
                />
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }
