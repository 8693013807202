import React from 'react'
import Like from '../../../../../../assets/svg/like.svg'
import Comment from '../../../../../../assets/svg/comment.svg'
import SaveAlt from '../../../../../../assets/svg/saveAlt.svg'

export const EventLine: React.FC = () => {
  return (
    <div
      className="absolute bottom-0 w-full h-3 flex items-end pb-0.5"
      style={{
        background: 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
        left: '0'
      }}>
      <img src={Like} alt="" className="h-1.5 w-1.5 ml-1.5" />
      <span className="text-white text-5/8 ml-1">152 Likes</span>
      <img src={Comment} alt="" className="h-1.5 w-1.5 ml-1.5" />
      <span className="text-white text-5/8 ml-1">12 Comments</span>
      <img src={SaveAlt} alt="" className="h-1.5 w-1.5 ml-1.5" />
      <span className="text-white text-5/8 ml-1">Share</span>
    </div>
  )
}