import format from 'date-fns/format'
import * as Yup from 'yup'
import moment from "moment-timezone";

export const TODAY = new Date()

export const getFormattedRange = (start: Date, end?: Date): string => {
  if (!end) {
    return format(start, 'MM/dd/yyyy')
  }

  const [startYear, startMonth, startDay] = divideDate(start)
  const [endYear, endMonth, endDay] = divideDate(end)

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      if (startDay === endDay) {
        return format(start, 'MM/dd/yyyy')
      }
      return `${startMonth}/${startDay} - ${endDay}/${startYear}`
    }
    return `${startMonth}/${startDay} - ${endMonth}/${endDay}/${startYear}`
  }

  return `${format(start, 'MM/dd/yyyy')} - ${format(end, 'MM/dd/yyyy')}`
}

const divideDate = (date: Date) => {
  return [date.getFullYear(), format(date, 'MM'), format(date, 'dd')]
}

export const toApiDateTime = (stringDate: string | Date): string => {
  let date = stringDate
  if (typeof date === 'string') {
    date = new Date(stringDate)
  }
  return format(date, 'yyyy-MM-dd')
}

export const toFormDateTime = (stringDate: string, formatStr: string = 'MMM D,YYYY'): string => {
  return moment(stringDate).tz("UTC").format(formatStr)
}

const yesterday = new Date(Date.now() - 86400000)

export const doubleDateValidator =
  Yup.object().shape({
    start: Yup.date()
    .min(yesterday, 'The start date can\'t be before today')
    .required('The start date is required'),
    end: Yup.date().min(
      Yup.ref('start'),
      'The end date can\'t be before start date'
    )
    .required('The end date is required')
  })