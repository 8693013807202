import React, { useState } from 'react'
import { CustomDropdown } from '../../../Common/CustomDropdown'
import { ReactComponent as UserIcon } from '../../../../assets/svg/userIcon.svg'
import { ReactComponent as Ellipse } from '../../../../assets/svg/mainHeaderDropdownEllipse.svg'
import { ReactComponent as Arrow } from '../../../../assets/svg/mainHeaderDropdownArrow.svg'
import { removeFromLocalStorage } from '../../../../utils/localStorage'
import { useNavigate } from 'react-router-dom'
import { ADD_COURSE, LOGGED_OUT, MY_PROFILE } from '../../../../constants/urls'

type PropTypes = {
  user: any
  disabled?: boolean
}

type Link = {
  name: string
  onClick: () => void
}

export const UserDropdown: React.FC<PropTypes> = ({ user, disabled }) => {
  const navigate = useNavigate()
  const [isOpen, setOpenRaw] = useState(false)

  const setOpen = (opened: boolean) => {
    if (!disabled) {
      setOpenRaw(opened)
    }
  }

  const logout = () => {
    setOpen(false)
    removeFromLocalStorage('authToken')
    window.location.replace(LOGGED_OUT)
  }

  const goTo = (link: string) => {
    navigate(link)
    setOpen(false)
  }

  const links = [
    { name: 'My Profile', onClick: () => goTo(MY_PROFILE) },
    { name: 'Add Course', onClick: () => goTo(ADD_COURSE) },
    { name: 'Logout', onClick: logout }
  ]

  return (
    <CustomDropdown isOpen={isOpen} setOpen={setOpen}>
      {{
        header: <Header isOpen={isOpen} email={user.email} />,
        body: <Body links={links} />
      }}
    </CustomDropdown>
  )
}

const Header: React.FC<{ isOpen: boolean, email: string }> = ({ isOpen, email }) => {
  return (
    <div className="flex items-center">
      <UserIcon />
      <span className="text-15/18 text-333333 mx-2 min-w-[100px] leading-[15px] h-[15px] mt-[-3px]">{email}</span>
      <figure className={`relative cursor-pointer ${isOpen ? 'rotate-180' : ''}`}>
        <Ellipse />
        <Arrow className="absolute top-5px left-1" />
      </figure>
    </div>
  )
}

const Body: React.FC<{ links: Link[] }> = ({ links }) => {
  return (
    <ul className="px-1">
      {links.map((link, index) => {
        return (
          <li
            className="text-14/16 text-333333 leading-[30px] border-b border-solid border-979797 cursor-pointer last:border-none"
            onClick={link.onClick}
            key={index}
          >
            {link.name}
          </li>
        )
      })}
    </ul>
  )
}