import React, { useEffect, useRef } from 'react'

type PropTypes = {
  isOpen: boolean,
  setOpen: (open: boolean) => void,
  onClose?: () => void,
  children: {
    header: JSX.Element,
    body: JSX.Element,
  },
  classNames?: {
    mainContainer?: string
    headerContainer?: string
    bodyContainer?: string
  }
}

export const CustomDropdown: React.FC<PropTypes> = ({ isOpen, setOpen, classNames, onClose, children }) => {
  const selectRef = useRef(null)
  const close = () => {
    setOpen(false)
    onClose && onClose()
  }

  const handleOutsideClick = (e: MouseEvent) => {
    if (!isOpen) {
      return
    }

    const path = (e as any).path || (e.composedPath && e.composedPath())
    if (!path.includes(selectRef.current)) {
      close()
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', handleOutsideClick)
    }
    return () => {
      document.body.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])
  return (
    <div ref={selectRef} className={`relative ${classNames?.mainContainer ? classNames?.mainContainer : ''}`}>
      <div onClick={() => setOpen(true)} className={classNames?.headerContainer ? classNames?.headerContainer : ''}>
        {children.header}
      </div>
      {isOpen && (
        <div className={`absolute shadow-md w-full bg-white z-10 overflow-hidden
        ${classNames?.bodyContainer ? classNames?.bodyContainer : ''}`}>
          {children.body}
        </div>
      )}
    </div>
  )
}