import { AsyncThunk } from '@reduxjs/toolkit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useApi } from './useApi'
import _ from 'lodash'

const PER_PAGE = 50

export type UsePaginatedApi = {
  data: Record<string, any>[][]
  loading: boolean
  loadMore: () => void
  hasNextPage: boolean
}


export const usePaginatedApi = (thunk: AsyncThunk<any, any, any>, search?: string): UsePaginatedApi => {
  const [page, setPage] = useState<number>(1)
  const [data, setData] = useState<any[]>([])
  const [pageCount, setPageCount] = useState<number>(1)
  const [loading, load] = useApi(thunk)


  const loadData = useCallback(async (prevData: any[], requestPage) => {
    const { payload } = await load({ page: requestPage, search, pageSize: PER_PAGE })
    const { count, result } = payload

    if (count && result) {
      setData(_.uniqBy([...prevData, ...result], item => item.id))
      setPageCount(Math.ceil(count / PER_PAGE))
    } else {
      setData([...prevData])
      setPageCount(1)
    }
  }, [page, search])

  const loadMore = useCallback(() => {
    if (page < pageCount) {
      const newPage = page + 1
      setPage(newPage)
      loadData(data, newPage)
    }
  }, [page, pageCount, loadData, data])

  useEffect(() => {
    setPage(1)
    loadData([], 1)
  }, [search])

  return useMemo(() => {
    return {
      data,
      loading,
      loadMore,
      hasNextPage: page < pageCount
    }
  }, [data, loading, loadMore, pageCount, page])

}