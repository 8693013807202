import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOutletContext } from '../Common/CustomContext'
import { BIN, EVENTS } from '../../constants/urls'

export const PromoteMyCoursePage: React.FC = () => {
  const navigate = useNavigate()
  const { course } = useOutletContext()

  useEffect(() => {
    if (course) {
      const isPrivate = course.is_private
      if (isPrivate) {
        return navigate(BIN)
      }

      navigate(EVENTS)
    }
  }, [course])

  return <></>
}