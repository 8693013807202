import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Input } from '../../Common/Input'
import { CustomButton } from '../../Common/CustomButton'
import { HOW_IT_WORKS, RESET_PASSWORD, SIGN_UP } from '../../../constants/urls'
import { useApiDispatch } from '../../../hooks/api/useApiDispatch'
import { login } from '../../../store/auth/actions'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ReactComponent as ShowPassword } from '../../../assets/svg/showPasswordIcon.svg'
import { ReactComponent as HidePassword } from '../../../assets/svg/hidePasswordIcon.svg'
import { useLoading } from '../../../hooks/api/useLoading'
import { useOnlyUnauthorized } from '../../../hooks/useOnlyUnauthorized'

type Inputs = {
  email: string
  password: string
}
const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email address is required'),
  password: Yup.string().required('Password address is required').min(6, 'Must have at least 6 characters')
})


export const LoginPage: React.FC = () => {
  useOnlyUnauthorized()
  const handleLogin = useApiDispatch(login)
  const loading = useLoading(login)
  const navigate = useNavigate()

  const [isInputValueVisible, setInputValueVisible] = useState<boolean>(false)
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({ resolver: yupResolver(validationSchema) })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleLogin(data)
    .then(({ error }) => {
      if (error) {
        return setInvalidCredentials(true)
      }
      navigate(HOW_IT_WORKS)
    })
  }

  return (
    <div className="flex justify-between pt-150px px-100px">
      <article className="relative max-w-520px w-full">
        <h1 className="signUp__title mb-3">Login to the Operator Portal</h1>
        <p className="text-18/22 text-white mb-12">
          Please enter your email and password to continue
        </p>
        <p className="text-18/22 text-white mb-2">Don't have an account with us?</p>
        <Link to={SIGN_UP} className="text-18/22 text-6EB629 font-semibold cursor-pointer">
          Create an account
        </Link>
      </article>
      <form onSubmit={handleSubmit(onSubmit)} className="relative max-w-520px w-full">
        {invalidCredentials && <div>
          <span className="px-2 bg-C43100 text-18/22 text-white mb-2">
            Invalid Email Address or Password
          </span>
        </div>}
        <div className="relative">
          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
            inputClass={`mb-5`}
            register={register}
            error={errors.email}
            labelClass="text-18/22 text-white opacity-100"
          />
        </div>
        <div className="relative">
          <Input
            name="password"
            placeholder="Password"
            inputClass={`mb-52px`}
            register={register}
            error={errors.password}
            type={isInputValueVisible ? '' : 'password'}
            label="Password"
            labelClass="text-18/22 text-white opacity-100"
          />
          {isInputValueVisible ? (
            <ShowPassword
              onClick={() => setInputValueVisible(false)}
              className={`absolute right-6 cursor-pointer top-10px`}
            />
          ) : (
            <HidePassword
              onClick={() => setInputValueVisible(true)}
              className={`absolute right-6 cursor-pointer top-2.5`}
            />
          )}
        </div>
        <div className="flex justify-end items-center">
          <Link to={RESET_PASSWORD} className="text-6EB629 cursor-pointer mr-12">
            Forgot your password?
          </Link>
          <CustomButton
            className="py-4 px-68px text-20/24 font-bold text-white bg-52A91A"
            text="Login"
            type="submit"
            loading={loading}
          />
        </div>
      </form>
    </div>
  )
}
