import { Notification } from '../../Common/Notification'
import React, { memo } from 'react'
import {
  createOffer, deleteFeedOffer,
  deleteGridOffer,
  deleteOffer,
  editOffer,
  publishOfferToFeed,
  publishOfferToGrid
} from '../../../store/offer/actions'
import { createEvent, deleteEvent, updateEvent } from '../../../store/event/actions'
import {
  deletePhotos, editTeammate, removeTeammate,
  saveTeammate,
  saveTournaments,
  updateCourse,
  updateHistory,
  updatePhotos
} from '../../../store/course/actions'
import { useSuccessRequests } from '../../../hooks/api/useSuccessRequests'
import Checkmark from '../../../assets/svg/golfAppCheckMark.svg'

const handledThunks = [
  createOffer,
  editOffer,
  createEvent,
  updateEvent,
  deleteEvent,
  deleteOffer,
  deleteGridOffer,
  publishOfferToFeed,
  publishOfferToGrid,
  deleteFeedOffer,
  updateCourse,
  updatePhotos,
  deletePhotos,
  updateHistory,
  saveTournaments,
  saveTeammate,
  editTeammate,
  removeTeammate
]

export const ApiNotifications: React.FC = memo(() => {
  const haveSuccessfulRequests = useSuccessRequests(handledThunks)
  return (
    <>
      {haveSuccessfulRequests &&
        <Notification>
          <div className="flex items-center">
            <figure
              className="w-5 h-5 flex justify-center items-center border border-solid border-52A91A rounded-full mr-3">
              <img src={Checkmark} alt="" className="h-3 w-3" />
            </figure>
            <span className="leading-4 mr-24">Saved!</span>
          </div>

        </Notification>
      }
    </>

  )
})