import React, { FormEvent } from 'react'

import { CustomButton } from './CustomButton'
import { Input } from './Input'

import { ReactComponent as ShowPassword } from '../../assets/svg/showPasswordIcon.svg'
import { ReactComponent as HidePassword } from '../../assets/svg/hidePasswordIcon.svg'

type PropsType = {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isInputValueVisible: boolean
  setInputValueVisible: (args: boolean) => void
  isPasswordValid: boolean
  inputClass: string
  title: string
  belowInputText?: string
  belowTitleText?: string
  inputLabel?: string
  name: string
  loading?: boolean
}

export const PasswordComponent: React.FC<PropsType> =
  ({
     handleSubmit,
     handleInputChange,
     isInputValueVisible,
     setInputValueVisible,
     isPasswordValid,
     inputClass,
     title,
     belowInputText,
     belowTitleText,
     inputLabel,
     name,
     loading
   }) => {
    return (
      <form onSubmit={handleSubmit} className="relative pt-120px pl-140px pr-100px">
        <h1 className={`signUp__component_title text-4A4A4A ${belowTitleText ? 'mb-4' : 'mb-6'}`}>
          {title}
        </h1>
        {belowTitleText && <p className="mb-4 text-18/22 text-4A4A4A mb-8">{belowTitleText}</p>}
        <div className="relative">
          <Input
            onChange={handleInputChange}
            inputClass={`pr-14 ${inputClass}`}
            label={inputLabel}
            type={isInputValueVisible ? '' : 'password'}
            name={name}
          />
          {isInputValueVisible ? (
            <ShowPassword
              onClick={() => setInputValueVisible(false)}
              className={`absolute right-6 cursor-pointer ${inputLabel ? 'top-46px' : 'top-18px'}`}
            />
          ) : (
            <HidePassword
              onClick={() => setInputValueVisible(true)}
              className={`absolute right-6 cursor-pointer ${inputLabel ? 'top-44px' : 'top-4'}`}
            />
          )}
        </div>
        {belowInputText && (
          <p className="text-4A4A4A text-18/22 opacity-50 pl-1 mb-4">{belowInputText}</p>
        )}
        <div className={`flex justify-end ${!belowInputText && 'mt-8'}`}>
          <CustomButton
            className={`py-4 px-68px text-20/24 font-bold text-white ${
              isPasswordValid ? 'bg-52A91A' : 'bg-52A91A/30'
            }`}
            text="Submit"
            disabled={!isPasswordValid}
            type="submit"
            loading={loading}
          />
        </div>
      </form>
    )
  }
