export const returnColumnStyle = (id: string, columnData: string) => {
  let classes = ''
  if (id === 'title') {
    classes += 'text-black'
  } else if (id === 'status') {
    if (columnData === 'Active') {
      classes += ' text-52A91A font-semibold'
    }
    if (columnData === 'Inactive') {
      classes += ' text-333333 opacity-60 font-semibold'
    }
    if (columnData === 'Pending') {
      classes += ' text-FFA800 font-semibold'
    }
    if (columnData === 'Suspended') {
      classes += ' text-C43100 font-semibold'
    }
    if (columnData === 'Unpublished') {
      classes += ' text-C43100'
    }
  } else {
    classes += ' text-333333'
  }

  return classes
}

export const getTeammatesColumnStyles = (id: string, data: string) => {
  let classes = ''

  if (id === 'status') {
    classes += ' border border-solid rounded px-[7px] py-[5px]'
    if (data === 'Active') {
      classes += ' text-52A91A border-52A91A'
    }
    if (data === 'Awaiting Validation') {
      classes += ' text-FFA800 border-FFA800'
    }
  }
  return classes
}