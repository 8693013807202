import React, { useEffect, useState } from 'react'
import { CustomDropdown } from '../../../Common/CustomDropdown'
import { CustomButton } from '../../../Common/CustomButton'
import Dots from '../../../../assets/svg/dots.svg'

type PropTypes = {
  onEdit: () => void
  onRemove: () => void
  onUnpublish: () => void,
  onOpen: () => void
  onClose: () => void
  unpublishAvailable?: boolean
}

export const ActionButton: React.FC<PropTypes> = (
  { onEdit, onRemove, onOpen, onClose, onUnpublish, unpublishAvailable = true }
) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const onCallback = (method: () => void) => {
    setOpen(false)
    method()
  }

  useEffect(() => {
    if (isOpen) {
      return onOpen()
    }
  }, [isOpen])

  return (
    <CustomDropdown
      isOpen={isOpen}
      setOpen={setOpen}
      onClose={onClose}
      classNames={{ bodyContainer: 'w-[109px] left-[-109px] cursor-default border border-solid border-[#E8E9E9] rounded' }}
    >
      {{
        header: <CustomButton className="w-5 flex justify-center" icon={Dots} />,
        body:
          <div className="flex flex-col px-[3px]">
            <div className="w-full flex justify-center py-1.5">
              <CustomButton className="text-12/14 text-000000 opacity-80" text="Edit"
                            onClick={() => onCallback(onEdit)} />
            </div>
            {unpublishAvailable && (
              <div className="w-full flex justify-center py-1.5">
                <CustomButton
                  className="text-12/14 text-52A91A opacity-80  "
                  text="Unpublish"
                  onClick={() => onCallback(onUnpublish)}
                />
              </div>
            )}
            <div className="w-full border-t border-solid border-[#E8E9E9] flex justify-center py-1.5">
              <CustomButton
                className="text-12/14 text-C43100 font-sfui"
                text="Delete"
                onClick={() => onCallback(onRemove)}
              />
            </div>

          </div>
      }}
    </CustomDropdown>
  )
}