export enum SignUpProgress {
  SignUpMain = 'SignUpMain',
  SignUpMobile = 'SignUpMobile',
  SignUpMobileText = 'SignUpMobileText',
  SignUpMobileCode = 'SignUpMobileCode',
  SignUpCreatePassword = 'SignUpCreatePassword',
  SignUpValidationQuestions = 'SignUpValidationQuestions',
  SignUpLogoPhoto = 'SignUpLogoPhoto',
  SignUpCongrats = 'SignUpCongrats'
}
