import React from 'react'

import { BuyItNowButton } from './BuyItNowButton'

import { InputDoubleCalendar } from '../Components/InputDoubleCalendar'
import { useForm } from 'react-hook-form'
import { CustomCheckbox } from '../../Common/CustomCheckbox'
import { GolfInput } from '../Components/GolfInput'
import { CustomButton } from '../../Common/CustomButton'
import { useOnlySpecificType } from '../../../hooks/useOnlySpecificType'

const disabled = true

export const BinPage: React.FC = () => {
  useOnlySpecificType('private')

  const { register, control } = useForm<any>()

  return (
    <section className="golfApp__section_100vh px-31px pt-18px relative">
      <div className="coming-soon-container">
        <p className="text-52A91A text-200/240 opacity-0.15">
          COMING SOON
        </p>
      </div>
      <div className="relative h-10">
        <BuyItNowButton />
        <h1 className="w-full text-center font-bold text-25/30 text-333333">Buy It Now</h1>
      </div>
      <p className="text-333333 text-16/16 mt-9 pr-24">
        To let potential members and others know that your club accepts non-member-sponsored outside play, fill out the
        form below. The information
        will be shown on the GolfBevy app with a special “Buy It Now” icon when users view your club listing.
      </p>
      <form className="pl-75px pr-24 mt-12">
        <InputDoubleCalendar
          control={control}
          name="publishDate"
          firstName="start"
          secondName="end"
          firstDateLabel="Publish Date Start"
          secondDateLabel="Publish Date End"
          required={true}
          position="top"
          disabled={disabled}
        />
        <BlockLabel
          title="What Are You Offering?*"
          description="At least one of these need to be filled out"
          containerClass="mb-2 mt-5"
        />
        <div className="flex">
          <OfferingBlock title="Play With Club Ambassador(s)" />
          <div className="w-1px bg-979797 h-151px mt-6 mx-8" />
          <OfferingBlock title="Play On Their Own" />
        </div>
        <GolfersCount />
        <div className="max-w-550px">
          <div className="w-full mt-3.5">
            <GolfInput
              inputClass="w-full"
              name="dates"
              labelTextClass="text-333333 opacity-50 text-11/13"
              labelTextOnNewLine
              label="Buy It Now Dates"
              labelText="Specify any restrictions on when rounds can be played, including months, days of the week, and times of day."
              disabled={disabled}
            />
          </div>
          <div className="w-full mt-5">
            <GolfInput
              inputClass="w-full"
              name="details"
              labelTextClass="text-333333 opacity-50 text-11/13"
              labelTextOnNewLine
              label="Buy It Now Details"
              labelText="Specify if offer includes, with any restrictions, use of locker room, practice areas, and any dining facilities. Also if cart is included and if walking is permitted"
              disabled={disabled}
            />
          </div>
          <BlockLabel
            title="Club Contact*"
            description="Who should golfer contact to set up round(s)?"
            containerClass="mt-5"
          />
          <div className="w-full flex justify-between mt-2">
            <div className="max-w-255px w-full">
              <GolfInput
                label="First Name*"
                name="first_name"
                htmlFor="first_name"
                register={register}
                disabled={disabled}
              />
            </div>
            <div className="max-w-255px w-full">
              <GolfInput
                label="Last Name*"
                name="last_name"
                htmlFor="last_name"
                register={register}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="w-full mt-6">
            <GolfInput
              label="Email Address"
              name="email"
              htmlFor="email"
              register={register}
              disabled={disabled}
            />
          </div>
          <div className="w-full mt-5">
            <GolfInput
              label="Title"
              name="title"
              htmlFor="title"
              register={register}
              disabled={disabled}
            />
          </div>
          <div className="w-full flex justify-end mt-10">
            <CustomButton type="submit" className="main-button h-10 w-176px" text="Publish" disabled={disabled} />
          </div>
        </div>
      </form>
    </section>
  )
}

const BlockLabel: React.FC<{ title: string, description?: string, containerClass?: string }> =
  ({ title, description, containerClass }) => {
    return (
      <div className={`h-30px flex items-center ${containerClass ? containerClass : ''}`}>
        <label
          className="text-15/18 text-333333 font-bold cursor-pointer text-333333">
          {title}
        </label>
        {description && <span className="text-333333 opacity-50 text-11/13 ml-2">
          {description}
        </span>}
      </div>
    )
  }

const OfferingBlock: React.FC<{ title: string }> = ({ title }) => {

  const offers = [
    { text: 'One Round', name: 'one_round' },
    { text: 'Two-Round Pack', name: 'two_round' },
    { text: 'Three-Round Pack', name: 'three_round' }
  ]

  return (
    <div>
      <BlockLabel title={title} description="Please include all applicable taxes and fees" containerClass="mb-2" />
      <div className="pl-18px">
        {offers.map((offer, index) => <Offer {...offer} key={index} />)}
      </div>
    </div>
  )
}

const Offer: React.FC<{ text: string, name: string }> = ({ text, name }) => {
  return (
    <div className="flex mb-2.5">
      <CustomCheckbox name={name} checkMarkColor="green" text={text} textClass="w-133px ml-3"
                      className="mr-5" />
      <DollarInput disabled={disabled} />
    </div>
  )
}

const DollarInput: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  return (
    <div className={`golfApp__formELements h-10 w-full max-w-239px ${disabled ? 'disabled' : ''}`}>
      <span className="h-full text-18/22 mx-3">$</span>
      <input type="text" className="h-full" disabled={disabled} />
    </div>
  )
}

const GolfersCount: React.FC = () => {
  const counts = [
    { text: 'One golfer', name: 'one_golfer' },
    { text: 'Three golfers', name: 'three_golfer' },
    { text: 'Two golfers', name: 'two_golfer' },
    { text: 'Foursome', name: 'four_golfer' }
  ]
  return (
    <>
      <BlockLabel
        title="How many golfers can participate?*"
        containerClass="mt-5"
      />
      <div className="flex flex-wrap max-w-390px">
        {counts.map((count, index) =>
          (<CustomCheckbox checkMarkColor="green" textClass="w-133px ml-3"
                           className="mt-4 mr-7" {...count} key={index} />)
        )}
      </div>
    </>
  )
}
