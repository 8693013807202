import React from 'react'
import { Link } from 'react-router-dom'

import { CustomButton } from '../Common/CustomButton'
import { ReactComponent as HeaderLogo } from '../../assets/svg/headerLogo.svg'
import { LOGIN, SIGN_UP } from '../../constants/urls'

type PropsType = {
  isLoginLayout: boolean
}

export const Header: React.FC<PropsType> = ({ isLoginLayout }) => {
  return (
    <header className="width__container">
      <div className="flex justify-between items-center px-60px py-6">
        <a href="https://golfbevy.com">
          <HeaderLogo />
        </a>
        <div className="text-right">
          <Link to={isLoginLayout ? SIGN_UP : LOGIN}>
            <CustomButton
              className="bg-52A91A text-white text-12/14 font-bold px-5 py-1.5"
              text={isLoginLayout ? 'Create an account' : 'Login'}
            />
          </Link>
        </div>
      </div>
    </header>
  )
}
