import React, { memo } from 'react'
import { UserCourse } from '../../../../../../models/UserCourse'

type PropTypes = {
  type?: string,
  course?: UserCourse,
}

export const ClubInfo: React.FC<PropTypes> = memo(({ type, course }) => {
  return (
    <div className="px-2 py-1 flex justify-between border border-solid border-F2F2F2">
      <div className="flex">
        <img src={course?.logo} className="w-5" alt="" />
        <div className="ml-1">
          <div className="text-8/10 mb-0.5">{course?.title}</div>
          <div className="text-7/8 text-9C9C9C">Ft. Lauderdale, FL</div>
        </div>
      </div>
      <div className="flex flex-col justify-start pt-0.5">
        {type && <span className="text-1A8805 text-6/7">{type}</span>}
      </div>
    </div>
  )
})