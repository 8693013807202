import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { HOW_IT_WORKS } from '../constants/urls'
import { useOutletContext } from '../components/Common/CustomContext'

export const useOnlySpecificType = (type: 'private' | 'public') => {
  const navigate = useNavigate()
  const { course } = useOutletContext()

  useEffect(() => {
    if (course) {
      const isPrivate = course.is_private
      if (isPrivate && type === 'public') {
        return navigate(HOW_IT_WORKS)
      }

      if (!isPrivate && type === 'private') {
        return navigate(HOW_IT_WORKS)
      }
    }
  }, [course])
}