import React from 'react'

import { SignUpProgress } from '../../../constants/pagesProgress'
import { SignUpText } from './TextComponents/SignUpText'
import { MobileCodeText } from './TextComponents/MobileCodeText'
import { CreatePasswordText } from './TextComponents/CreatePasswordText'

type PropsType = {
  progress: string
  first_name: string
  email: string
}

export const SignUpTextContainer: React.FC<PropsType> = ({ progress, first_name, email }) => {
  switch (progress) {
    case SignUpProgress.SignUpMain: {
      return <SignUpText />
    }
    case SignUpProgress.SignUpMobile: {
      return <SignUpText />
    }
    case SignUpProgress.SignUpMobileText: {
      return <SignUpText />
    }
    case SignUpProgress.SignUpValidationQuestions: {
      return <SignUpText />
    }
    case SignUpProgress.SignUpMobileCode: {
      return <MobileCodeText firstName={first_name} email={email} />
    }
    case SignUpProgress.SignUpCreatePassword: {
      return <CreatePasswordText firstName={first_name} />
    }
  }
  return <div>Sign up container</div>
}
