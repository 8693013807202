import { UseFormSetError } from 'react-hook-form'

export const handleApiErrors = (setError: UseFormSetError<any>, payload: any) => {
  if (payload && typeof payload === 'object') {
    Object.entries(payload)
    .forEach((keyValue) => {
      if (typeof keyValue[1] === 'string') {
        keyValue[1] = [keyValue[1]]
      }
      // @ts-ignore
      setError(keyValue[0], { message: keyValue[1].join(',') })
    })
  }
}