import { Event } from '../models/Event'
import { fileListToApiImage } from './image'
import { toApiDateTime, toFormDateTime } from './date'
import { EventStatus } from '../models/EventStatus'
import { EventType } from '../models/EventType'

export const fromEventDto = (eventDto: any): Event => {
  const { name, date_start, date_end, date_publish_start, date_publish_end, image, status } = eventDto
  return {
    ...eventDto,
    title: name,
    eventDate: {
      start: toFormDateTime(date_start),
      end: toFormDateTime(date_end)
    },
    publishDate: {
      start: toFormDateTime(date_publish_start),
      end: toFormDateTime(date_publish_end)
    },
    image: {
      url: image,
      name: image?.split('/').reverse()[0]
    },
    status: eventStatusToName(status),
    suspended: statusToSuspended(status)
  }
}

export const fromEventDtos = (eventDtos: Event[]): Event[] => {
  return eventDtos.map(fromEventDto)
}

export const toEventDto = async (eventFormObject: Event): Promise<any> => {
  const image = await fileListToApiImage(eventFormObject.image)

  return {
    ...eventFormObject,
    date_start: toApiDateTime(eventFormObject.eventDate.start),
    date_end: toApiDateTime(eventFormObject.eventDate.end),
    date_publish_start: toApiDateTime(eventFormObject.publishDate.start),
    date_publish_end: toApiDateTime(eventFormObject.publishDate.end),
    image: image?.file_name ? image : undefined
  }
}

export const eventTypeToName = (type: EventType): string => {
  switch (type) {
    case EventType.NEWS:
      return 'News'
    case EventType.TOURNAMENT:
      return 'Tournament'
    case EventType.EVENT:
      return 'Event'
  }
}

const eventStatusToName = (status: EventStatus): string => {
  switch (status) {
    case EventStatus.ACTIVE:
      return 'Active'
    case EventStatus.INACTIVE:
      return 'Inactive'
    case EventStatus.PENDING:
      return 'Pending'
    case EventStatus.SUSPENDED:
      return 'Suspended'
  }
}

const statusToSuspended = (status: EventStatus): boolean => {
  return status === EventStatus.SUSPENDED
}

export const getEventStatus = (event: Event): EventStatus => {
  const now = (new Date()).getTime()
  const oneDay = 24 * 60 * 60
  const publishStart = (new Date(event.publishDate.start)).getTime()
  const publishEnd = (new Date(event.publishDate.end)).getTime()

  if (publishStart > now) {
    return EventStatus.PENDING
  } else {
    if (now < publishEnd + oneDay) {
      return EventStatus.ACTIVE
    } else {
      return EventStatus.INACTIVE
    }
  }
}