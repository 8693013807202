import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { CustomButton } from '../../Common/CustomButton'

import Mobile from '../../../assets/img/CongratsMobile.svg'
import SignUpBg from '../../../assets/img/SignUpBg.png'
import { HOW_IT_WORKS } from '../../../constants/urls'
import { ReactComponent as Cup } from '../../../assets/svg/cup.svg'
import { UserType } from '../../../store/user/userSlice'
import { RelatedArchitect } from '../../../models/RelatedArchitect'
import { User } from "../../../models/User";

export const Congratulations: React.FC<{ user: UserType | User }> = ({ user }) => {
  const navigate = useNavigate()
  const course = user?.course || {}

  const handleSubmit = () => {
    navigate(HOW_IT_WORKS)
  }

  const type = course.is_private ? 'PR' : 'PU'
  const picture = course.picture || SignUpBg
  const architectName = course.related_architects?.filter(({ work_type }: RelatedArchitect) => work_type === 'Design')[0]?.name

  return (
    <section className="relative pt-9">
      <span className="absolute bottom-0 left-11 text-6EB629 cursor-pointer text-20/24 font-medium">
        <Link to="/login">{'< '}Back</Link>
      </span>
      <div className={` ${user.is_approved ? 'pl-72 max-w-800px' : 'pl-60 max-w-[900px]'} w-full flex justify-between box-content`}>
        {user.is_approved && (
          <article className="max-w-480px w-full text-center pt-70px">
            <h1 className="congrats__title">You're set up!</h1>
            <h1 className="congrats__title mb-26px">Now promote your course</h1>
            <CustomButton
              onClick={handleSubmit}
              className={`py-4 px-5 text-20/24 font-bold text-white bg-52A91A mb-25px`}
              text="Take Me To My Portal"
            />

            <p className="text-9C9C9C">
              Need help?
              <a className="text-6EB629" href="mailto:headpro@golfbevy.com">
                {' '}
                headpro@golfbevy.co
              </a>
            </p>
          </article>
        )}
        {!user.is_approved && (
          <article className="w-full text-center flex flex-col items-center">
            <h1 className="font-medium text-[36px] leading-[42px] text-center text-4A4A4A pt-16">
              You're almost done! <br/>
              As soon as we validate your email, <br/>you can promote your course.
            </h1>
            <p className="text-4A4A4A opacity-80 text-18/22 mt-4">
              In the meantime, see all you can do to promote <br/> your course with GolfBevy
            </p>
            <CustomButton
              onClick={handleSubmit}
              className={`py-4 px-5 text-20/24 font-bold text-white bg-52A91A mt-8 w-[230px] mb-[25px]`}
              text="What You Can Do"
            />
            <p className="text-9C9C9C">
              Need help?
              <a className="text-6EB629" href="mailto:headpro@golfbevy.com">
                {' '}
                headpro@golfbevy.co
              </a>
            </p>
          </article>
        )}
        <div>
          <Preview
            name={course.title}
            address={course.msa_name}
            architectYear={course.completed}
            architect={architectName}
            type={type}
            logo={course.logo}
            picture={picture}
          />
        </div>
      </div>
    </section>
  )
}

type PreviewPropTypes = {
  name: string
  address: string
  architectYear: number
  architect: string
  type: string
  logo: string
  picture: string
}

const Preview: React.FC<PreviewPropTypes> =
  ({
     name,
     address,
     architect,
     architectYear,
     type,
     logo,
     picture
   }) => {
    const isPR = type === 'PR'
    return (
      <div
        style={{
          backgroundImage: `url(${Mobile})`,
          width: '228.06px',
          height: '464.89px',
          paddingTop: '93px',
          paddingBottom: '160px',
          paddingLeft: '10px',
          paddingRight: '10px'
        }}
      >
        <div className="w-full h-fit flex justify-center pt-3">
          <div style={{
            width: '190.42px',
            height: '185px',
            boxShadow: '0px 2.33481px 2.33481px rgba(0, 0, 0, 0.25)'
          }}>
            <div className="w-full rounded-t" style={{
              height: '119.71px',
              backgroundImage: `url(${picture})`,
              backgroundSize: 'cover'
            }}>
              <div className="flex justify-end p-1.5">
                {isPR &&
                  <span
                    className="text-6/6 p-0.5 pt-3px rounded-2.5px bg-white max-w-45px text-center">SEE IF YOU CAN GET ON</span>}
              </div>
            </div>
            <div className="w-full flex justify-between p-1.5" style={{
              height: '66px',
              border: '0.583704px solid rgba(0, 0, 0, 0.3)',
              borderTop: 'initial',
            }}>
              <div>
                <div className="flex">
                  <div
                    style={{
                      backgroundImage: `url(${logo})`,
                      width: '27.57px',
                      height: '31.33px',
                      backgroundSize: 'cover'
                    }}
                  >
                  </div>
                  <div className="ml-1.5 max-w-125px">
                    <p className="truncate text-9/12">{name}</p>
                    <p className="truncate text-8/10 opacity-50">{address}</p>
                  </div>
                </div>
                <div className="h-14px">
                  {architectYear && <Tag>{architectYear}</Tag>}
                  {architect && <Tag>{architect}</Tag>}
                  {type && <Tag>{type}</Tag>}
                </div>
              </div>
              <div>
                <Cup width="12" height="12" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

const Tag: React.FC = ({ children }) => {
  return (
    <span
      className="text-7/8 opacity-50 border border-979797 border-solid border-0.5 p-0.5 mr-2 last:mr-0 rounded-2.5px">{children}</span>
  )
}