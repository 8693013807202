import React, { memo } from 'react'
import { TableAction } from '../../../models/TableAction'
import { TableConfig } from '../../../models/TableConfig'

type PropsType = {
  tableData: {
    id: string
  }[]
  config: TableConfig[]
  onRowClick?: (tableRow: any) => void
  actions?: TableAction[]
  selectedItemId?: number | string
  rowsClassNames: string
  rowColors: string[]
  bodyClassNames: string
  getColumnClasses: (id: string, name: string) => string
}

export const SeparatedTableBody: React.FC<PropsType> = memo((
    {
      tableData,
      config,
      onRowClick,
      actions,
      selectedItemId,
      rowsClassNames,
      rowColors,
      bodyClassNames,
      getColumnClasses
    }) => {
    const onClick = (tableRow: any) => {
      onRowClick && onRowClick(tableRow)
    }

    const getColor = (index: number) => {
      return rowColors[index % rowColors.length]
    }

    return (
      <div className={`${bodyClassNames}`}>
        {tableData.map((tableRow: any, index) => {
          return <Row
            key={index}
            row={tableRow}
            config={config}
            onClick={onClick}
            actions={actions}
            selected={tableRow.id === selectedItemId}
            getColumnClasses={getColumnClasses}
            classNames={rowsClassNames}
            color={getColor(index)}
          />
        })}
      </div>
    )
  }
)

type RowPropTypes = {
  row: any
  config: TableConfig[]
  onClick: (tableRow: any) => void
  actions?: TableAction[]
  selected: boolean
  classNames: string
  color: string
  getColumnClasses: (id: string, name: string) => string
}

const Row: React.FC<RowPropTypes> = (
  {
    onClick,
    row,
    config,
    actions,
    selected,
    classNames,
    color,
    getColumnClasses
  }) => {
  return (
    <div
      className={`flex justify-between bg-${color} ${classNames} ${
        selected ? 'selected-row' : ''
      }`}
      onClick={() => onClick(row)}
    >
      {config.map((col: TableConfig, index: number) => (
        <Column col={col} key={index} row={row} getClasses={getColumnClasses} />
      ))}
      <div className="pr-4 flex items-center">
        {actions &&
          actions.map(({ component, className }, index) => {
            return (
              <div className={`${className} flex items-center`} key={index}>
                {component(row)}
              </div>
            )
          })}
      </div>
    </div>
  )
}

type ColumnPropTypes = {
  row: any
  col: TableConfig
  getClasses: (id: string, name: string) => string
}

const Column: React.FC<ColumnPropTypes> = ({ row, col, getClasses }) => {
  const { id, width, getData, classNames } = col

  const value = getData ? getData(row) : row[id]
  return (
    <div
      className={`first:pl-5 text-14/16 py-3.5 w-full ${width} ${classNames?.all} ${classNames?.data}`}
    >
      <span className={`${getClasses(id, value)}`}>{value}</span>
    </div>
  )
}
