import React from 'react'
import { ConfirmDialog } from '../../../Common/ConfirmDialog'

type PropTypes = {
  opened: boolean
  onReject: () => void
  onSubmit: () => void
  title: string
  loading?: boolean
}

export const ConfirmUnpublishingDialog: React.FC<PropTypes> = (
  {
    opened,
    onReject,
    onSubmit,
    title,
    loading = false
  }) => {
  return (
    <ConfirmDialog
      opened={opened}
      title={title}
      description="You can re-publish it at a later date."
      reject={{ text: 'Cancel', className: '', onClick: onReject }}
      submit={{ text: 'Unpublish', className: 'text-C43100 font-medium font-sfui', onClick: onSubmit, loading }}
    />
  )
}
