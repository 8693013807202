import React from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'

type PropsType = {
  text: string
  className: string
  labelText?: string
  name: string
  isError?: FieldError
  register: UseFormRegister<any>
  disabled?: boolean
  maxLength?: number
  labelClassName?: string,
}

export const GolfTextarea: React.FC<PropsType> = (
  {
    text,
    className,
    labelText,
    name,
    isError,
    register,
    disabled = false,
    maxLength,
    labelClassName = 'inline-block ml-2 text-333333 opacity-50 text-xs'
  }
) => {
  return (
    <>
        <span
          className={`inline-block mb-1 text-15/18 font-bold ${
            isError ? 'text-C43100' : 'text-333333'
          }`}
        >
          {text}
        </span>
      {labelText && !isError && (
        <span className={labelClassName}>{labelText}</span>
      )}
      {isError &&
        <span className={`text-C43100 text-12/14 ${text.length > 0 ? 'ml-3' : ''}`}>{isError.message}</span>}
      <textarea
        className={`p-2.5 resize-none ${className} ${
          isError ? 'golfApp__formELements_error' : 'golfApp__formELements'
        }`}
        {...register(name, { required: true })}
        disabled={disabled}
        maxLength={maxLength}
      />
    </>
  )
}
