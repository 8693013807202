import React from 'react'


type PropTypes = {
  onImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  inputName: string
  image: string
  className?: string
  defaultComponent?: JSX.Element
  label: string
  disabled?: boolean
}

export const UploadedPicture: React.FC<PropTypes> = (
  {
    onImageChange,
    inputName,
    image,
    className,
    defaultComponent,
    label
  }) => {
  return (
    <div>
      <div className={`cursor-pointer bg-white shadow-md ${className ? className : ''}`}>
        <label className={`w-full h-full`} htmlFor={inputName}>
          <input
            onChange={onImageChange}
            className="hidden"
            type="file"
            id={inputName}
            accept="image/jpeg, image/png"
            name={inputName}
          />
          {image && <figure className="w-full h-full">
            <img src={image} alt="" className="w-full h-full" />
          </figure>
          }
          {!image &&
            <div className="w-full h-full flex justify-center items-center">
              {defaultComponent}
            </div>
          }
        </label>
      </div>
      <p className="mt-2 text-14/16 text-979797">
        {label}
      </p>
    </div>


  )
}