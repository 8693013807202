import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm, UseFormRegister } from 'react-hook-form'

import { CustomButton } from '../../Common/CustomButton'
import { GolfInput } from '../Components/GolfInput'
import { GolfSelect } from '../Components/GolfSelect'
import { Architect, CourseHistory } from '../../../models/CourseHistory'
import { useSelectOptions } from '../../../hooks/useSelectOptions'
import { InputLabel } from '../Components/small/InputLabel'
import { InputDescription } from '../Components/small/InputDescription'
import { GolfTextarea } from '../Components/GolfTextarea'
import { UsePaginatedApi } from '../../../hooks/api/usePaginatedApi'
import Plus from '../../../assets/svg/plus-green.svg'
import Minus from '../../../assets/svg/minus-green.svg'
import { UseFieldArrayAppend } from 'react-hook-form/dist/types/fieldArray'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { handleApiErrors } from '../../../helpers/api'
import { useSaveButtonConfig } from '../../../hooks/useSaveButtonConfig'

type Inputs = {
  design_architects: string[]
  redesign_architects: string[]
  design_year: string
  redesign_year: string
  about: string
}

const defaultArchitects: string[] = ['empty-0', 'empty-1']

const getArchitectLength = (architects: any[] | undefined) => {
  const minLength = 2
  const length = architects ? architects.length + 1 : minLength
  return length > minLength ? length : minLength
}

const toFormObject = (history?: any): Inputs => {
  let design_architects = history?.design_architects.map(({ id }: Architect) => `${id}`) || defaultArchitects
  design_architects = [...design_architects, ...defaultArchitects].slice(0, getArchitectLength(history?.design_architects))

  let redesign_architects = history?.redesign_architects.map(({ id }: Architect) => `${id}`) || defaultArchitects
  redesign_architects = [...redesign_architects, ...defaultArchitects].slice(0, getArchitectLength(history?.redesign_architects))

  let about = history?.about

  if (!about) {
    about = ''
  }

  about = about
  .replaceAll('<p>', '')
  .replaceAll('</p>', '')
  .replaceAll('<br />', '\n')
  .replaceAll('&ldquo;', '“')
  .replaceAll('&rdquo;', '”')

  return {
    about,
    design_architects,
    redesign_architects,
    design_year: `${history?.design_year || ''}`,
    redesign_year: `${history?.redesign_year || ''}`
  }
}

const fromFormObject = (data: Inputs) => {
  const { design_year, redesign_year, design_architects, redesign_architects } = data

  let about = data.about

  if (about) {
    about = about
    .replaceAll('\n', '<br />')
    .replaceAll('“', '&ldquo;')
    .replaceAll('”', '&rdquo;')

    about = `<p>${about}</p>`
  } else {
    about = ''
  }


  const formattedDesignArchitects = design_architects
  .filter((architect) => !architect.startsWith('empty-'))
  .map(architect => Number(architect))

  const formattedRedesignArchitects = redesign_architects
  .filter((architect) => !architect.startsWith('empty-'))
  .map(architect => Number(architect))

  return {
    about,
    design_year: Number(design_year),
    redesign_year: Number(redesign_year),
    redesign_architects: formattedRedesignArchitects,
    design_architects: formattedDesignArchitects
  }
}

const currentYear = new Date().getFullYear()

const validationSchema = Yup.object().shape({
  design_year: Yup.number().min(1850, 'Must fill in with a valid year').max(currentYear, 'Must fill in with a valid year'),
  redesign_year: Yup.number()
  .min(1850, 'Must fill in with a valid year')
  .max(currentYear, 'Must fill in with a valid year')
  .nullable(true)
  .transform((_, val) => val.length > 0 ? Number(val) : null),
  about: Yup.string()
  .nullable(true)
  .min(50, 'Must have at least 50 characters')
  .max(1000, 'Must have maximum 1000 characters')
  .transform((_, val) => val === '' ? null : val)
})

type PropTypes = {
  history?: CourseHistory
  architectsPagination: UsePaginatedApi
  searchArchitectPagination: UsePaginatedApi
  onSubmit: any
  onArchitectSearch: (search: string) => void
  loading: boolean,
}

export const CourseHistoryForm: React.FC<PropTypes> = (
  {
    history,
    architectsPagination,
    onSubmit,
    onArchitectSearch,
    loading,
    searchArchitectPagination
  }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setError,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => toFormObject(history) || {}, [history]),
    resolver: yupResolver(validationSchema)
  })
  const saveButtonConfig = useSaveButtonConfig(loading)
  const {
    fields: redesignArchitectsFields,
    append: redesignArchitectsAppend,
    remove: redesignArchitectsRemove
  } = useFieldArray<any>({ control, name: 'redesign_architects' })


  const {
    fields: designArchitectsFields,
    append: designArchitectsAppend,
    remove: designArchitectsRemove
  } = useFieldArray<any>({ control, name: 'design_architects' })

  useEffect(() => {
    reset(toFormObject(history))
  }, [history])

  const onFormSubmit: SubmitHandler<Inputs> = (data) => {
    onSubmit(fromFormObject(data))
    .then(({ error, payload }: any) => {
      if (error) {
        handleApiErrors(setError, payload.errors)
      }
    })
  }

  const [
    designArchitectsIds,
    redesignArchitectsIds,
    designYear,
    about
  ] = watch(['design_architects', 'redesign_architects', 'design_year', 'redesign_year', 'about'])

  const requiredFields = [designArchitectsIds, designYear]

  const enabledButton = useMemo(() => {
    return requiredFields
    .map((field) => field && field.length > 0)
    .every((field) => !!field)
  }, requiredFields)

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="max-w-[579px] w-full pt-15px">
      <ArchitectSection
        fields={designArchitectsFields}
        append={designArchitectsAppend}
        selectedIds={designArchitectsIds}
        errors={errors}
        architectName="design_architects"
        architectLabel="Original Architect"
        register={register}
        architectsPagination={architectsPagination}
        yearLabel="Year Opened"
        yearName="design_year"
        history={history}
        onArchitectSearch={onArchitectSearch}
        searchArchitectPagination={searchArchitectPagination}
        remove={designArchitectsRemove}
      />
      <ArchitectSection
        fields={redesignArchitectsFields}
        append={redesignArchitectsAppend}
        selectedIds={redesignArchitectsIds}
        errors={errors}
        architectName="redesign_architects"
        architectLabel="Architect of Most Recent Renovation"
        register={register}
        architectsPagination={architectsPagination}
        yearLabel="Year Renovated"
        yearName="redesign_year"
        history={history}
        onArchitectSearch={onArchitectSearch}
        searchArchitectPagination={searchArchitectPagination}
        remove={redesignArchitectsRemove}
      />
      <div className="w-full">
        <InputLabel text="About My Course" />
        <InputDescription
          text="Tell GolfBevy users what you want them to know about the course—history, design, playability, etc. What you write wil appear, as is, when users find the course through the directory, listed under “Info.”"
        />
        <div className="w-full flex justify-end mt-4 mb-1">
          <InputDescription text="50 Character Min" />
        </div>
        <GolfTextarea
          name="about"
          register={register}
          className="w-full h-[123px]"
          text=""
          maxLength={1000}
          isError={errors.about}
        />
        <div className="w-full flex justify-end mb-3">
          <InputDescription text={`${about?.length || 0}/1000`} />
        </div>
      </div>
      <p className="text-12/14 text-979797 mb-33px">
        If either architect is not on the list, contact {' '}
        <a href="mailto:headpro@golfbevy.com" className="text-52A91A">headpro@golfbevy.com</a>
      </p>
      <div className="">
        <CustomButton
          className={`py-2.5 w-195px main-button`}
          type="submit"
          loading={loading}
          text={saveButtonConfig.text}
          disabled={!enabledButton || saveButtonConfig.disabled}
        />
      </div>
    </form>
  )
}

type ArchitectSectionProps = {
  fields: Record<'id', string>[],
  append: UseFieldArrayAppend<any>,
  remove: any,
  selectedIds: string[] | number[],
  errors: any,
  architectName: string,
  register: UseFormRegister<any>,
  architectsPagination: UsePaginatedApi,
  searchArchitectPagination: UsePaginatedApi,
  architectLabel: string,
  yearName: string,
  yearLabel: string,
  history: any
  onArchitectSearch: (search: string) => void
}

const ArchitectSection: React.FC<ArchitectSectionProps> = (
  {
    fields,
    selectedIds,
    errors,
    architectName,
    register,
    architectsPagination,
    architectLabel,
    append,
    remove,
    yearLabel,
    yearName,
    history,
    onArchitectSearch,
    searchArchitectPagination,
  }
) => {
  const [localHistory, setHistory] = useState<Record<string, any>>()

  useEffect(() => {
    setHistory(history)
  }, [history])

  const yearError = errors[yearName]
  const fullyRemove = (index: number) => {
    // TODO: HACK, need to rewrite more clearly
    if (localHistory && localHistory[architectName]) {
      const updatedHistory = { ...localHistory }
      updatedHistory[architectName] = [...updatedHistory[architectName].slice(0, index), ...updatedHistory[architectName].slice(index+1)]
      setHistory(updatedHistory)
    }
    remove(index)
  }

  return (
    <>
      {yearError && <span className="text-C43100 text-12/14">{yearError.message}</span>}
      <div className="flex justify-between mb-5">
        <div className="max-w-110px w-full mr-8">
          <GolfInput
            name={yearName}
            label={yearLabel}
            htmlFor={yearName}
            isError={!!yearError}
            register={register}
            type="number"
          />
        </div>
        <div className="w-full">
          {fields.map((field, index: any) => {
            return (
              <div className="mb-5 last:mb-0 relative flex" key={index}>
                <div className="max-w-390px w-full mr-8">
                  <ArchitectSelect
                    selectedIds={selectedIds}
                    errors={errors}
                    architectName={architectName}
                    register={register}
                    architectsPagination={architectsPagination}
                    searchArchitectPagination={searchArchitectPagination}
                    architectLabel={architectLabel}
                    history={localHistory}
                    onArchitectSearch={onArchitectSearch}
                    index={index}
                    key={index}
                  />
                </div>
                {index === fields.length - 1 ? (
                  <CustomButton
                    className="w-8 h-8 rounded-[50%!important] bg-[#E8E9E9] flex justify-center items-center mt-[34px]"
                    icon={Plus}
                    onClick={() => append([`empty-${selectedIds.length}`])} />
                ) : (
                  <CustomButton
                    className="w-8 h-8 rounded-[50%!important] bg-[#E8E9E9] flex justify-center items-center mt-[34px]"
                    icon={Minus}
                    onClick={() => fullyRemove(index)} />
                )
                }
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

type ArchitectSelectPropsType = {
  selectedIds: string[] | number[],
  errors: any,
  architectName: string,
  register: UseFormRegister<any>,
  architectsPagination: UsePaginatedApi,
  searchArchitectPagination: UsePaginatedApi,
  architectLabel: string,
  history: any
  onArchitectSearch: (search: string) => void
  index: number
}

const ArchitectSelect: React.FC<ArchitectSelectPropsType> = (
  {
    selectedIds,
    errors,
    architectName,
    register,
    architectsPagination,
    architectLabel,
    onArchitectSearch,
    index,
    history,
    searchArchitectPagination
  }
) => {
  const [search, setSearch] = useState<string>("")


  const useSearchPagination = useMemo(() => {
    return search !== ""
  }, [search])

  const pagination: UsePaginatedApi = useMemo(() => {
    return useSearchPagination ? searchArchitectPagination : architectsPagination
  }, [search, architectsPagination, searchArchitectPagination])

  const architectSelect = useSelectOptions(pagination.data)

  const onSearch = (value: string) => {
    onArchitectSearch(value)
    setSearch(value)
  }

  const selectedArchitectId = selectedIds[index]

  const forcedInputValue = useMemo(() => {
    const selectedArchitect: Record<string,  any> | undefined = architectsPagination.data.find(({id}: any) => String(id) === String(selectedArchitectId))
    const selectedArchitectName = selectedArchitect?.name
    const historyValue = history && history[architectName] && history[architectName][index] && history[architectName][index].get_full_name

    return selectedArchitectName || historyValue
  }, [selectedArchitectId, history])

  return (
    <GolfSelect
      className="text-15/18 h-40px border-979797"
      name={`${architectName}.${index}`}
      text={`${architectLabel} ${index > 0 ? '(if more than one)' : ''}`}
      selectedOption={selectedArchitectId}
      optionsModalClass="h-190px top-20"
      isError={errors[architectName] && errors[architectName][index]}
      register={register}
      searchable
      onSearch={onSearch}
      forcedInputValue={forcedInputValue}
      {...architectSelect}
      infiniteScrollConfig={{
        loading: pagination.loading,
        hasNextPage: pagination.hasNextPage,
        onLoadMore: pagination.loadMore
      }}
    />
    )

}