import { readFile } from './readFile'

export const fileListToImage = (
  list: FileList | any
): { name: string; url: string } | undefined => {
  if (!(list instanceof FileList)) {
    return list
  }
  const image = list[0]

  if (!image) {
    return undefined
  }
  return {
    name: image.name,
    url: URL.createObjectURL(image)
  }
}

export const fileListToApiImage = async (
  list: FileList | any
): Promise<{ file_name: string; data: string } | undefined> => {
  if (!(list instanceof FileList)) {
    return list
  }
  const image = list[0]

  if (!image) {
    return undefined
  }
  return {
    file_name: image.name,
    data: await readFile(image) as string
  }
}

export const imageToApiFormat = async (
  image: unknown | any
): Promise<{ file: { file_name: string; data: string } } | undefined> => {

  if (!image) {
    return undefined
  }
  const imageBase64 = await readFile(image.data) as string
  return {
    file: {
      file_name: image.name,
      data: imageBase64.replace('data:image/jpeg;base64,', '')
    }
  }
}

export const photosToImage = async (
  image: unknown | any
): Promise<{ id: number, name: string; image: string } | undefined> => {

  if (!image) {
    return undefined
  }

  let filename = image.file.substring(image.file.lastIndexOf('/')+1);

  return {
    id: image.id,
    name: filename,
    image: image.file
  }
}

export const convertImageUrlToFile = async (url: string): Promise<File | undefined> => {
  try {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: 'image/jpeg'
    };
    const name = url.split('/').reverse()[0]
    return new File([data], name, metadata);
  } catch (e) {
    console.log(e)
  }

}