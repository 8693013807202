import React, { ChangeEventHandler } from 'react'
import { DayPeriod } from '../../../../../models/DayPeriod'

type PropTypes = {
  onChange: ChangeEventHandler<HTMLInputElement>
  isChecked: boolean
  name: string
  period: DayPeriod
  disabled?: boolean
}

export const DayCheckbox: React.FC<PropTypes> = ({ onChange, isChecked, name, period, disabled = false }) => {
  return (
    <label
      className={`cursor-pointer flex justify-center items-center w-[38px] h-[38px] border border-solid border-black rounded-lg ${isChecked ? 'text-white bg-[#1A8805]' : ''} ${disabled ? 'disabled' : ''}`}
      htmlFor={name}>
      <span className="text-[14px] leading-[19px]">{period.toString().toUpperCase()}</span>
      <input
        onChange={onChange}
        className="border-2 border-solid bolder-979797 hidden"
        id={name}
        type="checkbox"
        name={name}
        checked={isChecked}
        disabled={disabled}
      />
    </label>
  )
}