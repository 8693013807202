import React, { useState, useEffect } from 'react'
import { CourseInternalPage } from '../CourseInternalPage'
import { MyCourseForm } from './MyCourseForm'
import { Course } from '../../../../models/Course'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../store/store'
import { getCourse, getStates, updateCourse, getCities } from '../../../../store/course/actions'
import { usePaginatedApi } from '../../../../hooks/api/usePaginatedApi'
import { useApi } from '../../../../hooks/api/useApi'

export const MyCoursePage: React.FC = () => {
  const [loading, onSubmit] = useApi(updateCourse)
  const [course, setCourse] = useState<Course | undefined>()
  const [stateSearch, setStateSearch] = useState<string>()
  const [citySearch, setCitySearch] = useState<string>()
  const cityPagination = usePaginatedApi(getCities, citySearch)
  const statePagination = usePaginatedApi(getStates, stateSearch)


  const dispatch = useDispatch<AppDispatch>()
  const store = useSelector((state: any) => state)

  useEffect(() => {
    if (store.course.course === undefined) {
      dispatch(getCourse())
    }
  }, [])

  useEffect(() => {
    setCourse(store.course.course)
  }, [store.course.course])


  return (
    <CourseInternalPage title="Contact Information">
      <MyCourseForm
        cityPagination={cityPagination}
        statePagination={statePagination}
        course={course}
        onCitySearch={setCitySearch}
        onStateSearch={setStateSearch}
        onSubmit={onSubmit}
        loading={loading}
      />
    </CourseInternalPage>
  )
}
