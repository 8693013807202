import React, { memo } from 'react'
import { MobileApp } from '../../../Components/MobileApp'
import format from 'date-fns/format'
import { Description } from './Components/Description'
import { Photo } from './Components/Photo'
import { ClubInfo } from './Components/ClubInfo'
import { UserCourse } from '../../../../../models/UserCourse'

type PropTypes = {
  imageUrl?: string
  type?: string
  name?: string
  date?: Date
  description?: string
  course?: UserCourse
}

export const FeedPreview: React.FC<PropTypes> = memo(({ imageUrl, type, name, date, description, course }) => {
  const formattedDate = date && format(date, 'MMMM dd')

  return (
    <>
      <MobileApp size="sm">
        <div className="w-full h-fit bg-white">
          <Photo imageUrl={imageUrl}>
            {imageUrl && <div className="flex justify-between">
              <p className="pr-1 text-11/13 font-bold text-white"
                 style={{ textShadow: '0px 1.77067px 1.77067px rgba(0, 0, 0, 0.6)' }}>
                {name}
              </p>
              <p className="text-center text-11/13 font-bold text-white">
                {formattedDate}
              </p>
            </div>}
          </Photo>
          <ClubInfo type={type} course={course} />
          <div className="p-2 h-14">
            <Description description={description} />
          </div>
        </div>
      </MobileApp>
      <p className="text-center text-333333 opacity-50 text-14/16 mb-5">Feed Preview</p>
    </>
  )
})