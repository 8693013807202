import { createSlice } from '@reduxjs/toolkit'
import { Event } from '../../models/Event'
import { createEvent, deleteEvent, getEvents, updateEvent, updateEventStatus } from './actions'

type EventState = {
  events?: Event[]
}

const initialState: EventState = {
  events: undefined
}

const onUpdateEvent = (state: any, action: any) => {
  const event = action.payload as Event
  const eventIndex = (state.events || []).findIndex(({ id }: Event) => id === event.id)
  state.events = [
    ...(state.events || []).slice(0, eventIndex),
    event,
    ...(state.events || []).slice(eventIndex + 1, (state.events || []).length)
  ]
}

export const eventSlice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(createEvent.fulfilled, (state, action) => {
      const event = action.payload as Event
      state.events = [...state.events || [], event]
    })
    .addCase(updateEvent.fulfilled, onUpdateEvent)
    .addCase(updateEventStatus.fulfilled, onUpdateEvent)
    .addCase(getEvents.fulfilled, (state, action) => {
      state.events = [...action.payload as Event[]]
    })
    .addCase(deleteEvent.fulfilled, (state, action) => {
      const eventId = action.payload
      const eventIndex = (state.events || []).findIndex(({ id }: Event) => id === eventId)
      state.events = [
        ...(state.events || []).slice(0, eventIndex),
        ...(state.events || []).slice(eventIndex + 1, (state.events || []).length)
      ]
    })
  }
})

export const { setEvents } = eventSlice.actions
export default eventSlice.reducer
