import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { getTournaments } from '../../store/course/actions'

export const useTournaments = () => {
  const store = useSelector((state: RootState) => state.course)
  const dispatch = useDispatch()

  const { tournaments } = store

  if (tournaments === undefined) {
    dispatch(getTournaments())
  }

  return tournaments
}