import React, { useState } from 'react'

import { CustomButton } from '../../Common/CustomButton'

import { eventTableConfig } from './configs'
import { Link, useNavigate } from 'react-router-dom'
import { EDIT_EVENT, NEW_EVENT } from '../../../constants/urls'
import { Page } from '../Components/Page'
import { CustomTable } from '../Components/CustomTable'
import DeleteIcon from '../../../assets/svg/delete-small.svg'
import { Event } from '../../../models/Event'
import { ConfirmDeletingDialog } from '../Components/dialogs/ConfirmDeletingDialog'
import { deleteEvent } from '../../../store/event/actions'
import { useEvents } from '../../../hooks/useEvents'
import { useApi } from '../../../hooks/api/useApi'
import { SetupProgress } from '../Components/SetupProgress'

export const EventsPage: React.FC = () => {
  const [openedConfirmDialog, setOpenedConfirmDialog] = useState<boolean>(false)
  const [selectedEventId, setSelectedEventId] = useState<string | number>()
  const events = useEvents()

  const [deleteLoading, deleteEventsApi] = useApi(deleteEvent)
  const navigate = useNavigate()

  const startDeletingEvent = (e: Event | undefined | any, event: Event) => {
    e?.stopPropagation()
    setOpenedConfirmDialog(true)
    setSelectedEventId(event.id)
  }

  const finishDeleting = () => {
    setSelectedEventId(undefined)
    setOpenedConfirmDialog(false)
  }

  const confirmDeleting = () => {
    selectedEventId && deleteEventsApi(selectedEventId).then(finishDeleting).catch((err) => console.log(err))
  }

  const onEventClick = (event: Event) => {
    navigate(EDIT_EVENT.replace(':id', event.id?.toString() || ''))
  }

  const actions = [
    {
      component: (event: Event) => (
        <DeleteEventButton appEvent={event} onClick={startDeletingEvent} />
      ),
      className: 'w-5'
    }
  ]

  return (
    <Page title="My Events" relative>
      <div className="flex justify-end absolute right-[30px] top-5">
        <SetupProgress />
      </div>
      <h2 className="text-16/19 text-333333">Click on Event to edit or reuse it.</h2>
      <div className="flex justify-end mb-5px">
        <CustomButton
          className="mb-1 text-16/19 text-52A91A font-semibold"
          text="+ Add New Event"
          link={NEW_EVENT}
        />
      </div>
      <CustomTable
        config={eventTableConfig}
        data={events || []}
        actions={actions}
        emptyPlaceholderText={<EmptyPlaceholder />}
        onRowClick={onEventClick}
        selectedItemId={selectedEventId}
        sorterable
      />
      <ConfirmDeletingDialog
        title="Are You Sure You Want To Delete This Event?"
        opened={openedConfirmDialog}
        onSubmit={confirmDeleting}
        onReject={finishDeleting}
        loading={deleteLoading}
      />
    </Page>
  )
}

type DeleteEventPropTypes = {
  appEvent: Event
  onClick: (e: Event | undefined, appEvent: Event) => void
}

const DeleteEventButton: React.FC<DeleteEventPropTypes> = ({ appEvent, onClick }) => {
  return (
    <CustomButton
      icon={DeleteIcon}
      className="text-14/16"
      onClick={(event: Event) => onClick(event, appEvent)}
    />
  )
}

const EmptyPlaceholder: React.FC = () => {
  return (
    <div className="text-center">
      <span className="text-4A4A4A text-18/22 text-center opacity-40">
        There are no events here yet.{' '}
      </span>
      <span className="mb-1 text-18/22 text-52A91A font-semibold">
        <Link to={NEW_EVENT}>+ Add New Event</Link>
      </span>
    </div>
  )
}
