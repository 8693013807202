import React from 'react'
import MobileAppImage from '../../../../assets/img/MobileAppSmall.png'

export const MobileAppSmall: React.FC = ({ children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${MobileAppImage})`,
        width: '183px',
        height: '382px',
        paddingTop: '53px',
        paddingBottom: '48px',
        paddingLeft: '10px',
        paddingRight: '10px'
      }}
    >
      <div className="w-full h-full">{children}</div>
    </div>
  )
}
