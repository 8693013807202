import { useEffect, useMemo, useState } from 'react'

export const useScreenSize = (): 'sm' | 'lg' => {
  const [width, setWidth] = useState(window.innerWidth)
  const breakpoint = 640

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  }, [])

  return useMemo(() => {
    return width > breakpoint ? 'lg' : 'sm'
  }, [width])
}