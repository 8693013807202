import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from './usePrevious'

const DEFAULT_CONFIG = { text: 'Save', disabled: false }

export const useSaveButtonConfig = (loading: boolean) => {
  const [config, setConfig] = useState({ text: 'Save', disabled: false })
  const previousLoading = usePrevious(loading, false)

  useEffect(() => {
    if (loading !== previousLoading) {
      if (!loading) {
        setConfig({ text: 'Saved!', disabled: true })
        setTimeout(() => {
          setConfig({ ...DEFAULT_CONFIG })
        }, 2 * 1000)
      }
    }
  }, [loading])

  return useMemo(() => {
    return config
  }, [config])

}