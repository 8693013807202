import React from 'react'
import { Link } from 'react-router-dom'

import { LOGIN } from '../../../../constants/urls'
import { SupportEmailText } from '../../../Common/SupportEmailText'

export const SignUpText: React.FC = () => {
  return (
    <article className="relative px-85px text-white">
      <h3 className="signUp__welcomeText">Welcome to the Operator Portal</h3>
      <h1 className="signUp__title">Create A Free Account</h1>
      <h2 className="signUp__subtitle mb-6">No Credit Card Required</h2>
      <p className="text-18/22 mb-6">
        See in advance when golfers want to play and use our free content Feed to put offers and
        other information in front of them.
      </p>
      <p className="text-18/22 mb-110px">
        Have an account?
        <Link className="text-6EB629 pl-5 cursor-pointer" to={LOGIN}>
          Log in
        </Link>
      </p>
      <p className="inline-block">
          Need help?
          <SupportEmailText />
      </p>
    </article>
  )
}
