import React, { useEffect, useMemo, useState } from 'react'

import { useApi } from '../../../../hooks/api/useApi'
import { deleteFeedOffer, getFeedOffers, unpublishFeedOffer } from '../../../../store/offer/actions'
import { FeedOffer } from '../../../../models/Offer'
import { CustomTable } from '../../Components/CustomTable'
import { ConfirmDeletingDialog } from '../../Components/dialogs/ConfirmDeletingDialog'
import { formatId } from '../../../../helpers/id'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { offerStatusToName, statusToUnpublished } from '../../../../helpers/offer'
import { Link, useNavigate } from 'react-router-dom'
import { EDIT_FEED_OFFER, NEW_OFFER } from '../../../../constants/urls'
import { DefaultTableStyles } from '../../../../models/TableStyles'
import { toFormDateTime } from '../../../../helpers/date'
import { ActionButton } from "../OffersAvailabilityGridPage/ActionButton";
import { ConfirmUnpublishingDialog } from "../../Components/dialogs/ConfirmUnpublishingDialog";

const tableConfig = [
  { name: 'ID#', id: 'id', width: 'max-w-100px', getData: (row: FeedOffer) => formatId(row.id) },
  {
    name: 'Title',
    id: 'title',
    width: 'max-w-480px',
    classNames: { data: 'truncate' },
    getData: (row: FeedOffer) => row['name']
  },
  {
    name: 'Publish Begin',
    id: 'date_publish_start',
    width: 'max-w-135px',
    getData: (row: FeedOffer) => toFormDateTime(row['date_publish_start'], 'MM/DD/YYYY')
  },
  {
    name: 'Publish End',
    id: 'date_publish_end',
    width: 'max-w-135px',
    getData: (row: FeedOffer) => toFormDateTime(row['date_publish_end'], 'MM/DD/YYYY')
  },
  { name: 'Status', id: 'status', width: 'max-w-100px', getData: (row: FeedOffer) => offerStatusToName(row.status) }
]

const tableStyles = {
  ...DefaultTableStyles,
  emptyPlaceholderClasses: 'flex justify-center mb-14 mt-[123px]'
}

export const Table: React.FC = () => {
  const navigate = useNavigate()
  const [selectedOfferId, setSelectedOfferId] = useState<number>()
  const [openedConfirmDialog, setOpenedConfirmDialog] = useState<boolean>(false)
  const [deleteLoading, deleteOfferApi] = useApi(deleteFeedOffer)
  const [offersLoading, loadOffers] = useApi(getFeedOffers)
  const [unpublishing, setUnpublishing] = useState<boolean>(false)
  const [unpublishLoading, unpublishOfferApi] = useApi(unpublishFeedOffer)

  const offers = useSelector((state: RootState) => state.offer.feedOffers)

  const offer = useMemo(() => {
    return offers?.find(({id}: FeedOffer) => id === selectedOfferId)
  }, [offers, selectedOfferId])

  useEffect(() => {
    if (!offers && !offersLoading) {
      loadOffers()
    }
  }, [offers, offersLoading])

  const startDeletingOffer = (offer: FeedOffer) => {
    setSelectedOfferId(offer?.id)
    setOpenedConfirmDialog(true)
  }

  const finishDeleting = () => {
    setSelectedOfferId(undefined)
    setOpenedConfirmDialog(false)
  }

  const confirmDeleting = () => {
    selectedOfferId && deleteOfferApi(selectedOfferId).then(finishDeleting)
  }

  const confirmUnpublishing = () => {
    selectedOfferId && unpublishOfferApi(offer).then(finishUnpublishing)
  }

  const finishUnpublishing = () => {
    setUnpublishing(false)
    setSelectedOfferId(undefined)
  }

  const actions = [
    {
      component: (offer: FeedOffer) => {
        const onEdit = () => {
          navigate(EDIT_FEED_OFFER.replace(':id', String(offer?.id)))
        }

        const unpublished = statusToUnpublished(offer.status)

        return <ActionButton
          onRemove={() => startDeletingOffer(offer)}
          onOpen={() => setSelectedOfferId(offer.id)}
          onClose={() => setSelectedOfferId(undefined)}
          onEdit={onEdit}
          unpublishAvailable={!unpublished}
          onUnpublish={() => setUnpublishing(true)}
        />
      },
      className: 'w-5'
    }
  ]

  return (
    <div className="mt-4">
      <CustomTable
        data={offers || []}
        config={tableConfig}
        actions={actions}
        selectedItemId={selectedOfferId}
        emptyPlaceholderText={<EmptyPlaceholder />}
        styles={tableStyles}
        sorterable
      />
      <ConfirmDeletingDialog
        title="Are You Sure You Want To Delete This Offer?"
        opened={openedConfirmDialog}
        onSubmit={confirmDeleting}
        onReject={finishDeleting}
        loading={deleteLoading}
      />
      <ConfirmUnpublishingDialog
        title="Are You Sure You Want To Unpublish This Offer?"
        opened={unpublishing}
        onSubmit={confirmUnpublishing}
        onReject={finishUnpublishing}
        loading={unpublishLoading}
      />
    </div>
  )
}


const EmptyPlaceholder: React.FC = () => {
  return (
    <div className="text-center">
      <span className="text-4A4A4A text-18/22 text-center opacity-40">
        There are no Offers here yet.{' '}
      </span>
      <span className="mb-1 text-18/22 text-52A91A font-semibold">
        <Link to={NEW_OFFER}>+ Add New Offer</Link>
      </span>
    </div>
  )
}