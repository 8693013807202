import { createAsyncThunk } from '@reduxjs/toolkit'
import request from '../../utils/agent'
import { CourseHistory } from '../../models/CourseHistory'
import { Course } from '../../models/Course'
import { imageToApiFormat, photosToImage } from '../../helpers/image'
import { Teammate } from '../../models/Teammate'
import { fromTeammateDto, fromTeammateDtos } from '../../helpers/teammate'

export const getPhotos = createAsyncThunk(
  'photos/get',
  async (_, { rejectWithValue }) => {

    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/course/photos/'
      })

      return (await Promise.all(response.data.map(photosToImage))).sort((a, b) => a.id - b.id)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }

  }
)

export const updatePhotos = createAsyncThunk(
  'photos/update',
  async (photos: any[], { rejectWithValue }) => {

    const uploadedPhotos = photos.filter(photo => {
      return photo.data
    })

    const processedData = await Promise.all(uploadedPhotos.map(imageToApiFormat))

    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/course/photos/',
        data: processedData
      })

      return (await Promise.all(response.data.map(photosToImage))).sort((a, b) => a.id - b.id)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }

  }
)

export const deletePhotos = createAsyncThunk(
  'photos/delete',
  async (deletedPhotosId: any[], { rejectWithValue }) => {

    try {
      const response = await request({
        method: 'DELETE',
        url: 'operator_portal/course/photos/',
        data: deletedPhotosId
      })

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const updateHistory = createAsyncThunk(
  'history/update',
  async (history: CourseHistory, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'PUT',
        url: 'operator_portal/course/history/',
        data: history
      })

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getHistory = createAsyncThunk(
  'history/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/course/history/'
      })

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getArchitects = createAsyncThunk(
  'architects/get',
  async ({ page, search, pageSize }: { page: number, search: string, pageSize: number }, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'GET',
        url: `operator_portal/course/architects?page=${page}&search=${search || ''}&page_size=${pageSize}`
      })

      const result = response.data.results.map((arch: any) => ({ ...arch, name: arch.get_full_name }))
      return { count: response.data.count, result }
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getCities = createAsyncThunk(
  'cities/get',
  async ({ page, search, pageSize }: { page: number, search: string, pageSize: number }, { rejectWithValue }) => {

    try {
      const response = await request({
        method: 'GET',
        url: `operator_portal/cities?page=${page}&search=${search || ''}&page_size=${pageSize}`
      })

      return { count: response.data.count, result: response.data.results }
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getStates = createAsyncThunk(
  'states/get',
  async ({ page, search, pageSize }: { page: number, search: string, pageSize: number }, { rejectWithValue }) => {

    try {
      const response = await request({
        method: 'GET',
        url: `operator_portal/states?page=${page}&search=${search || ''}&page_size=${pageSize}`
      })

      return { count: response.data.count, result: response.data.results }

    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getCourse = createAsyncThunk(
  'course/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/course/'
      })

      const { data } = response

      return data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const updateCourse = createAsyncThunk(
  'course/update',
  async (course: Partial<Course>, { rejectWithValue }) => {

    let routing_number = course.routing_number
    if (routing_number === null) {
      routing_number = undefined
    }

    let account_name = course.account_name
    if (account_name === null) {
      account_name = undefined
    }

    const data = {
      ...course,
      city: course.city_id,
      state: course.state_id,
      contact_phone: '+1' + course.contact_phone,
      routing_number,
      account_name
    }

    try {
      const response = await request({
        method: 'PUT',
        url: 'operator_portal/course/',
        data: data
      })

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getTournaments = createAsyncThunk(
  'course/tournaments/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/course/tournaments/'
      })

      const { data } = response

      return data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const saveTournaments = createAsyncThunk(
  'course/tournaments/save',
  async (tournaments: any, { rejectWithValue }) => {

    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/course/tournaments/',
        data: {
          name: tournaments.tournament_name,
          year: parseInt(tournaments.tournament_year)
        }
      })

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const saveTeammate = createAsyncThunk(
  'course/temmates/save',
  async (teammate: Teammate, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/team_members/',
        data: teammate
      })

      return fromTeammateDto(response.data)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  })

export const editTeammate = createAsyncThunk(
  'course/temmates/update',
  async (teammate: Teammate, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'PATCH',
        url: `operator_portal/team_members/${teammate.id}/`,
        data: teammate
      })

      return { ...fromTeammateDto(response.data), id: teammate.id }
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)


export const getTeammates = createAsyncThunk(
  'course/temmates/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/team_members/'
      })

      return fromTeammateDtos(response.data.results)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const removeTeammate = createAsyncThunk(
  'course/temmates/delete',
  async (id, { rejectWithValue }) => {
    try {
      await request({
        method: 'DELETE',
        url: `operator_portal/team_members/${id}`
      })

      return id
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const resendTeammateInvite = createAsyncThunk(
  'course/temmates/resend-invite',
  async (id, { rejectWithValue }) => {
    try {
      await request({
        method: 'POST',
        url: `operator_portal/team_members/${id}/resend_invite/`
      })

      return id
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createCourse = createAsyncThunk(
  'course/create',
  async (course: Course, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/course/',
        data: course
      })

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const attachPictureToCourse = createAsyncThunk(
  'course/create/picture',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/course/',
        data: data
      })

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)