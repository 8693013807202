import React from 'react'
import { DetailPreview } from './EventPreviews/DetailPreview'
import { FeedPreview } from './EventPreviews/FeedPreview'
import { UserCourse } from '../../../../models/UserCourse'

type PropTypes = {
  imageUrl?: string
  type?: string
  name?: string
  date?: Date
  description?: string
  contentLink?: string
  course?: UserCourse
}

export const EventPreviews: React.FC<PropTypes> =
  ({
     imageUrl,
     type,
     name,
     date,
     description,
     contentLink,
     course
   }) => {
    return (
      <>
        <FeedPreview imageUrl={imageUrl} type={type} date={date} name={name} description={description}
                     course={course} />
        <DetailPreview imageUrl={imageUrl} type={type} name={name} date={date} description={description}
                       contentLink={contentLink} course={course} />
      </>
    )
  }
