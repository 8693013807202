import React from 'react'

import { RegistrationFrom } from './RegistrationFrom'
import { EnterMobile } from './EnterMobile'
import { EnterMobileTextSent } from './EnterMobileTextSent'
import { MobileCode } from './MobileCode'
import { CreatePassword } from './CreatePassword'
import { ValidationQuestions } from './ValidationQuestions'
import { PhotoLogo } from './PhotoLogoPage/PhotoLogo'
import { Congratulations } from '../../Pages/CongratsPage/Congratulations'

import { SignUpProgress } from '../../../constants/pagesProgress'
import { setProgress } from '../../../store/common/commonSlice'
import { AppDispatch } from '../../../store/store'
import { UserType } from '../../../store/user/userSlice'

type PropsType = {
  progress: string
  dispatch: AppDispatch
  setFullScreenBg: (args: boolean) => void
  user: UserType
}

export const SignUpContainer: React.FC<PropsType> = ({ progress, dispatch, setFullScreenBg, user }) => {
  switch (progress) {
    case SignUpProgress.SignUpMain: {
      return (
        <RegistrationFrom
          dispatch={dispatch}
          setProgress={setProgress}
          nextProgress={SignUpProgress.SignUpMobile}
        />
      )
    }
    case SignUpProgress.SignUpMobile: {
      return (
        <EnterMobile
          dispatch={dispatch}
          setProgress={setProgress}
          nextProgress={SignUpProgress.SignUpMobileText}
        />
      )
    }
    case SignUpProgress.SignUpMobileText: {
      return (
        <EnterMobileTextSent
          mobile={user.mobile}
          dispatch={dispatch}
          setProgress={setProgress}
          nextProgress={SignUpProgress.SignUpMobileCode}
          backProgress={SignUpProgress.SignUpMobile}
        />
      )
    }
    case SignUpProgress.SignUpMobileCode: {
      return (
        <MobileCode
          dispatch={dispatch}
          setProgress={setProgress}
          prevProgress={SignUpProgress.SignUpMobileText}
          nextProgress={SignUpProgress.SignUpCreatePassword}
        />
      )
    }
    case SignUpProgress.SignUpCreatePassword: {
      return (
        <CreatePassword
          dispatch={dispatch}
          setProgress={setProgress}
          nextProgress={SignUpProgress.SignUpLogoPhoto}
        />
      )
    }
    case SignUpProgress.SignUpLogoPhoto: {
      return (
        <PhotoLogo
          dispatch={dispatch}
          setFullScreenBg={setFullScreenBg}
          setProgress={setProgress}
          nextProgress={SignUpProgress.SignUpCongrats}
          courseLogo={user?.course?.logo}
        />
      )
    }
    case SignUpProgress.SignUpCongrats: {
      return <Congratulations user={user} />
    }
    case SignUpProgress.SignUpValidationQuestions: {
      return (
        <ValidationQuestions
          setFullScreenBg={setFullScreenBg}
          dispatch={dispatch}
          nextProgress={SignUpProgress.SignUpMobileCode}
          setProgress={setProgress}
          prevProgress={SignUpProgress.SignUpMain}
          user={user}
        />
      )
    }
  }
  return <div>Sign up container</div>
}

