import React, { useEffect, useState } from 'react'

import { Page } from '../../Components/Page'
import { useOffers } from '../../../../hooks/useOffers'
import { MainOffer, Offer } from '../../../../models/Offer'
import { OfferSelect } from '../OfferSelect'
import { Form } from './Form'
import { Table } from './Table'
import { CustomButton } from '../../../Common/CustomButton'
import { EDIT_FEED_OFFER, NEW_OFFER } from '../../../../constants/urls'
import { useLocation, useNavigate } from 'react-router-dom'
import { SetupProgress } from '../../Components/SetupProgress'
import { useOnlySpecificType } from '../../../../hooks/useOnlySpecificType'
import { useOutletContext } from '../../../Common/CustomContext'
import { CongratsModal } from './CongratsModal'
import { useApi } from '../../../../hooks/api/useApi'
import { publishOfferToFeed } from '../../../../store/offer/actions'

export const OffersFeedPage: React.FC = () => {
  useOnlySpecificType('public')
  const navigate = useNavigate()
  const offers: MainOffer[] = useOffers()
  const { course } = useOutletContext()
  const [selectedOfferId, setSelectedOfferId] = useState<number>()
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
  const [feedOfferId, setFeedOfferId] = useState<number>()
  const [loading, publish] = useApi(publishOfferToFeed)

  const { search } = useLocation()
  const offerId = new URLSearchParams(search).get('offerId')

  useEffect(() => {
    setSelectedOfferId(Number(offerId))
  }, [offerId])

  const onCongrats = () => {
    setIsModalOpened(false)
    navigate(EDIT_FEED_OFFER.replace(':id', String(feedOfferId)))
  }

  const onSuccess = (payload: any) => {
    setIsModalOpened(true)
    setFeedOfferId(payload.id)
  }

  const selectedOffer = offers.find(({ id }) => id === selectedOfferId)

  return (
    <Page title="Feed" relative>
      {selectedOfferId ?
        undefined :
        <div className="flex justify-end absolute right-[30px] top-5">
          <SetupProgress />
        </div>
      }
      <p className="text-14/16 text-33333 mb-2 h-30px leading-[30px]">
        Improve your yield by sending targeted Offers to golfers who’ve expressed interest in playing your course.
      </p>
      <div className="w-full relative">
        {offers.length ?
          <OfferSelect offers={offers || []} selectedOfferId={selectedOfferId} onChange={setSelectedOfferId} /> :
          <div className="flex justify-end mb-5px">
            <CustomButton
              className="mb-1 text-16/16 text-52A91A font-semibold"
              text="+ Add New Offer"
              link={NEW_OFFER}
            />
          </div>
        }
      </div>
      {selectedOfferId ?
        <Form
          offedId={selectedOfferId}
          offer={selectedOffer as Offer}
          course={course}
          loading={loading}
          submit={publish}
          onSuccess={onSuccess}
          editable
        /> :
        <Table />}
      <CongratsModal opened={isModalOpened} onConfirmed={onCongrats} onClose={onCongrats} />
    </Page>
  )
}
