import React from 'react'

import { CustomButton } from '../../Common/CustomButton'
import { SupportEmailText } from '../../Common/SupportEmailText'
import { CategoriesCard } from './CategoriesCard'

import { categoriesList } from './blockData'
import { MY_TEAM } from '../../../constants/urls'
import { useOutletContext } from '../../Common/CustomContext'

export const HowItWorksPage: React.FC = () => {
  const { course, user } = useOutletContext()
  const isLinkDisabled = !user?.is_approved
  const isPrivate = !!course?.is_private
  return (
    <section className="pt-52px">
      <div className="max-w-500px w-full mx-auto mb-70px">
        <article className="mb-6">
          <h1 className="mainApp__pageTitle_25">Get more business and more golfers:</h1>
          <h1 className="mainApp__pageTitle_25">It's as easy as 1, 2, 3</h1>
        </article>
        <div>
          <ul className="flex justify-between items-center mb-6 px-42px">
            <li className="stepNumber stepNumber__active flex justify-center items-center">
              <span className="">1</span>
            </li>
            <li className="relative max-w-100px h-5px w-full">
              <span className="greyStick h-5px block max-w-100px w-full relative" />
              <span className="greenStick h-5px w-8 absolute left-0 top-0" />
            </li>
            <li className="stepNumber flex justify-center items-center">
              <span className="opacity-50">2</span>
            </li>
            <span className="greyStick h-5px max-w-100px w-full" />
            <li className="stepNumber flex justify-center items-center">
              <span className="opacity-50">3</span>
            </li>
          </ul>
          <ul className="flex justify-between mb-16">
            <li className="text-base font-bold text-4A4A4A w-150px">Create an account</li>
            <li className="text-base opacity-50 text-4A4A4A w-150px text-center">
              Set up your Team
            </li>
            <li className="text-base opacity-50 text-4A4A4A w-150px text-right">
              Put offers in the feed
            </li>
          </ul>
          <div className="mx-auto w-max">
            <CustomButton
              className="py-2 px-7 text-base font-bold text-white bg-52A91A"
              text="Set Up My Team"
              link={MY_TEAM}
              disabled={isLinkDisabled}
            />
          </div>
        </div>
      </div>
      <div className="max-w-800px w-full mx-auto">
        <article className="mb-52px">
          <h1 className="mainApp__pageTitle_25 mb-11">Everything in one place</h1>
          <p className="text-14/16 text-333333">
            Now that your account is set up, go to “My Team” and add the team members responsible
            for creating the offers and deals that will attract more golfers to your course.
            Everything necessary to reach those golfers and promote your course is listed below.
            Questions? Reach us at
            <SupportEmailText />
          </p>
        </article>
        <ul>
          {categoriesList.map((item) => {
            return <CategoriesCard {...item} key={item.title} isPrivate={isPrivate} linkDisabled={isLinkDisabled}  />
          })}
        </ul>
      </div>
    </section>
  )
}
