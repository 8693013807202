import React from 'react'
import { useOutlet } from 'react-router-dom'
import { UserCourse } from '../../models/UserCourse'
import { User } from '../../models/User'

type ContextType = {
  course?: UserCourse,
  user?: User
}

const Context = React.createContext<ContextType>({})

export function CustomOutlet({ data }: any) {
  const el = useOutlet()
  return <Context.Provider value={data}>{el}</Context.Provider>
}

export function useOutletContext(): ContextType {
  const context = React.useContext(Context)
  if (!context) {
    throw Error('Using context while not in an Outlet Provider')
  }
  return context
}