import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { GolfAppHeader } from './GolfAppHeader'
import { HOW_IT_WORKS, LOGGED_OUT, NOT_APPROVED } from '../../../constants/urls'
import { getMe } from '../../../store/user/actions'
import { CustomOutlet } from '../../Common/CustomContext'
import { useApiResource } from '../../../hooks/api/useApiResource'
import { NavBar } from './NavBar'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { MobilePlaceholder } from './MobilePlaceholder'
import { ApiNotifications } from '../../GolfApp/Components/ApiNotifications'

type PropsType = {
  isFullScreenBg: boolean
  isInitialRoute: boolean
}

export const GolfAppLayout: React.FC<PropsType> = ({ isFullScreenBg, isInitialRoute }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const screenSize = useScreenSize()
  const [loading, user] = useApiResource('user', 'me', getMe, false, location.pathname.includes(LOGGED_OUT) || !location.pathname)

  const showNotApprovedPlaceholder = useMemo(() => {
    const allowedForNotApprovedAfterFirstLoad = [
      HOW_IT_WORKS,
    ]

    const allowedForNotApprovedEveryLoad = [
      LOGGED_OUT,
    ]

    if (loading || user?.is_approved) {
      return false
    }

    if (allowedForNotApprovedEveryLoad.includes(location.pathname)) {
      return false
    }

    if (allowedForNotApprovedAfterFirstLoad.includes(location.pathname)) {
      if (!isInitialRoute) {
        return false
      }
    }

    return true

  }, [location, user, loading])

  useEffect(() => {
    if (showNotApprovedPlaceholder) {
      navigate(NOT_APPROVED)
    }
  }, [showNotApprovedPlaceholder])

  return (
    <div className="relative width__container">
      <GolfAppHeader user={user} loading={loading} disabledDropdown={!user?.is_approved} />
      <main className="flex">
        {screenSize === 'lg' && user?.is_approved && <NavBar course={user?.course} />}
        {screenSize === 'lg' && <CustomOutlet data={{ course: user?.course, user: user }} />}
        {screenSize === 'sm' && <MobilePlaceholder />}
      </main>
      {isFullScreenBg && (
        <div className="bg-black opacity-60 absolute left-0 top-0 w-full h-full z-10" />
      )}
      <ApiNotifications />
    </div>
  )
}