import React, { useMemo, useState } from 'react'
import { CropImage } from '../../Common/CropImage'
import { UploadedPicture } from '../Components/UploadedPicture'
import FooterLogo from '../../../assets/svg/footerLogo.svg'
import { fileListToImage } from '../../../helpers/image'
import { AppImage } from '../../../models/AppImage'
import { CustomButton } from '../../Common/CustomButton'

enum CropImageTarget {LOGO, PICTURE}

type PropTypes = {
  onSubmit: (data: any) => Promise<any>,
  onSuccess: () => void
}

export const AttachImages: React.FC<PropTypes> = ({ onSubmit, onSuccess }) => {
  const [imageToCrop, setImageToCrop] = useState<AppImage | null>()
  const [cropImageTarget, setCropImageTarget] = useState<CropImageTarget>(CropImageTarget.LOGO)
  const [logo, setLogo] = useState<any>(null)
  const [picture, setPicture] = useState<any>(null)

  const setTargettedImageToCrop = (event: React.ChangeEvent<HTMLInputElement>, target: CropImageTarget) => {
    const image = fileListToImage(event.target.files)
    setImageToCrop(image)
    setCropImageTarget(target)
  }

  const save = () => {
    onSubmit({ logo, picture })
    .then(({ error, payload }) => {
      return onSuccess()
    })
  }

  const config = useMemo(() => {
    switch (cropImageTarget) {
      case CropImageTarget.LOGO:
        return {
          title: 'Upload Logo',
          setCroppedLogo: setLogo,
          cropSize: { width: 400, height: 400 }
        }
      default:
        return {
          title: 'Upload Logo',
          setCroppedLogo: setPicture,
          cropSize: { width: 400, height: 246 }
        }
    }
  }, [cropImageTarget])

  return (
    <>
      {imageToCrop && <CropImage
        className="uploadImage__modal top-[10rem!important]"
        showBackground
        logoImg={imageToCrop?.url}
        onUploadModalClose={() => setImageToCrop(null)}
        {...config}
      />}
      <div className="w-full max-w-[647px]">
        <h2 className="text-4A4A4A text-20/24 leading-[49px] mt-[43px]">
          Check logo and photo
        </h2>
        <p className="mt-4 mb-[34px] text-4A4A4A opacity-80 text-16/19">
          If these are not the correct logo and photo for your course, click on them to replace.
        </p>
        <div className="w-full flex justify-between pl-[86px]">
          <UploadedPicture
            onImageChange={(event: React.ChangeEvent<HTMLInputElement>) => setTargettedImageToCrop(event, CropImageTarget.LOGO)}
            inputName="logo"
            image={logo?.url}
            className="w-[118px] h-[118px] mt-4"
            defaultComponent={<img src={FooterLogo} alt="" className="w-100px" />}
            label="Course Logo"
          />
          <UploadedPicture
            onImageChange={(event: React.ChangeEvent<HTMLInputElement>) => setTargettedImageToCrop(event, CropImageTarget.PICTURE)}
            inputName="picture"
            image={picture?.url}
            className="w-[328px] h-[202px]"
            defaultComponent={<span className="text-16/19 font-medium text-979797">UPDATE LOGO OR PICTURE</span>}
            label="Course Picture"
          />
        </div>
        <div className="w-full flex justify-end mt-10">
          <CustomButton className="main-button w-[232px] h-[45px]" text="They’re Both Right" onClick={save} />
        </div>
      </div>
    </>
  )
}