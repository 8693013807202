import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { CustomButton } from '../../../Common/CustomButton'
import { GolfInput } from '../../Components/GolfInput'
import { GolfSelect } from '../../Components/GolfSelect'

import { InputPhoneMask } from '../../../Common/InputPhoneMask'
import { Course } from '../../../../models/Course'
import { useSelectOptions } from '../../../../hooks/useSelectOptions'
import { conformToMask } from 'react-text-mask'
import { UsePaginatedApi } from '../../../../hooks/api/usePaginatedApi'
import { CustomRadioButton } from '../../../Common/CustomRadioButton'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { handleApiErrors } from '../../../../helpers/api'
import { useSaveButtonConfig } from '../../../../hooks/useSaveButtonConfig'
import { urlValidator } from "../../../../helpers/url";

const phoneNumberMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

const toFormObject = (course?: Course): Inputs | undefined => {
  if (!course) {
    return
  }
  return {
    ...course,
    contact_phone: course?.contact_phone ? conformToMask(course?.contact_phone?.replace('+1', '') || '', phoneNumberMask).conformedValue : '',
    is_private: course.is_private ? 'true' : 'false'
  }
}

const fromFormObject = (data: Inputs): Partial<Course> => {
  return {
    ...data,
    is_private: data.is_private === 'true',
    contact_phone: data.contact_phone.replace('-', '')
  }
}

type Inputs = {
  url: string
  tee_time_booking_url: string
  is_private: 'true' | 'false'
  address1: string
  address2: string
  city_id: string
  state_id: string
  zip: string
  contact_phone: string
  account_number1?: string
  account_number2?: string
  routing_number?: string
  account_name?: string
}

type PropTypes = {
  course?: Course,
  cityPagination: UsePaginatedApi,
  statePagination: UsePaginatedApi,
  onSubmit: (course: Partial<Course>) => Promise<any>
  onStateSearch: (search: string) => void
  onCitySearch: (search: string) => void
  loading: boolean
}

const validationSchema = Yup.object().shape({
  is_private: Yup.string().required('Type of course is required.'),
  url: urlValidator.required('Website is required.'),
  tee_time_booking_url: urlValidator
})

export const MyCourseForm: React.FC<PropTypes> = (
  { cityPagination, statePagination, course, onSubmit, onStateSearch, onCitySearch, loading }
) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => toFormObject(course) || {}, [course]),
    resolver: yupResolver(validationSchema)
  })
  const saveButtonConfig = useSaveButtonConfig(loading)
  const citySelect = useSelectOptions(cityPagination.data)
  const stateSelect = useSelectOptions(statePagination.data)

  useEffect(() => {
    reset(toFormObject(course))
  }, [course])


  const onFormSubmit: SubmitHandler<Inputs> = (data) => {
    onSubmit(fromFormObject(data))
    .then(({ error, payload }) => {
      if (error) {
        handleApiErrors(setError, payload)
      }
    })
  }


  const [
    isPrivate,
    city,
    state
  ] = watch(['is_private', 'city_id', 'state_id'])

  const setCity = (id: string) => setValue('city_id', id)
  const setState = (id: string) => setValue('state_id', id)
  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="max-w-650px w-full mt-2">
      <h2 className="text-20/24 text-4A4A4A font-semibold">General Information</h2>
      <p className="text-12/14 text-979797 mb-4 mt-0.5">
        Contact <a href="mailto:headpro@golfbevy.com" className="text-52A91A">headpro@golfbevy.com</a> if the name of
        course changes.
      </p>
      <div className="flex justify-between mb-18px">
        <div className="max-w-300px w-full">
          <GolfInput
            name="url"
            inputClass="w-full"
            label="Course Website*"
            htmlFor="url"
            isError={errors.url}
            register={register}
          />
        </div>
        <div className="max-w-300px w-full">
          <GolfInput
            name="tee_time_booking_url"
            inputClass="w-full"
            label="Tee Time Booking URL"
            htmlFor="tee_time_booking_url"
            isError={errors.tee_time_booking_url}
            register={register}
            required={false}
          />
        </div>
      </div>
      <div className="mb-18px">
        <h3 className="block text-15/18 text-333333 font-bold cursor-pointer mb-2">
          Type of course*
        </h3>
        <span className="block text-9C9C9C text-12/14 mb-2.5">Please check one</span>
        <CustomRadioButton
          name="is_private"
          textClass="ml-2.5 text-base text-black"
          options={[{ text: 'Public Course', value: 'false' }, { text: 'Private Course', value: 'true' }]}
          checkMarkColor="green"
          register={register}
          value={isPrivate}
          className="mr-7"
        />
      </div>
      <div className="w-full mb-18px">
        <GolfInput
          inputClass="w-full"
          label="Street Address"
          htmlFor="address1"
          name="address1"
          isError={errors.address1}
          register={register}
        />
        <span className="block text-9C9C9C text-12/14 mb-1 mt-2">Second address</span>
        <GolfInput
          inputClass="w-full"
          htmlFor="address2"
          name="address2"
          isError={errors.address2}
          register={register}
          required={false}
        />
      </div>
      <div className="flex items-end justify-between mb-18px">
        <div className="relative max-w-435px w-full">
          <GolfSelect
            className="text-15/18 h-40px border-979797"
            name="city_id"
            text="City"
            selectedOption={city}
            setSelectedOption={setCity}
            optionsModalClass="h-190px top-20"
            isError={errors.city_id}
            searchable
            onSearch={onCitySearch}
            register={register}
            forcedInputValue={course?.city}
            {...citySelect}
            infiniteScrollConfig={{
              loading: cityPagination.loading,
              hasNextPage: cityPagination.hasNextPage,
              onLoadMore: cityPagination.loadMore
            }}
          />
        </div>
        <div className="relative max-w-75px w-full">
          <GolfSelect
            className="text-15/18 h-40px border-979797"
            name="state_id"
            text="State"
            selectedOption={state}
            setSelectedOption={setState}
            optionsModalClass="h-190px top-20"
            isError={errors.state_id}
            register={register}
            searchable
            onSearch={onStateSearch}
            forcedInputValue={course?.state}
            {...stateSelect}
            infiniteScrollConfig={{
              loading: statePagination.loading,
              hasNextPage: statePagination.hasNextPage,
              onLoadMore: statePagination.loadMore
            }}
          />
        </div>
        <div className="max-w-100px w-full">
          <GolfInput
            inputClass="w-full"
            label="Postal Code"
            htmlFor="zip"
            name="zip"
            isError={errors.zip}
            register={register}
          />
        </div>
      </div>
      <div className="mb-10">
        <div>
          <span
            className={`text-15/18 text-333333 font-bold ${errors.contact_phone && 'text-C43100'}`}
          >
          Course Phone Number
        </span>
          {errors.contact_phone && <span className="text-C43100 text-12/14 ml-3">{errors.contact_phone.message}</span>}
        </div>
        <div className="max-w-300px">
          <InputPhoneMask
            name="contact_phone"
            inputClass="myCourse__input"
            inputErrorClass="myCourse__input_error"
            flagBoxClass="border-979797"
            id="contact_phone"
            mask={phoneNumberMask}
            isError={errors.contact_phone}
            control={control}
          />
        </div>
      </div>
      {/* <h2 className="text-20/24 text-4A4A4A font-semibold">Bank Information for Buy It Now</h2>
      <p className="text-12/14 text-979797 mb-4 mt-0.5">
       Bank Information for Buy It Now. We need the bank information for Private Clubs that participate in Buy It Now so we know where to send their deposits.
       Click to learn about <a href="mailto:headpro@golfbevy.com" className="text-52A91A">Buy It Now</a> and how it can increase a private club's revenue or write us at <a href="mailto:headpro@golfbevy.com" className="text-52A91A">headpro@golfbevy.com</a>.
      </p>
      <div className="w-64 mb-18px">
       <GolfInput
         inputClass="w-full"
         label="Routing Number*"
         htmlFor="routing_number"
         name="routing_number"
         isError={errors.routing_number}
         register={register}
       />
      </div>
      <div className="max-w-sm mb-18px">
       <GolfInput
         inputClass="w-full"
         label="Enter Account Number*"
         htmlFor="account_number1"
         name="account_number1"
         isError={errors.account_number1}
         register={register}
       />
      </div>
      <div className="max-w-sm mb-18px">
       <GolfInput
         inputClass="w-full"
         label="Enter Account Number Again*"
         htmlFor="account_number2"
         name="account_number2"
         isError={errors.account_number2}
         register={register}
       />
      </div>
      <div className="w-full mb-18px">
       <GolfInput
         inputClass="w-full"
         label="Name On Account*"
         htmlFor="account_name"
         name="account_name"
         isError={errors.account_name}
         register={register}
       />
      </div> */}
      <div className="flex justify-end">
        <CustomButton
          className={`py-2.5 px-52px font-bold text-white bg-52A91A mb-5`}
          type="submit"
          loading={loading}
          {...saveButtonConfig}
        />
      </div>
    </form>
  )
}
