import { User } from '../../../models/User'
import { useApi } from '../../../hooks/api/useApi'
import { updateMyProfile } from '../../../store/user/actions'
import { useForm } from 'react-hook-form'
import { handleApiErrors } from '../../../helpers/api'
import { GolfInput } from '../Components/GolfInput'
import { CustomButton } from '../../Common/CustomButton'
import React, { useMemo, useEffect } from 'react'
import { DoubleFields } from './DoubleFields'

type Profile = {
  first_name: string
  last_name: string
  email: string
  title: string
}

type MainFormType = {
  user?: User
}

export const MainForm: React.FC<MainFormType> = ({ user }) => {
  const [loading, save] = useApi(updateMyProfile)
  const { register, reset, setError, handleSubmit, formState: { errors } } = useForm<Profile>({
    defaultValues: useMemo(() => user || {}, [user])
  })

  useEffect(() => {
    reset(user)
  }, [user])

  const saveUpdates = (data: Profile) => {
    save(data)
    .then(({ error, payload }) => {
      if (!error) {
        return
      }
      handleApiErrors(setError, payload)
    })
  }

  return (
    <div className="w-full flex justify-center">
      <form className="w-full max-w-[657px] mt-5" onSubmit={handleSubmit(saveUpdates)}>
        <DoubleFields>
          <GolfInput name="first_name" label="First Name" register={register} isError={errors.first_name} />
          <GolfInput name="last_name" label="Last Name" register={register} isError={errors.last_name} />
        </DoubleFields>
        <DoubleFields>
          <GolfInput name="title" label="Title" register={register} isError={errors.title} required={false} />
          <GolfInput name="email" label="Email" disabled register={register} isError={errors.email} />
        </DoubleFields>
        <div className="flex justify-end w-full top-0 absolute left-0">
          <CustomButton className="main-button w-[167px] h-10 mr-10 mt-[25px]" type="submit" text="Save"
                        loading={loading} />
        </div>
      </form>
    </div>
  )
}
