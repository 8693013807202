import React, { useEffect, useState } from 'react'
import {
  EVENTS,
  MY_COURSE,
  MY_COURSE_HISTORY,
  MY_COURSE_PHOTOS,
  MY_COURSE_TOURNAMENTS,
  OFFERS,
  OFFERS_AVAILABILITY_GRID,
  OFFERS_FEED,
  PROMOTE_MY_COURSE
} from '../../../constants/urls'
import { ComingSoonText } from '../../Common/ComingSoonText'
import { Link, useLocation } from 'react-router-dom'
import { UserCourse } from '../../../models/UserCourse'
import { useAvailableLinks } from '../../../hooks/useAvailableLinks'

const buildNavList = (isPrivate: boolean) => {
  let list: any[] = [{ name: 'Easy as 1, 2, 3', link: '/how-it-works' }]

  if (isPrivate) {
    list.push({
      name: 'Promote My Course',
      link: PROMOTE_MY_COURSE,
      tabs: [
        { name: 'Events', link: EVENTS },
        {
          name: 'Buy It Now',
          link: '/bin',
          component: () => (
            <ComingSoonText className="text-[6.5px] py-5px px-[3px] leading-[6.5px] mr-4" />
          )
        }
      ]
    })
  } else {
    list.push({
      name: 'Promote My Course',
      link: PROMOTE_MY_COURSE,
      tabs: [
        { name: 'Events', link: EVENTS },
        {
          name: 'Offers',
          link: OFFERS
        },
        { name: 'Feed', link: OFFERS_FEED, class: 'ml-4' },
        { name: 'Availability Grid', link: OFFERS_AVAILABILITY_GRID, class: 'ml-4' },
        {
          name: 'GolfBevy Premium',
          link: '/passbook',
          component: () => (
            <ComingSoonText className="text-[6.5px] py-5px px-[3px] leading-[6.5px] mr-4" />
          )
        }
      ]
    })
  }

  list = [
    ...list,
    ...[
      {
        name: 'My Course',
        link: MY_COURSE,
        tabs: [
          { name: 'Course Information', link: MY_COURSE },
          {
            name: 'Course Photos',
            link: MY_COURSE_PHOTOS,
            component: () => (
              <ComingSoonText className="text-[6.5px] py-5px px-[3px] leading-[6.5px] mr-4" />
            )
          },
          {
            name: 'Course History',
            link: MY_COURSE_HISTORY
          },
          {
            name: 'Course Tournaments',
            link: MY_COURSE_TOURNAMENTS,
            hidden: false,
            component: () => (
              <ComingSoonText className="text-[6.5px] py-5px px-[3px] leading-[6.5px] mr-4" />
            )
          }
        ]
      },
      { name: 'My Team', link: '/my-team' }
    ]
  ]
  return list
}

type PropTypes = {
  course: UserCourse
}

export const NavBar: React.FC<PropTypes> = ({ course }) => {
  const location = useLocation()
  const [active, setActive] = useState(0)
  const [activePromoteTab, setActivePromoteTab] = useState<string>()
  const isPrivate = !!course?.is_private

  const navList = course ? buildNavList(isPrivate) : []

  const findPathIndex = (path: string) => {
    return navList.findIndex(({ link, tabs }) => {
      const childIndex = tabs?.findIndex((tab: any) => path.includes(tab.link)) || -1
      return path.includes(link) || childIndex !== -1
    })
  }

  const findChildPath = (path: string) => {
    for (let item of navList) {
      if (!item.tabs) {
        continue
      }
      for (let tab of item.tabs) {
        if (path.includes(tab.link)) {
          return tab.link
        }
      }
    }
  }

  useEffect(() => {
    setActive(findPathIndex(location.pathname))
    setActivePromoteTab(findChildPath(location.pathname))
  }, [location, navList])

  return (
    <nav className="mainApp__navbar max-w-230px w-full bg-EEF1EB py-11  flex flex-col justify-between">
      <ul className="text-15/18 text-333333">
        {navList.map((item, index) => {
          const isActive = index === active
          if (item.tabs) {
            return (
              <TabWithChild
                item={item}
                isActive={isActive}
                setActive={setActive}
                setActivePromoteTab={setActivePromoteTab}
                index={index}
                activePromoteTab={activePromoteTab}
                key={item.name}
              />
            )
          }
          return (
            <Tab
              item={item}
              isActive={isActive}
              setActive={setActive}
              setActivePromoteTab={setActivePromoteTab}
              index={index}
              key={item.name}
            />
          )
        })}
      </ul>
      <p className="inline-block text-12/14 pl-8">
        Need help?
        <a className="text-6EB629 font-bold text-12/14" href="mailto:headpro@golfbevy.com">
          {' '}
          Contact Support
        </a>
      </p>
    </nav>
  )
}

type TabWithChildPropTypes = {
  setActive: any
  setActivePromoteTab: any
  item: any
  isActive: any
  index: any
  activePromoteTab: any
}

const TabWithChild: React.FC<TabWithChildPropTypes> = ({
  setActive,
  setActivePromoteTab,
  isActive,
  item,
  index,
  activePromoteTab
}) => {
  const onSetActive = () => {
    if (item.link) {
      setActivePromoteTab(null)
    }
    setActive(index)
  }

  return (
    <li
      onClick={onSetActive}
      className={`relative cursor-pointer ${isActive && 'bg-white font-bold'}`}
    >
      <Link className="px-8 block py-3" to={item.link || '#'}>
        <span>{item.name}</span>
      </Link>
      <ul>
        {item.tabs.map((tab: any) => {
          if (tab.hidden) {
            return <div key={`${tab.name}-${index}`} />
          }
          // @ts-ignore
          const Component = tab.component
          const isPromoteTabActive = tab.link === activePromoteTab
          const onSetActivePromoteTab: any = (event: Event) => {
            event.stopPropagation()
            setActivePromoteTab(tab.link)
            setActive(index)
          }
          return (
            <li
              onClick={onSetActivePromoteTab}
              className={`relative pl-10 cursor-pointer text-13/15 font-normal
                               ${isPromoteTabActive && 'bg-52A91A/70 text-white font-bold'}
                               `}
              key={`${tab.name}-${index}`}
            >
              <Link
                className={`py-3 w-full flex justify-between items-center ${
                  tab.class ? tab.class : ''
                }`}
                to={tab.link}
              >
                <span>{tab.name}</span>
                {Component && <Component />}
              </Link>
            </li>
          )
        })}
      </ul>
      {isActive && (
        <>
          <span className="absolute left-0 top-42px h-0.5 w-full bg-6EB629" />
          <span className="absolute left-0 top-0 h-full w-5px bg-6EB629" />
        </>
      )}
    </li>
  )
}

type TabPropTypes = {
  setActive: any
  setActivePromoteTab: any
  item: any
  isActive: any
  index: any
}

const Tab: React.FC<TabPropTypes> = ({ setActive, setActivePromoteTab, isActive, item, index }) => {
  return (
    <li
      onClick={() => {
        setActive(index)
        setActivePromoteTab(null)
      }}
      className={`relative cursor-pointer flex items-center ${isActive && 'bg-white font-bold'}`}
      key={item.name}
    >
      <Link className="py-3 px-8 w-full flex justify-between items-center" to={item.link}>
        <span>{item.name}</span>
      </Link>
      {isActive && <span className="absolute left-0 top-0 h-42px w-5px bg-6EB629" />}
    </li>
  )
}
