import { readFile } from './readFile'

export const onImgChange = async (
  e: React.ChangeEvent<HTMLInputElement>,
  setLogoImg: (logo: unknown, name?: string) => void,
  setPhotoImg?: (image: unknown, name?: string) => void
) => {
  if (e.target.files && e.target.files.length > 0) {
    const id = e.target.id
    const file = e.target.files[0]
    let imageDataUrl = await readFile(file)
    const data = { name: file.name, url: imageDataUrl }
    if (id === 'logo') {
      setLogoImg(data)
    } else {
      setPhotoImg && setPhotoImg(data)
    }
  }
}
