import React, { useCallback, useMemo, useState } from 'react'
import { MainOffer } from '../../../../models/Offer'
import { CustomDropdown } from '../../../Common/CustomDropdown'
import { Body } from './Body'
import { Header } from './Header'

type PropTypes = {
  offers: MainOffer[]
  selectedOfferId?: number
  onChange: (offerId: number) => void
  disabled?: boolean
}

export const OfferSelect: React.FC<PropTypes> = ({ offers, selectedOfferId, onChange, disabled = false }) => {
  const [isOpen, setOpen] = useState(false)

  const setOpenIfEnabled = useCallback((isOpen: boolean) => {
    if (disabled) {
      return
    }

    setOpen(isOpen)
  }, [setOpen, disabled])

  const offerName = useMemo(() => {
    const offer = offers.find(({ id }) => id === selectedOfferId)
    return offer?.name || 'Select Offer'
  }, [offers, selectedOfferId])

  const selectOffer = (offer: MainOffer) => {
    onChange(offer.id)
    setOpen(false)
  }

  return (
    <CustomDropdown isOpen={isOpen} setOpen={setOpenIfEnabled} classNames={{ bodyContainer: 'rounded mt-0.5 px-2.5' }}>
      {{
        header: <Header isOpen={isOpen} value={offerName} disabled={disabled} />,
        body: <Body offers={offers} onOfferSelect={selectOffer} />
      }}
    </CustomDropdown>
  )
}
