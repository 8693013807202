import { TeammateStatus } from '../models/TeammateStatus'
import { Teammate, TeammateType } from '../models/Teammate'
import { User } from '../models/User'

export const fromTeammateDto = (teammateDto: any): Teammate => {
  const { name } = teammateDto
  const names = (name || '').split(' ')
  const first_name = teammateDto.first_name || names[0]
  const last_name = teammateDto.last_name || names.reverse()[0]
  return {
    ...teammateDto,
    first_name,
    last_name
  }
}

export const fromTeammateDtos = (teammateDtos: any[]): Teammate[] => {
  return teammateDtos.map(fromTeammateDto)
}

export const statusToName = (status: TeammateStatus | undefined): string => {
  if (!status) {
    return 'Awaiting Validation'
  }
  switch (status) {
    case TeammateStatus.ACTIVE:
      return 'Active'
    case TeammateStatus.INACTIVE:
      return 'Inactive'
    case TeammateStatus.PENDING:
      return 'Awaiting Validation'
    default:
      return ''
  }
}

export const canEdit = (teammate: Teammate, user: User | undefined): boolean => {
  const isCurrentUser = user?.email === teammate.email
  const isInitialUser = isInitial(teammate)
  if (isInitialUser) {
    return isCurrentUser
  }
  return true
}

export const canRemove = (teammate: Teammate): boolean => {
  const isInitialUser = isInitial(teammate)
  return !isInitialUser

}

const isInitial = (teammate: Teammate): boolean => {
  return teammate.type === TeammateType.INITIAL
}