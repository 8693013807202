import React from 'react'

type PropTypes = {
  loading?: boolean
  className?: string
}

export const Loading: React.FC<PropTypes> = ({ children, loading, className = '' }) => {
  return (
    <div className={`loading ${loading ? 'active' : ''} ${className}`}>
      {children}
    </div>
  )

}