import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { GolfInput } from '../Components/GolfInput'
import { GolfTextarea } from '../Components/GolfTextarea'
import { CustomButton } from '../../Common/CustomButton'
import { TextAreaBottomCharsCount } from '../Components/small/TextAreaBottomCharsCount'
import { textFieldsMaxLength } from '../../../constants/textFieldsMaxLength'
import { CustomCheckbox } from '../../Common/CustomCheckbox'
import { InputUploadImage } from '../Components/InputUploadImage'
import { OFFERS } from '../../../constants/urls'
import { IdContainer } from '../../Common/IdContainer'
import { OfferPreview } from './OfferForm/OfferPreview'
import { Offer } from '../../../models/Offer'
import { fileListToImage } from '../../../helpers/image'
import { ConfirmSuspendingDialog } from '../Components/dialogs/ConfirmSuspendingDialog'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserCourse } from '../../../models/UserCourse'
import { AsyncThunk } from '@reduxjs/toolkit'
import { useApi } from '../../../hooks/api/useApi'
import { formatId } from '../../../helpers/id'
import _ from 'lodash'
import { editOffer, updateOfferStatus } from '../../../store/offer/actions'
import { PublishModal } from './OfferForm/PublishModal'
import { useOffers } from '../../../hooks/useOffers'
import { handleApiErrors } from '../../../helpers/api'
import { useSaveButtonConfig } from '../../../hooks/useSaveButtonConfig'

type Inputs = {
  name: string
  message: string
  code: string
  is_active_in_tee_sheet: boolean
  image: FileList | { name: string; url: string } | undefined
  withoutImage: boolean
}

type PropTypes = {
  editing?: boolean
  offer?: Offer
  onSubmitThunk: AsyncThunk<any, any, any>
  course?: UserCourse
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Offer Name is required')
  .min(5, 'Must have at least 5 characters'),
  message: Yup.string().required('Offer Description is required'),
  code: Yup.string().required('Offer Code is required')
})

export const OfferForm: React.FC<PropTypes> = ({ editing, offer, onSubmitThunk, course }) => {
  const offers = useOffers()
  const [suspendDialogOpened, setSuspendDialogOpened] = useState<boolean>(false)
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const [updateStatusLoading, updateStatus] = useApi(updateOfferStatus)
  const [offerId, setOfferId] = useState<number>()
  const apiThunk = offerId ? editOffer : onSubmitThunk
  const [saving, onSubmit] = useApi(apiThunk)
  const saveButtonConfig = useSaveButtonConfig(saving)

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => offer || {}, [offer]),
    resolver: yupResolver(validationSchema)
  })
  useEffect(() => {
    reset(offer)
  }, [offer])

  const onFormSubmit: SubmitHandler<Inputs> = (formData) => {
    const data: any = { ...formData }

    if (offerId) {
      data.id = offerId
    }

    onSubmit(_.omit(data, ['status']))
    .then(({ error, payload }) => {
      if (!error) {
        setOfferId(payload.id)
        return setTimeout(() => setSaved(true), 2 * 1000)
      }
      handleApiErrors(setError, payload)
    })
  }

  const confirmSuspending = () => {
    updateStatus({ offer, suspended: !offer?.suspended })
    .then(() => {
      setSuspendDialogOpened(false)
    })
  }

  const [message, name, isPromoCodeActive, image, promoCode, withoutImage] = watch([
    'message',
    'name',
    'is_active_in_tee_sheet',
    'image',
    'code',
    'withoutImage'
  ])

  const previewURL = useMemo(() => {
    return fileListToImage(image)?.url
  }, [image])

  return (
    <div className="flex px-16 mt-7">
      <form className="max-w-590px w-full mr-32 pt-1.5" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mb-1">
          <GolfInput
            name="name"
            label="Offer Name*"
            labelText="5 Character Min"
            isError={errors.name}
            register={register}
            disabled={offer?.suspended || saved}
            maxLength={textFieldsMaxLength.passbook.course_name}
          />
          <div className="text-right text-12/14 text-333333 opacity-50 mt-2">
            <span>{name?.length || 0}</span>
            <span>/{textFieldsMaxLength.passbook.course_name}</span>
          </div>
        </div>
        <div>
          <GolfTextarea
            text="Offer Description*"
            name="message"
            className="h-100px"
            labelText="Include offer details plus when it is applicable (dates, days of the week, time of day, etc.)"
            isError={errors.message}
            register={register}
            disabled={offer?.suspended || saved}
            maxLength={300}
          />
          <TextAreaBottomCharsCount
            maxTextLength={300}
            messageLength={message?.length || 0}
          />
        </div>
        <div className="mb-5">
          <InputUploadImage
            labelText="JPG or PNG, The higher the resolution the better. Standard image will appear unless you upload another."
            register={register}
            name="image"
            value={image}
            setValue={(value: FileList | undefined) => setValue('image', value)}
            rightButton={false}
            disabled={offer?.suspended || saved}
          />
          <div className="mt-2">
            <CustomCheckbox
              isCheckboxChecked={withoutImage}
              checkMarkColor="green"
              text="I don’t have a photo for this Offer"
              name="withoutImage"
              register={register}
              htmlFor="withoutImage"
              textClass="ml-2 text-12/14"
              disabled={offer?.suspended || saved}
            />
          </div>
        </div>
        <div className="mb-8">
          <GolfInput
            htmlFor="code"
            name="code"
            label="Offer Code*"
            labelText="After creating Offer Code, add it to your tee sheet software so it can be redeemed online or in Pro Shop."
            isError={errors.code}
            register={register}
            inputClass="mb-2"
            labelTextOnNewLine
            disabled={offer?.suspended || saved}
          />
          <CustomCheckbox
            isCheckboxChecked={isPromoCodeActive}
            checkMarkColor="green"
            text="This code is now active in my Tee Sheet"
            name="is_active_in_tee_sheet"
            register={register}
            htmlFor="is_active_in_tee_sheet"
            textClass="ml-2 text-12/14"
            disabled={offer?.suspended || saved}
          />
        </div>
        <div className="flex justify-between mb-10">
          <div className="flex items-center">
            <CustomButton className="secondary-button" text="< All Offers" link={OFFERS} />
          </div>
          <div>
            {/*{editing && (*/}
            {/*  <CustomButton*/}
            {/*    text={offer?.suspended ? 'Unsuspend Offer' : 'Suspend Offer'}*/}
            {/*    className="text-16/19 text-C43100 font-medium mr-50px"*/}
            {/*    onClick={() => setSuspendDialogOpened(true)}*/}
            {/*  />*/}
            {/*)}*/}
            {
              !editing && saved && (
                <CustomButton
                  text="Edit"
                  className="secondary-button mr-50px"
                  onClick={() => setSaved(false)}
                />
              )
            }
            {saved ?
              <CustomButton
                className="py-2 px-7 main-button w-176px"
                text="Publish"
                onClick={() => setIsModalOpened(true)}
              />
              :
              <CustomButton
                className="py-2 px-7 main-button w-176px"
                text={saveButtonConfig.text}
                type="submit"
                disabled={!isPromoCodeActive || offer?.suspended || saveButtonConfig.disabled}
                loading={saving}
              />}
          </div>
        </div>
      </form>
      <div>
        <div className="flex justify-center mb-30px mt-3">
          <IdContainer name="Offer" id={formatId(offer?.id || offerId)} />
        </div>
        <OfferPreview
          name={name}
          description={message}
          promoCode={promoCode}
          imageUrl={previewURL}
          withoutImage={withoutImage}
          course={course}
        />
      </div>
      <ConfirmSuspendingDialog
        title={`Are You Sure You Want To ${offer?.suspended ? 'Unsuspend' : 'Suspend'} This Event?`}
        submitText={offer?.suspended ? 'Unsuspend' : 'Suspend'}
        opened={suspendDialogOpened}
        onReject={() => setSuspendDialogOpened(false)}
        onSubmit={confirmSuspending}
        suspending={updateStatusLoading}
      />
      <PublishModal opened={isModalOpened} close={() => setIsModalOpened(false)} offerId={offerId} />
    </div>
  )
}
