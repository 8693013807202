import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Loading } from './Loading'

type PropsType = {
  className: string
  text?: string
  disabled?: boolean
  onClick?: (arg0: any) => void
  type?: 'button' | 'submit' | 'reset' | undefined
  link?: string
  icon?: string
  iconProps?: Record<string, any>
  loading?: boolean
}

export const CustomButton: React.FC<PropsType> = memo((
    { className, text, disabled, onClick, type = 'button', link, icon, iconProps, loading = false }) => {
    return (
      <Loading loading={loading} className="inline-block">
        {link && !disabled ? (
          <Link className={`block rounded-3px cursor-pointer ${className}`} to={link}>
            {text}
          </Link>
        ) : (
          <button
            onClick={onClick}
            className={`rounded-3px cursor-pointer ${className}`}
            disabled={disabled}
            type={type}
          >
            {text}
            <img src={icon} alt="" {...iconProps} />
          </button>
        )}
      </Loading>
    )
  }
)
