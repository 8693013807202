import React from 'react'
import { CustomButton } from '../../../Common/CustomButton'
import Crosses from '../../../../assets/svg/crosses-small.svg'
import Info from '../../../../assets/svg/info.svg'
import { OFFERS_AVAILABILITY_GRID, OFFERS_FEED } from '../../../../constants/urls'
import { useNavigateSearch } from '../../../../hooks/useNavigateSearch'

type PropTypes = {
  opened: boolean
  close: () => void
  offerId?: number
}

export const PublishModal: React.FC<PropTypes> = ({ opened, close, offerId }) => {
  const navigate = useNavigateSearch()
  return opened ? (
    <div className="absolute w-full h-full top-0 left-0">
      <div className="modal top-[30%!important]">
        <div className="flex justify-between p-2">
          <img src={Info} alt="" />
          <img src={Crosses} alt="" className="cursor-pointer" onClick={close} />
        </div>
        <div className="max-w-294px w-full pt-4 px-7 pb-6 text-center">
          <p className="text-17/22 font-sfui font-medium text-center">
            Where would you like to publish this Offer?
          </p>
        </div>
        <div className="flex justify-center p-[11px] border-t-1.5 border-b-1.5 border-solid border-4A4A4A">
          <CustomButton
            className="text-52A91A text-17/22"
            text="Feed"
            onClick={() => navigate(OFFERS_FEED, { offerId })}
          />
        </div>
        <div className="flex justify-center p-[11px]">
          <CustomButton
            className="text-52A91A text-17/22"
            text="Availability Grid"
            onClick={() => navigate(OFFERS_AVAILABILITY_GRID, { offerId })}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
