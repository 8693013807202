import { createSlice } from '@reduxjs/toolkit'

type ApiState = {
  loading: boolean
  loadingRequests: string[]
  successfulRequests: string[]
}

const initialState: ApiState = {
  loading: false,
  loadingRequests: [],
  successfulRequests: []
}

export const apiSlice = createSlice({
  name: 'api',
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      const result = action.payload
      state.loading = result
    },
    startLoading: (state, action) => {
      state.loadingRequests = Array.from(new Set([...state.loadingRequests, action.payload]))
    },
    finishLoading: (state, action) => {
      state.loadingRequests = [...state.loadingRequests.filter(request => request !== action.payload)]
    },
    addSuccessfulRequests: (state, action) => {
      state.successfulRequests = Array.from(new Set([...state.successfulRequests, action.payload]))
    },
    deleteSuccessfulRequests: (state, action) => {
      state.successfulRequests = [...state.successfulRequests.filter(request => request !== action.payload)]
    }
  }
})

export const {
  setLoading,
  startLoading,
  finishLoading,
  addSuccessfulRequests,
  deleteSuccessfulRequests
} = apiSlice.actions
export default apiSlice.reducer
