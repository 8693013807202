import React from 'react'
import { CustomButton } from '../../Common/CustomButton'

export const Finish: React.FC<{ course?: any, addCourse: () => void }> = ({ course, addCourse }) => {
  const { logo, name, city, state, url, is_private: isPrivate } = course || {}
  const type = isPrivate ? 'PR' : 'PU'

  return (
    <div className="w-full max-w-[629px] mt-[122px]">
      <CourseCard
        logo={logo}
        name={name}
        city={city}
        state={state}
        type={type}
        url={url}
      />
      <div className="w-full flex justify-between items-center mt-[105px]">
        <p className="text-808080 text-18/22 font-medium">
          Need to make any changes?
          <CustomButton text="Click here" className="ml-1 text-52A91A font-normal" />
        </p>
        <CustomButton text="Add Course" className="main-button h-10 w-[167px]" onClick={addCourse} />
      </div>
    </div>
  )
}

type CourseCardPropTypes = {
  logo: { url: string }
  name: string
  city: string,
  state: string
  url: string
  type: string
}

const CourseCard: React.FC<CourseCardPropTypes> = ({ logo, name, url, city, state, type }) => {
  return (
    <div className="flex ml-[33px]">
      {logo &&
        <div className="cursor-pointer bg-white h-[72px] w-[72px] mt-2 mr-5"
             style={{ filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25))' }}>
          <figure className="w-full h-full">
            <img src={logo.url} alt="" className="w-full h-full" />
          </figure>
        </div>
      }
      <div>
        <p className="text-4A4A4A text-22/26 font-semibold">{name}</p>
        <p className="text-4A4A4A text-20/24 font-medium flex items-center mb-1">
          <span className="opacity-80">{city}, {state}</span>
          <div className="flex w-3 justify-center">
            <span className="w-5px h-5px bg-52A91A rounded-full" />
          </div>
          <span className="text-15/18">
            {url}
          </span>
        </p>
        <div
          className="rounded border border-solid border-979797 text-979797 text-12/14 leading-[22px] w-[27px] text-center">
          {type}
        </div>
      </div>
    </div>
  )
}