import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'

import { ReactComponent as ArrowDown } from '../../../assets/svg/ArrowDown.svg'
import { ReactComponent as ArrowUp } from '../../../assets/svg/ArrowUp.svg'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { UseInfiniteScrollHookArgs } from 'react-infinite-scroll-hook/dist/useInfiniteScroll'
import _ from 'lodash'

type PropsType = {
  className?: string
  name: string
  text?: string
  options: any[]
  selectedOption: string | any
  setSelectedOption?: (option: string | any) => void
  isError?: FieldError
  optionsModalClass: string
  isHoverBg?: boolean
  isPaginator?: boolean
  register?: UseFormRegister<any>
  placeholder?: string
  optionClass?: string
  getId?: (option: any) => string
  getTitle?: (option: any) => number | string
  disabled?: boolean,
  infiniteScrollConfig?: UseInfiniteScrollHookArgs,
  forcedInputValue?: string,
  searchable?: boolean
  onSearch?: (search: string) => void
}

const OPTIONS_ON_PAGE = 50

export const GolfSelect: React.FC<PropsType> = (
  {
    className,
    name,
    text,
    options,
    selectedOption,
    setSelectedOption,
    isError,
    optionsModalClass,
    isHoverBg,
    isPaginator,
    register,
    placeholder,
    optionClass = 'py-3',
    getId,
    getTitle,
    forcedInputValue,
    disabled = false,
    searchable = false,
    onSearch,
    infiniteScrollConfig = {
      loading: false, hasNextPage: true, onLoadMore: () => {
      }
    }
  }) => {
  const cachedValues = useRef<Record<any, any>>({})
  const [isOpen, setOpen] = useState(false)
  const [displayedCount, setDisplayedCount] = useState<number>(OPTIONS_ON_PAGE)
  const selectRef = useRef(null)
  const inputRegister = register && { ...register(name, { required: true }) }
  const onLoadMore = () => {
    if (isOpen) {
      setDisplayedCount(displayedCount + OPTIONS_ON_PAGE)
      infiniteScrollConfig?.onLoadMore()
    }
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    ...infiniteScrollConfig,
    rootMargin: '0px 0px 600px 0px',
    onLoadMore
  })

  useEffect(() => {
    if (!isOpen) {
      setDisplayedCount(OPTIONS_ON_PAGE)
      onSearch && onSearch('')
    }
  }, [isOpen])

  const handleSelect = (option: string) => {
    return () => {
      setSelectedOption && setSelectedOption(option)
      setOpen(false)
    }
  }

  const handleOutsideClick = (e: MouseEvent) => {
    const path = (e as any).path || (e.composedPath && e.composedPath())
    if (!path.includes(selectRef.current)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick)
    return () => {
      document.body.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const selectedOptionObject = useMemo(() => {
    if (!(options && options[0] && options[0].id)) {
      return selectedOption
    }

    return options.find((option) => (getId && getId(option)) === selectedOption)
  }, [options, selectedOption])

  const originalInputValue =
    (getTitle ? getTitle(selectedOptionObject) : selectedOptionObject)

  useEffect(() => {
    if (originalInputValue) {
      const newValue = {...cachedValues.current}
      newValue[selectedOption] = originalInputValue

      cachedValues.current = newValue
    }
  }, [originalInputValue, selectedOption])

  const inputValue = originalInputValue || cachedValues.current[selectedOption] || forcedInputValue || placeholder


  const handleClick = () => {
    if (!disabled) {
      setOpen(!isOpen)
    }
  }

  const onInputChange = (e: any) => {
    onSearch && onSearch(e.target.value)
  }

  return (
    <div ref={selectRef}>
      <div className={`${text && 'mb-1'}`}>
          <span
            className={`text-15/18 text-333333 font-bold ${
              isError ? 'text-C43100' : 'text-333333'
            }`}
          >
            {text}
          </span>
      </div>
      <div
        onClick={handleClick}
        className={`w-full flex items-center cursor-pointer justify-between pl-2 pr-2 ${className} ${isError ? 'golfApp__formELements_error' : 'golfApp__formELements'} ${disabled ? 'disabled' : ''}`}
      >
        <span>{inputValue}</span>
        <div>{isOpen ? <ArrowUp /> : <ArrowDown />}</div>
      </div>
      {isOpen && (
        <div
          className={`golfSelectOptions absolute left-0 bottom-0 shadow-md w-full bg-white overflow-auto z-10 ${
            isHoverBg ? 'shadow-xl' : 'rounded-3px pb-2'
          } ${optionsModalClass}`}
          ref={rootRef}
        >
          {searchable &&
            <div className="w-full overflow-hidden pr-4">
              <input
                type="text"
                className="golfApp__formELements ml-3 pl-3 h-10"
                onChange={_.debounce(onInputChange, 300)}
              />
            </div>
          }
          {options.slice(0, displayedCount).map((option, index) => {
            const id = getId ? getId(option) : option
            const title = getTitle ? getTitle(option) : option
            return (
              <div key={index}>
                <input
                  onClick={handleSelect(id)}
                  {...inputRegister}
                  type="radio"
                  value={id}
                  className="hidden"
                  id={id}
                />
                <label
                  className={`${optionClass} block cursor-pointer ${
                    isHoverBg
                      ? 'hover:bg-52A91A hover:text-white text-333333 pl-2.5'
                      : 'text-333333 border-b border-solid border-979797 px-9px'
                  }`}
                  htmlFor={id}
                >
                  {isPaginator ? `Show ${title} per Page` : title}
                </label>
              </div>
            )
          })}
          {!infiniteScrollConfig?.loading && infiniteScrollConfig?.hasNextPage && <span ref={sentryRef} />}
        </div>
      )}
    </div>
  )
}
