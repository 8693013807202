import { createAsyncThunk } from '@reduxjs/toolkit'
import { sleep } from '../../utils/sleep'
import request, { setTokenHeader } from '../../utils/agent'
import { RootState } from '../store'
import { readFile } from '../../helpers/readFile'

export const sendResetPasswordEmail = createAsyncThunk('auth/reset/send',
  async (email: string) => {
    await request({
      method: 'POST',
      url: 'operator_portal/auth/password/reset/',
      data: { email }
    })

    return 'Email was sent'
  }
)

export const saveNewPassword = createAsyncThunk('auth/reset/confirm',
  async ({ token, password, uid }: { token: string, password: string, uid: string }) => {
    await request({
      method: 'POST',
      url: 'operator_portal/auth/password/reset/confirm/',
      data: { token, uid, new_password: password }
    })

    return 'Password saved'
  }
)

export const login = createAsyncThunk('auth/login',
  async (credentials: { email: string, password: string }, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/auth/login/',
        data: credentials
      })

      const token = response.data.token
      localStorage.setItem('authToken', token)
      setTokenHeader()
      return token
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }

  }
)

export const resendCode = createAsyncThunk(
  'auth/code/resend',
  async (data: any) => {
    await sleep(1000)
    return true
  }
)

export const startRegistration = createAsyncThunk(
  'auth/registration/start',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/auth/register/',
        data: { ...data, step: 1 }
      })

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }

  }
)

export const attachPhoneNumber = createAsyncThunk(
  'auth/registration/phone',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/auth/register/',
        data: { ...data, step: 3 }
      })
      return { ...response.data, mobile: data.phone_number }
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const confirmEmail = createAsyncThunk<any, any, { state: RootState }>(
  'auth/registration/confirm',
  async (code: string, { rejectWithValue, getState }) => {
    const { user } = getState()
    const { otp } = user
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/auth/register/',
        data: { id: otp?.pk, otp: code, step: 2 }
      })
      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const attachPassword = createAsyncThunk<any, any, { state: RootState }>(
  'auth/registration/password',
  async (password: string, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/auth/register/',
        data: { password, step: 3 }
      })
      const token = response.data.token
      localStorage.setItem('authToken', token)
      setTokenHeader()
      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const attachImages = createAsyncThunk<any, any, { state: RootState }>(
  'auth/registration/images',
  async (data: any, { rejectWithValue }) => {
    const { logo, photo } = data
    const logoBase64 = logo.image && await readFile(logo.image) as string
    const photoBase64 = photo.image ? await readFile(photo.image) as string : ''

    if (!(logoBase64 || photoBase64)) {
      return rejectWithValue({skipped: true})
    }

    const processedData = {
      logo: logoBase64 && { file_name: logo.name, data: logoBase64.replace('data:image/jpeg;base64,', '') },
      picture: photoBase64 ? {
        file_name: logo.name,
        data: photoBase64.replace('data:image/jpeg;base64,', '')
      } : undefined,
      step: 4
    }

    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/auth/register/',
        data: processedData
      })

      return { ...response.data, course: response.data?.active_course }
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const validation = createAsyncThunk<any, any, { state: RootState }>(
  'auth/registration/validate',
  async (data: any, { rejectWithValue }) => {
    const { dlPicture, businessCardPicture, linkedIn, businessColleagues } = data
    const dlPictureBase64 = await readFile(dlPicture.image) as string
    const businessCardPictureBase64 = businessCardPicture?.image ? await readFile(businessCardPicture.image) as string : ''

    const processedData = {
      dl_picture: dlPicture ? {
        file_name: dlPicture.name,
        data: dlPictureBase64.replace('data:image/jpeg;base64,', '')
      } : undefined,
      business_card: businessCardPicture ? {
        file_name: businessCardPicture.name,
        data: businessCardPictureBase64.replace('data:image/jpeg;base64,', '')
      } : undefined,
      linkedin_url: linkedIn,
      emails: businessColleagues
    }

    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/sign_up/identity_verify/',
        data: processedData
      })

      // setInLocalStorage('token', response.data.token)

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)