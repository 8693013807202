import { TeammateStatus } from './TeammateStatus'

export interface Teammate {
  id: number
  first_name: string
  last_name: string
  title: string
  email: string
  status?: TeammateStatus
  mobile: string
  type: TeammateType
}

export enum TeammateType {
  SECONDARY = 'Secondary',
  INITIAL = 'Initial'
}