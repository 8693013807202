import React from 'react'
import { CourseInternalPage } from './CourseInternalPage'
import { useTournaments } from '../../../hooks/course/useTournaments'
import { saveTournaments } from '../../../store/course/actions'
import { TournamentsForm } from './TournamentsForm'
import { useApi } from '../../../hooks/api/useApi'

const IS_READY = false

export const TournamentsPage: React.FC = () => {
  const tournaments = useTournaments()

  const [loading, save] = useApi(saveTournaments)

  const onFormSubmit = async (data: any) => {
    data.data.map(async (element: any) => {
      if (element.tournament_name !== '') {
        save(element)
      }
    })
  }

  if (!IS_READY) {
    return (
      <CourseInternalPage title="Course History">
        <div className="flex">
          <p className="text-52A91A text-200/240 opacity-0.15 text-center mt-40">COMING SOON</p>
        </div>
      </CourseInternalPage>
    )
  }

  return (
    <CourseInternalPage title="Tournaments">
      <TournamentsForm tournaments={tournaments} onSubmit={onFormSubmit} loading={loading} />
    </CourseInternalPage>
  )
}
