import React from 'react'

type PropsType = {
  firstName: string
  email: string
}

export const MobileCodeText: React.FC<PropsType> = ({ firstName, email }) => {
  return (
    <div className="relative pt-110px px-85px text-white">
      <h1 className="signUp__title mb-6">Well Done, {firstName}! Check your email.</h1>
      <p className="text-18/22 mb-6">
        We’ve sent a six-digit validation code to {email} {'('}
        <span className="text-6EB629 cursor-pointer">edit</span>
        {')'}
      </p>
      <p className="text-18/22">This code expires quickly, so enter it soon!</p>
    </div>
  )
}
