import React, { useEffect, useMemo } from 'react'
import { GolfInput } from '../Components/GolfInput'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from '../../Common/CustomButton'
import { InputLabel } from '../Components/small/InputLabel'
import { InputDescription } from '../Components/small/InputDescription'

type PropTypes = {
  invite?: any
  onSubmit: any
  loading: boolean
}

type Inputs = {
  email: string
  password: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Enter a valid email'),
  password: Yup.string().required('Password is required').min(6, 'Password should consist at least 6 characters')
})

export const AcceptInvitationForm: React.FC<PropTypes> = ({ invite, onSubmit, loading }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => invite || {}, [invite]),
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    reset(invite)
  }, [invite])

  return (
    <form className="w-full pt-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6">
        <p className="font-semibold text-14/20 sm:text-20/24 text-4A4A4A mb-3 tracking-tight">Username</p>
        <GolfInput
          label="Email Address*"
          name={'email'}
          htmlFor={'email'}
          register={register}
          isError={errors.email}
          disabled
        />
      </div>
      <div className="mb-6">
        <p className="font-semibold text-14/20 sm:text-20/24 text-4A4A4A mb-3 tracking-tight">Create Password</p>
        <div className="flex justify-between">
          <InputLabel text="Password*" className="mb-1 sm:mb-0" />
          <InputDescription text="6 character min" className="sm:leading-[30px] mb-1 sm:mb-0" />
        </div>
        <GolfInput
          name={'password'}
          htmlFor={'email'}
          register={register}
          isError={errors.password}
          errorTextClass="text-C43100 text-12/14"
          secure
        />
      </div>
      <div className="mt-10 flex justify-center sm:justify-end">
        <CustomButton
          className="py-3 sm:py-4 w-[255px] sm:w-[198px] text-white bg-52A91A font-semibold sm:font-bold text-16/19 sm:text-20/24"
          text="Submit"
          type="submit"
          loading={loading}
        />
      </div>
    </form>
  )
}