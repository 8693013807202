import React from 'react'

export const TermsOfConditions: React.FC = () => {
  return (
    <section className="terms__container">
      <article className="max-w-1140px w-full mx-auto pt-12">
        <h1 className="text-22/26 font-bold mb-7 text-center">Terms Or Condiions</h1>
        <p className="text-18/22 mb-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Aliquam id diam maecenas ultricies mi eget mauris
          pharetra. Euismod quis viverra nibh cras pulvinar mattis. Adipiscing vitae proin sagittis
          nisl rhoncus mattis rhoncus urna. Odio euismod lacinia at quis risus sed. Vel risus
          commodo viverra maecenas accumsan lacus vel facilisis. Eget duis at tellus at. Enim
          facilisis gravida neque convallis a. Mauris sit amet massa vitae tortor condimentum.
          Tempus egestas sed sed risus pretium quam. Potenti nullam ac tortor vitae purus faucibus
          ornare suspendisse. Felis bibendum ut tristique et egestas quis. Senectus et netus et
          malesuada fames ac turpis egestas sed. Sit amet aliquam id diam maecenas ultricies mi
          eget. Ut faucibus pulvinar elementum integer enim neque volutpat ac. Nulla porttitor massa
          id neque aliquam. Curabitur vitae nunc sed velit dignissim sodales ut eu sem. Orci ac
          auctor augue mauris augue neque gravida.
        </p>
        <p className="text-18/22 mb-5">
          Morbi tempus iaculis urna id. At tempor commodo ullamcorper a lacus vestibulum sed arcu
          non. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien. In hac habitasse
          platea dictumst. Tortor at risus viverra adipiscing. Odio facilisis mauris sit amet massa
          vitae tortor condimentum lacinia. Augue neque gravida in fermentum et sollicitudin ac
          orci. Sed odio morbi quis commodo odio aenean. Vulputate odio ut enim blandit volutpat
          maecenas volutpat. Enim diam vulputate ut pharetra sit. Ultrices eros in cursus turpis
          massa. Consectetur adipiscing elit pellentesque habitant morbi tristique. Tempus iaculis
          urna id volutpat lacus laoreet. Diam donec adipiscing tristique risus nec feugiat in
          fermentum. Sed id semper risus in hendrerit gravida rutrum quisque. Hac habitasse platea
          dictumst vestibulum. Consequat id porta nibh venenatis.
        </p>
        <p>
          Urna porttitor rhoncus dolor purus non. Ut enim blandit volutpat maecenas volutpat blandit
          aliquam etiam erat. Potenti nullam ac tortor vitae purus faucibus. Nunc sed blandit libero
          volutpat sed cras ornare. Et ultrices neque ornare aenean euismod elementum nisi quis.
          Elementum nibh tellus molestie nunc non blandit massa. Vestibulum morbi blandit cursus
          risus. Urna molestie at elementum eu facilisis sed odio morbi.
        </p>
      </article>
    </section>
  )
}
