import React, { memo } from 'react'

import { ReactComponent as ArrowDown } from '../../../assets/svg/tableArrow.svg'
import { TableAction } from '../../../models/TableAction'
import { TableConfig } from '../../../models/TableConfig'

type PropsType = {
  className: string
  columnsArr: TableConfig[]
  activeArrow: {
    index: number
    active: boolean
  }
  setActiveArrow: (index: { index: number; active: boolean }) => void
  actions?: TableAction[]
  sorterable: boolean
}

export const TableHeader: React.FC<PropsType> = memo(
  ({ className, columnsArr, activeArrow, setActiveArrow, actions, sorterable }) => {
    const handleColumnClick = (index: number) => {
      return () => setActiveArrow({ index: index, active: !activeArrow.active })
    }

    return (
      <div className={`flex justify-between ${className}`}>
        {columnsArr.map(({ name, width, classNames }, index) => {
          const isArrowActive = index === activeArrow.index && activeArrow.active
          return (
            <div
              className={`${classNames?.all} ${classNames?.head} first:pl-5 py-1 ${sorterable ? 'cursor-pointer' : ''} w-full ${width}`}
              onClick={handleColumnClick(index)}
              key={name}
            >
              <span className="inline-block pr-3">{name}</span>
              {sorterable && <span>
               {isArrowActive ? (
                 <ArrowDown className={`inline-block rotate180`} />
               ) : (
                 <ArrowDown className={`inline-block`} />
               )}
              </span>}

            </div>
          )
        })}
        <div className="pr-4">
          {actions &&
            actions.map(({ className }, index) => {
              return <div className={className} key={index} />
            })}
        </div>
      </div>
    )
  }
)
