import { readFile } from './readFile'

export const onImgDragAndDown = async (
  files: any,
  setLogoImg: (logo: unknown, name?: string) => void
) => {
  if (files && files.length > 0) {
    const file = files[0]
    let imageDataUrl = await readFile(file)
    const data = { name: file.name, url: imageDataUrl }
      setLogoImg(data)
  }
}
