import { InputLabel } from '../../Components/small/InputLabel'
import { ReactComponent as ArrowUp } from '../../../../assets/svg/ArrowUp.svg'
import { ReactComponent as ArrowDown } from '../../../../assets/svg/ArrowDown.svg'
import React from 'react'

type HeaderTypes = {
  isOpen: boolean
  value: string
  disabled?: boolean
}

export const Header: React.FC<HeaderTypes> = ({ isOpen, value, disabled = false }) => {
  return (
    <div className="w-full">
      <InputLabel text="Select Offer*" />
      <div
        className={`w-full flex items-center cursor-pointer h-10 justify-between pl-2 pr-2 golfApp__formELements ${disabled ? 'disabled' : ''}`}>
        <span>{value}</span>
        <div>{isOpen ? <ArrowUp /> : <ArrowDown />}</div>
      </div>
    </div>
  )
}
