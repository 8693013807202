import React, { memo } from 'react'

type PropsType = {
  leftBottomText?: string
  maxTextLength: number
  messageLength: number
}

export const TextAreaBottomCharsCount: React.FC<PropsType> = memo(
  ({ leftBottomText, messageLength, maxTextLength }) => {
    return (
      <div className="flex justify-between">
        <span className="inline-block text-right text-15/18 text-333333 opacity-50">
          {leftBottomText}
        </span>
        <div>
          <span className="inline-block text-right text-15/18 text-333333 opacity-50">
            {messageLength}
          </span>
          <span className="inline-block text-right text-15/18 text-333333 opacity-50">
            /{maxTextLength}
          </span>
        </div>
      </div>
    )
  }
)
