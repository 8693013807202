import React from 'react'
import { formatId } from '../../helpers/id'

type PropsType = {
  className?: string
  name: string
  id: string
}

export const IdContainer: React.FC<PropsType> = ({ className, name, id }) => {
  return (
    <div className={`rounded-sm w-56 h-58px text-15/18 overflow-hidden ${className}`}>
      <div className="flex justify-center items-center font-bold text-white bg-81A46A h-1/2">
        <span>{name} ID#</span>
      </div>
      <div
        className="flex justify-center items-center text-333333/50 font-bolder h-1/2 border border-52A91A border-solid border-t-0">
        {formatId(id)}
      </div>
    </div>
  )
}
