import React from 'react'
import { UserDropdown } from './UserDropdown'
import Logo from '../../../../assets/svg/logo.svg'
import { Link } from 'react-router-dom'
import { LOGIN } from '../../../../constants/urls'
import { CustomButton } from '../../../Common/CustomButton'
import { User } from '../../../../models/User'
import { useScreenSize } from '../../../../hooks/useScreenSize'

type PropTypes = {
  user: User
  loading: boolean
  disabledDropdown?: boolean
}

export const GolfAppHeader: React.FC<PropTypes> = (props) => {
  const screenSize = useScreenSize()

  if (screenSize === 'sm') {
    return <MobileHeader {...props} />
  }

  return <DesktopHeader {...props} />
}

const DesktopHeader: React.FC<PropTypes> = ({ user, loading, disabledDropdown = false }) => {
  const course = user?.course || (loading ? {} : { logo: Logo, title: 'Operator Portal' })

  return (
    <header className="relative py-4 pl-17px pr-9 flex justify-between items-center mb-5px">
      <div className="bg-81A46A w-full h-5px absolute left-0 -bottom-5px" />
      <div className="flex items-center">
        <figure className="relative">
          <img src={course.logo} alt="" className="h-8" />
        </figure>
        <h1 className="text-20/24 text-333333 ml-4">{course.title}</h1>
      </div>
      <div className="h-5">
        {user ?
          <UserDropdown user={user} disabled={disabledDropdown} /> :
          (
            loading ? <></> :
              <Link to={LOGIN}>
                <CustomButton
                  className="bg-52A91A text-white text-12/14 font-bold px-5 py-1.5"
                  text={'Login'}
                />
              </Link>
          )
        }
      </div>
    </header>
  )
}

const MobileHeader: React.FC<PropTypes> = ({ user, loading }) => {
  const course = user?.course || (loading ? {} : { logo: Logo, title: 'Operator Portal' })

  return (
    <header className="">
      <div
        className="w-full border-b-[3px] border-solid border-81A46A flex justify-center items-center h-[60px] w-full">
        <figure className="relative">
          <img src={course.logo} alt="" className="h-8" />
        </figure>
        <h1 className="text-20/24 text-333333 ml-4">{course.title}</h1>
      </div>
      <div className="w-full flex justify-end p-[10px]">
        {!!user && <UserDropdown user={user} disabled />}
      </div>
    </header>
  )
}