import React from 'react'
import { CustomButton } from '../../Common/CustomButton'
import Info from '../../../assets/svg/info.svg'
import Crosses from '../../../assets/svg/crosses-small.svg'

type PropsType = {
  isOpen: boolean
  onClose: () => void
  onConfirmed: () => void
  loading: boolean
}

export const DeleteTeammateModal: React.FC<PropsType> = ({ isOpen, onClose, onConfirmed, loading }) => {

  if (!isOpen) {
    return <></>
  }

  return (
    <div className="absolute w-full h-full top-0 left-0">
      <div className="modal">
        <div className="flex justify-between p-2">
          <img src={Info} alt="" />
          <img src={Crosses} alt="" className="cursor-pointer" onClick={onClose} />
        </div>
        <div className="max-w-294px w-full pt-4 px-7 pb-6 text-center">
          <p className="text-17/22 font-sfui font-medium text-center">
            Are You Sure You Want To Remove This Person?
          </p>
          <p className="text-13/15 font-sfui mt-2">
            This action cannot be undone.
          </p>
        </div>
        <div className="flex justify-center p-[11px] border-t-1.5 border-solid border-4A4A4A">
          <CustomButton
            className="text-C43100 text-18/22 text font-sfui"
            text="Remove"
            onClick={onConfirmed}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}