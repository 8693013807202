import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import { CustomButton } from '../../Common/CustomButton'

import { ReactComponent as Crosses } from '../../../assets/svg/closeDatePickerCrosses.svg'
import { GreySquareButtonRight } from './small/greySquareButtonRight'
import { GreySquareButtonLeft } from './small/greySquareButtonLeft'
import format from 'date-fns/format'

type PropsType = {
  value: { start: string; end: string }
  onChange: (date: { start: string; end: string }) => void
  setDatePickerVisible: (isVisible: boolean) => void
  className?: string
  firstDateLabel: string
  secondDateLabel: string
  position?: 'top' | 'bottom'
  disabledSecondDate?: boolean
}

export const DoubleDatePicker: React.FC<PropsType> =
  ({
     setDatePickerVisible,
     onChange,
     className,
     firstDateLabel,
     secondDateLabel,
     value,
     position,
     disabledSecondDate
   }) => {
    const [calendarDate, setCalendarDate] = useState<any>({ start: new Date(), end: new Date() })

    useEffect(() => {
      value && setCalendarDate({ start: new Date(value.start), end: new Date(value.end) })
    }, [value])

    const onAccept = () => {
      onChange({
        start: format(calendarDate.start as number | Date, 'LLL dd,yyyy'),
        end: format(calendarDate.end as number | Date, 'LLL dd,yyyy')
      })
      setDatePickerVisible(false)
    }

    return (
      <div className={`doubleDatePicker w-max bg-white ${className} ${position}`}>
        <div className="datePicker__left bg-white relative">
          <span className="absolute alignCenter top-5 z-10 text-20/24 text-333333">
            {firstDateLabel}
          </span>
          <GreySquareButtonLeft className="absolute z-10 top-70px left-60px" />
          <GreySquareButtonRight className="absolute z-10 top-70px right-60px" />
          <DatePicker
            name="1"
            selected={calendarDate.start}
            onChange={(date) => setCalendarDate({ ...calendarDate, start: date })}
            shouldCloseOnSelect={false}
            showDisabledMonthNavigation
            inline
          />
        </div>
        <div className="datePicker__right relative">
          <span className="absolute alignCenter top-5 z-10 text-20/24 text-333333">
            {secondDateLabel}
          </span>
          <GreySquareButtonLeft className="absolute z-10 top-70px left-60px" />
          <GreySquareButtonRight className="absolute z-10 top-70px right-60px" />
          <DatePicker
            name="2"
            selected={calendarDate.end}
            onChange={(date) => setCalendarDate({ ...calendarDate, end: date })}
            shouldCloseOnSelect={false}
            showDisabledMonthNavigation
            inline
            readOnly={true}
          />
          {disabledSecondDate &&
            <div className="opacity-30 w-full h-4/6 absolute left-0 bottom-12 top-14 z-50 bg-white" />
          }
        </div>
        <Crosses
          className="absolute z-10 top-5 right-5 cursor-pointer"
          onClick={() => setDatePickerVisible(false)}
        />
        <div className="absolute bottom-4 w-full right-4">
          <div className="flex justify-end">
            <CustomButton
              className="bg-52A91A w-120px text-center py-1.5 text-15/18 text-white font-bold"
              text="Accept"
              onClick={onAccept}
            />
          </div>
        </div>
      </div>
    )
  }
