import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'

import { SignUpLayout } from './components/Layouts/SignUpLayout'
import { LoginLayout } from './components/Layouts/LoginLayout'
import { LoginPage } from './components/UserWorkflowApp/LoginPage/Login'
import { PasswordResetPage } from './components/UserWorkflowApp/PasswordResetPage/PasswordResetPage'
import { SignUpProcess } from './components/UserWorkflowApp/SignUpPages/SignUpProcess'
import { TermsOfConditions } from './components/Pages/TermsPage/TermsOfConditions'
import { GolfAppLayout } from './components/Layouts/GolfAppLayout'
import { HowItWorksPage } from './components/GolfApp/HowItWorksPage/HowItWorksPage'
import { Passbook } from './components/GolfApp/PassbookPage/Passbook'
import { MyCoursePage } from './components/GolfApp/MyCourseBlock/MyCoursePage/MyCoursePage'
import { CoursePhotosPage } from './components/GolfApp/MyCourseBlock/CoursePhotoPage/CoursePhotosPage'
import { CourseHistoryPage } from './components/GolfApp/MyCourseBlock/CourseHistoryPage'
import { TournamentsPage } from './components/GolfApp/MyCourseBlock/TournamentsPage'
import { EventsPage } from './components/GolfApp/EventsBlock/EventsPage'
import { NewEventPage } from './components/GolfApp/EventsBlock/NewEventPage'
import { BinPage } from './components/GolfApp/BinPage/BinPage'
import { OffersPage } from './components/GolfApp/OffersBlock/OffersPage'
import { NewTeammatesPage } from './components/GolfApp/MyTeamBlock/NewTeammatesPage'
import { TeammatesPage } from './components/GolfApp/MyTeamBlock/TeammatesPage'

import {
  ACCEPT_INVITE, ACCEPTED_INVITE,
  ADD_COURSE,
  ADD_NEW_TEAMMATES,
  BIN, EDIT_AVAILABILITY_GRID,
  EDIT_EVENT, EDIT_FEED_OFFER,
  EDIT_OFFER, EDIT_TEAMMATE,
  EVENTS,
  GROUPS_AND_ASSOCIATIONS,
  HOW_IT_WORKS,
  LOGGED_OUT,
  LOGIN,
  MY_COURSE,
  MY_COURSE_HISTORY,
  MY_COURSE_PHOTOS,
  MY_COURSE_TOURNAMENTS,
  MY_PROFILE,
  MY_TEAM,
  NEW_EVENT,
  NEW_OFFER,
  NEW_PASSWORD, NOT_APPROVED,
  OFFERS,
  OFFERS_AVAILABILITY_GRID,
  OFFERS_FEED,
  PASSBOOK, PROMOTE_MY_COURSE,
  RESET_PASSWORD,
  SIGN_UP,
  TERMS_OF_CONDITION
} from './constants/urls'
import { NewOfferPage } from './components/GolfApp/OffersBlock/NewOfferPage'
import { EditOfferPage } from './components/GolfApp/OffersBlock/EditOfferPage'
import { EditEventPage } from './components/GolfApp/EventsBlock/EditEventPage'
import { NewPasswordPage } from './components/UserWorkflowApp/PasswordResetPage/NewPasswordPage'
import { AcceptInvitationPage } from './components/Pages/AcceptInvitationPage'
import { OffersFeedPage } from './components/GolfApp/OffersBlock/OffersFeedPage'
import { LoggedOutPage } from './components/GolfApp/LoggedOutPage'
import { ProfilePage } from './components/GolfApp/ProfilePage'
import { AddCoursePage } from './components/GolfApp/AddCoursePage'
import { GroupsPage } from './components/GolfApp/GroupsPage'
import { OffersAvailabilityGridPage } from './components/GolfApp/OffersBlock/OffersAvailabilityGridPage'
import { AcceptedInvitationPage } from './components/Pages/AcceptedInvitationPage'
import { EditTeammatePage } from './components/GolfApp/MyTeamBlock/EditTeammatePage'
import { PromoteMyCoursePage } from './components/GolfApp/PromoteMyCoursePage'
import { EditFeedOfferPage } from './components/GolfApp/OffersBlock/OffersFeedPage/EditFeedOfferPage'
import {
  EditAvailabilityGridOfferPage
} from './components/GolfApp/OffersBlock/OffersAvailabilityGridPage/EditAvailabilityGridOfferPage'
import { NotApprovedPage } from "./components/UserWorkflowApp/SignUpPages/NotApprovedPage";

export const AppRoute: React.FC = () => {


  return (
    <Router>
      <AppRoutes/>
    </Router>
  )
}

const AppRoutes: React.FC = () => {
  const [isFullScreenBg, setFullScreenBg] = useState(false)
  const [isInitialRoute, setInitialRoute] = useState(true)
  const location = useLocation()

  useEffect(() => {
    return () => setInitialRoute(false)
  }, [location])

  return (
    <>
      <Routes>
        <Route element={<GolfAppLayout isFullScreenBg={isFullScreenBg} isInitialRoute={isInitialRoute} />}>
          <Route path={HOW_IT_WORKS} element={<HowItWorksPage />} />
          <Route path={OFFERS} element={<OffersPage />} />
          <Route path={NEW_OFFER} element={<NewOfferPage />} />
          <Route path={EDIT_OFFER} element={<EditOfferPage />} />
          <Route path={PASSBOOK} element={<Passbook />} />
          <Route path={EVENTS} element={<EventsPage />} />
          <Route path={PROMOTE_MY_COURSE} element={<PromoteMyCoursePage />} />
          <Route path={NEW_EVENT} element={<NewEventPage />} />
          <Route path={EDIT_EVENT} element={<EditEventPage />} />
          <Route path={BIN} element={<BinPage />} />
          <Route path={GROUPS_AND_ASSOCIATIONS} element={<GroupsPage />} />
          <Route>
            <Route path={MY_COURSE_PHOTOS} element={<CoursePhotosPage setFullScreenBg={setFullScreenBg} />} />
            <Route path={MY_COURSE} element={<MyCoursePage />} />
            <Route path={MY_COURSE_HISTORY} element={<CourseHistoryPage />} />
            <Route path={MY_COURSE_TOURNAMENTS} element={<TournamentsPage />} />
          </Route>
          <Route path={OFFERS_AVAILABILITY_GRID} element={<OffersAvailabilityGridPage />} />
          <Route path={OFFERS_FEED} element={<OffersFeedPage />} />
          <Route path={EDIT_FEED_OFFER} element={<EditFeedOfferPage />} />
          <Route path={EDIT_AVAILABILITY_GRID} element={<EditAvailabilityGridOfferPage />} />
          <Route path={MY_TEAM} element={<TeammatesPage />} />
          <Route path={ADD_NEW_TEAMMATES} element={<NewTeammatesPage />} />
          <Route path={EDIT_TEAMMATE} element={<EditTeammatePage />} />
          <Route path={LOGGED_OUT} element={<LoggedOutPage />} />
          <Route path={MY_PROFILE} element={<ProfilePage />} />
          <Route path={ADD_COURSE} element={<AddCoursePage />} />
        </Route>
      </Routes>
      <Routes>
        <Route element={<LoginLayout />}>
          <Route index element={<LoginPage />} />
          <Route path={LOGIN} element={<LoginPage />} />
          <Route path={RESET_PASSWORD} element={<PasswordResetPage />} />
          <Route path={NEW_PASSWORD} element={<NewPasswordPage />} />
        </Route>
      </Routes>
      <Routes>
        <Route element={<SignUpLayout isFullScreenBg={isFullScreenBg} />}>
          <Route path={SIGN_UP} element={<SignUpProcess setFullScreenBg={setFullScreenBg} />} />
          <Route path={NOT_APPROVED} element={<NotApprovedPage />} />
          <Route path={TERMS_OF_CONDITION} element={<TermsOfConditions />} />
        </Route>
      </Routes>
      <Routes>
        <Route element={<AcceptInvitationPage />} path={ACCEPT_INVITE} />
        <Route element={<AcceptedInvitationPage />} path={ACCEPTED_INVITE} />
      </Routes></>
  )
}