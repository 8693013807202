import React from 'react'
import { CustomButton } from './CustomButton'

type ActionType = {
  text: string
  onClick: () => void
  className: string
  loading?: boolean
}

type PropTypes = {
  opened: boolean
  reject: ActionType
  submit: ActionType
  title: string
  description: string
}

export const ConfirmDialog: React.FC<PropTypes> = ({
                                                     opened,
                                                     reject,
                                                     submit,
                                                     title,
                                                     description
                                                   }) => {
  return opened ? (
    <div className="absolute w-full h-full top-0 left-0">
      <div className="modal">
        <div className="border-b-1.5 border-solid border-4A4A4A">
          <div className="max-w-294px w-full pt-8 px-7 pb-6 text-center">
            <p className="text-17/22 font-sfui font-medium mb-2">{title}</p>
            <p className="text-13/15">{description}</p>
          </div>
        </div>
        <div className="flex">
          <div className="border-r border-solid border-898A8D p-3 flex-1 flex justify-center text-17/22">
            <CustomButton {...reject} />
          </div>
          <div className="p-3 flex-1 flex justify-center text-17/22">
            <CustomButton {...submit} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
