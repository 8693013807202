import React, { useMemo, useState } from 'react'

import { CustomButton } from '../../Common/CustomButton'
import { CustomTable } from '../Components/CustomTable'

import { teammatesTableConfig } from './configs'
import { Page } from '../Components/Page'
import { ADD_NEW_TEAMMATES, EDIT_TEAMMATE, PROMOTE_MY_COURSE } from '../../../constants/urls'
import { useApiResource } from '../../../hooks/api/useApiResource'
import { getTeammates, removeTeammate, resendTeammateInvite } from '../../../store/course/actions'
import { Teammate, TeammateType } from '../../../models/Teammate'
import { ActionButton } from '../TeammatesPage/ActionButton'
import { Link, useNavigate } from 'react-router-dom'
import { DefaultTableStyles, TableStyles } from '../../../models/TableStyles'
import { getTeammatesColumnStyles } from '../../../helpers/returnColumnsStyles'
import { useOutletContext } from '../../Common/CustomContext'
import { canEdit, canRemove } from '../../../helpers/teammate'
import { DeleteTeammateModal } from './DeleteTeammateModal'
import { useApi } from '../../../hooks/api/useApi'
import { SetupProgress } from '../Components/SetupProgress'
import GolfHole from '../../../assets/img/golfHole.png'
import { ResendInviteModal } from './ResendInviteModal'

const styles: TableStyles = {
  ...DefaultTableStyles,
  getColumnClasses: getTeammatesColumnStyles,
  rowClasses: 'shadow mb-2.5 border border-solid border-EEF1EB rounded-5px'
}

export const TeammatesPage: React.FC = () => {
  const [teammatesLoading, teammates] = useApiResource('course', 'teammates', getTeammates)
  const [loading, deleteTeammate] = useApi(removeTeammate)
  const [resendLoading, resendInvite] = useApi(resendTeammateInvite)
  const [activeRowId, setActiveRowId] = useState<number>()
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)
  const [isResendModal, setIsResendModal] = useState<boolean>(false)
  const [isResendSuccess, setIsResendSuccess] = useState<boolean>(false)
  const navigate = useNavigate()
  const { user } = useOutletContext()
  const actions = useMemo(() => [
    {
      component: (teammate: Teammate) => {
        const edit = () => {
          navigate(EDIT_TEAMMATE.replace(':id', String(teammate.id)))
        }
        const remove = () => {
          setIsDeleteModal(true)
        }
        const resend = () => {
          setIsResendModal(true)
          setIsResendSuccess(false)
        }

        return (
          <ActionButton
            onResend={resend}
            onRemove={remove}
            onEdit={edit}
            onOpen={() => setActiveRowId(teammate.id)}
            onClose={() => setActiveRowId(undefined)}
            canResend={teammate.status === 'Pending'}
            canRemove={canRemove(teammate)}
            canEdit={canEdit(teammate, user)}
          />
        )
      },
      className: 'w-5'
    }
  ], [teammates])

  const closeDeleteModal = () => {
    setIsDeleteModal(false)
    setActiveRowId(undefined)
  }

  const finishTeammateDeleting = () => {
    deleteTeammate(activeRowId)
    .then(() => {
      closeDeleteModal()
    })
  }

  const closeResendModal = () => {
    setIsResendModal(false)
    setActiveRowId(undefined)
  }

  const finishResending = () => {
    resendInvite(activeRowId)
    .then(() => {
      setIsResendSuccess(true)
    })
  }

  const isSecondaryTeammatesExists = useMemo(() => {
    const secondaryTeammates = teammates?.filter(({ type }: Teammate) => type === TeammateType.SECONDARY)
    return !!secondaryTeammates && secondaryTeammates?.length > 0
  }, [teammates])

  return (
    <Page title="Teammates" relative>
      <div className="flex justify-end absolute right-[30px] top-5">
        <SetupProgress />
      </div>
      <p className="text-18/22 text-4A4A4A opacity-80 mt-4 mb-5">
        Add all teammates who can access the portal to manage events at your course.
      </p>
      <div className="flex justify-end mb-2">
        <CustomButton
          className="mb-1 text-16/16 text-52A91A font-semibold"
          text="+ Add More"
          link={ADD_NEW_TEAMMATES}
        />
      </div>
      <CustomTable
        config={teammatesTableConfig}
        data={teammates || []}
        actions={actions}
        styles={styles}
        selectedItemId={activeRowId}
        sorterable
      />
      {
        !teammatesLoading && (
          <>
            <div className="w-full flex justify-end items-center mt-3">
              {
                isSecondaryTeammatesExists ?
                  <CustomButton
                    className="h-14 w-[213px] bg-52A91A text-20/24 leading-[56px] font-bold text-white text-center mt-5"
                    text="Promote My Course"
                    link={PROMOTE_MY_COURSE}
                  /> :
                  <CustomButton
                    className="h-6 leading-6 text-base text-4A4A4A opacity-50"
                    text="Skip This Step"
                    link={PROMOTE_MY_COURSE} />
              }
            </div>
            {!isSecondaryTeammatesExists && <EmptyPlaceholder />}
          </>
        )
      }
      <DeleteTeammateModal
        isOpen={isDeleteModal}
        onClose={closeDeleteModal}
        onConfirmed={finishTeammateDeleting}
        loading={loading}
      />
      <ResendInviteModal
        isOpen={isResendModal}
        onClose={closeResendModal}
        onConfirmed={finishResending}
        loading={resendLoading}
        isSuccess={isResendSuccess}
      />
    </Page>
  )
}

const EmptyPlaceholder: React.FC = () => {
  return (
    <div className="flex flex-col items-center mt-14">
      <img src={GolfHole} alt="img" />
      <div className="text-center mt-14">
        <span className="text-4A4A4A text-18/22 text-center opacity-40">
          There are no Teammates here yet.{' '}
        </span>
        <span className="mb-1 text-18/22 text-52A91A font-semibold">
          <Link to={ADD_NEW_TEAMMATES}>+Add Teammate</Link>
        </span>
      </div>
    </div>
  )
}