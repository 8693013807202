import React, { useEffect } from 'react'
import MainBg from '../../../assets/img/SignUpBg.png'
import { Congratulations } from "../../Pages/CongratsPage/Congratulations";
import { useApiResource } from "../../../hooks/api/useApiResource";
import { getMe } from "../../../store/user/actions";
import { useNavigate } from "react-router-dom";
import { HOW_IT_WORKS } from "../../../constants/urls";

type PropsType = {
}

export const NotApprovedPage: React.FC<PropsType> = ( ) => {
  const [_, user] = useApiResource('user', 'me', getMe, false, false)
  const navigate = useNavigate()
  useEffect(() => {
    if (user?.is_approved) {
      navigate(HOW_IT_WORKS)
    }
  }, [user])

  return (
    <section
      style={{
        background: `url(${MainBg})`,
        backgroundSize: 'cover',
        display: 'flex',
        position: 'relative',
        marginBottom: '12px',
        height: '580px'
      }}
    >
      <div className="relative w-full">
        <div className="bg-white absolute left-0 top-0 w-full h-full opacity-90" />
        <Congratulations user={user || {}} />
      </div>
      <div className="absolute left-0 -bottom-3 bg-81A46A w-full h-3" />
    </section>
  )
}
