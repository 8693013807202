import { createSlice } from '@reduxjs/toolkit'
import { Architect, CourseHistory, Tournament } from '../../models/CourseHistory'
import { City, Course, State } from '../../models/Course'
import {
  editTeammate,
  getCourse,
  getHistory,
  getPhotos,
  getTeammates,
  getTournaments,
  removeTeammate,
  saveTeammate,
  saveTournaments,
  updateCourse,
  updateHistory,
  updatePhotos
} from './actions'
import { Teammate, TeammateType } from '../../models/Teammate'

type CourseState = {
  history?: CourseHistory
  architects?: Architect[]
  cities?: City[]
  states?: State[]
  course?: Course
  photos?: any
  teammates?: any[]
  tournaments?: Tournament[]
}

const initialState: CourseState = {
  history: undefined,
  architects: undefined,
  cities: undefined,
  states: undefined,
  course: undefined,
  photos: undefined,
  teammates: undefined,
  tournaments: undefined
}

export const courseSlice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
    .addCase(updateHistory.fulfilled, (state, action) => {
      state.history = action.payload
    })
    .addCase(getHistory.fulfilled, (state, action) => {
      state.history = action.payload
    })
    .addCase(getCourse.fulfilled, (state, action) => {
      state.course = action.payload
    })
    .addCase(updateCourse.fulfilled, (state, action) => {
      state.course = action.payload
    })
    .addCase(getPhotos.fulfilled, (state, action) => {
      state.photos = action.payload
    })
    .addCase(updatePhotos.fulfilled, (state, action) => {
      state.photos = action.payload
    })
    .addCase(getTournaments.fulfilled, (state, action) => {
      state.tournaments = action.payload
    })
    .addCase(saveTournaments.fulfilled, (state, action) => {
      state.tournaments = action.payload
    })
    .addCase(saveTeammate.fulfilled, (state, action) => {
      state.teammates = [...(state.teammates || []), action.payload as Teammate]
    })
    .addCase(removeTeammate.fulfilled, (state, action) => {
      const teammateId = action.payload as unknown as number
      const teammates = state.teammates || []
      const teammateIndex = teammates.findIndex(({ id }: Teammate) => id === teammateId)
      state.teammates = [
        ...teammates.slice(0, teammateIndex),
        ...teammates.slice(teammateIndex + 1, teammates.length)
      ]
    })
    .addCase(editTeammate.fulfilled, (state, action) => {
      const teammate = action.payload as Teammate
      const teammates = state.teammates || []
      const teammateIndex = teammates.findIndex(({ id }: Teammate) => id === teammate.id)
      const prevTeammate = teammates[teammateIndex]
      state.teammates = [
        ...teammates.slice(0, teammateIndex),
        { ...prevTeammate, ...teammate },
        ...teammates.slice(teammateIndex + 1, teammates.length)
      ]
    })
    .addCase(getTeammates.fulfilled, (state, action) => {
      const teammates = action.payload as Teammate[]
      const sortedTeammates = teammates.sort((a: Teammate, b: Teammate) => {
        if (a.type === b.type) {
          return 0
        }
        if (a.type === TeammateType.INITIAL) {
          return -1
        }
        if (b.type === TeammateType.INITIAL) {
          return 1
        }
        return 0
      })
      state.teammates = sortedTeammates
    })
  }
})

export default courseSlice.reducer
