import React from 'react'
import { Outlet } from 'react-router-dom'

import { Footer } from './Footer'
import { Header } from './Header'

import MainBg from '../../assets/img/SignUpBg.png'

type PropsType = {
  isLinkToLogin?: boolean
}

export const LoginLayout: React.FC<PropsType> = ({ isLinkToLogin }) => {
  return (
    <>
      <Header isLoginLayout={true} />
      <main className="width__container">
        <section
          style={{
            background: `url(${MainBg})`,
            backgroundSize: 'cover',
            position: 'relative',
            marginBottom: '12px',
            height: '540px'
          }}
        >
          <div className="bg-black absolute left-0 top-0 w-full h-full opacity-50" />
          <div className="absolute left-0 -bottom-3 bg-81A46A w-full h-3" />
          <span className="absolute right-5 bottom-3 text-base text-white opacity-60 font-bold">
            Prairie Dunes Golf Club
          </span>
          <Outlet />
        </section>
      </main>
      <Footer />
    </>
  )
}
