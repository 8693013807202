import { FeedOffer, Offer } from '../../models/Offer'
import { createAsyncThunk } from '@reduxjs/toolkit'
import request from '../../utils/agent'
import { fromOfferDto, fromOfferDtos, getFeedOfferStatus, getOfferStatus, toOfferDto } from '../../helpers/offer'
import { OfferStatus } from '../../models/OfferStatus'

export const getOffers = createAsyncThunk(
  'offers/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/offer/'
      })
      return fromOfferDtos(response.data.results)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getFeedOffers = createAsyncThunk(
  'feed_offers/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/feed_offer/'
      })
      return fromOfferDtos(response.data.results)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getGridOffers = createAsyncThunk(
  'grid_offers/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/availability_grid_feed/'
      })
      return fromOfferDtos(response.data.results)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const editOffer = createAsyncThunk(
  'offers/edit',
  async (offer: Offer, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'PUT',
        url: `operator_portal/offer/${offer.id}/`,
        data: await toOfferDto(offer)
      })

      return fromOfferDto(response.data)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const createOffer = createAsyncThunk(
  'offers/create',
  async (offer: Offer, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: `operator_portal/offer/`,
        data: await toOfferDto(offer)
      })

      return fromOfferDto(response.data)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const deleteOffer = createAsyncThunk(
  'offers/delete',
  async (offerId: string | number, { rejectWithValue }) => {
    try {
      await request({
        method: 'DELETE',
        url: `operator_portal/offer/${offerId}/`
      })

      return offerId
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const updateOfferStatus = createAsyncThunk(
  'offers/status/update',
  async ({ offer, suspended }: { offer: Offer, suspended: boolean }, { rejectWithValue }) => {

    let status: OfferStatus

    if (suspended) {
      status = OfferStatus.UNPUBLISHED
    } else {
      status = getOfferStatus(offer)
    }

    try {
      const response = await request({
        method: 'PATCH',
        url: `operator_portal/offer/${offer.id}/`,
        data: { status }
      })

      return fromOfferDto(response.data)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const publishOfferToFeed = createAsyncThunk(
  'offer/publish/feed',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await request({
        method: 'POST',
        url: `operator_portal/feed_offer/`,
        data
      })

      dispatch(getOffers())

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)


export const deleteFeedOffer = createAsyncThunk(
  'feed_offers/delete',
  async (offerId: string | number, { rejectWithValue, dispatch }) => {
    try {
      await request({
        method: 'DELETE',
        url: `operator_portal/feed_offer/${offerId}/`
      })

      dispatch(getOffers())

      return offerId
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const unpublishFeedOffer = createAsyncThunk(
  'feed_offers/unpublish',
  async (offer: FeedOffer, { rejectWithValue, dispatch }) => {
    try {
      const response = await request({
        method: 'PATCH',
        url: `operator_portal/feed_offer/${offer.id}/`,
        data: { status: OfferStatus.UNPUBLISHED }
      })

      dispatch(getOffers())

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const updateFeedOffer = createAsyncThunk(
  'feed_offers/update',
  async (offer: FeedOffer, { rejectWithValue, dispatch }) => {
    try {
      const response = await request({
        method: 'PATCH',
        url: `operator_portal/feed_offer/${offer.id}/`,
        data: offer
      })

      dispatch(getOffers())

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const republishFeedOffer = createAsyncThunk(
  'feed_offers/republish',
  async (offer: FeedOffer, { rejectWithValue, dispatch }) => {
    try {
      const response = await request({
        method: 'PATCH',
        url: `operator_portal/feed_offer/${offer.id}/`,
        data: { status: getFeedOfferStatus(offer)}
      })

      dispatch(getOffers())

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const deleteGridOffer = createAsyncThunk(
  'grid_offers/delete',
  async (offerId: string | number, { rejectWithValue, dispatch }) => {
    try {
      await request({
        method: 'DELETE',
        url: `operator_portal/availability_grid_feed/${offerId}/`
      })

      dispatch(getOffers())

      return offerId
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)


export const unpublishGridOffer = createAsyncThunk(
  'grid_offers/unpublish',
  async (offerId: string | number, { rejectWithValue, dispatch }) => {
    try {
      const response = await request({
        method: 'PATCH',
        url: `operator_portal/availability_grid_feed/${offerId}/`,
        data: { status: 3 }
      })

      dispatch(getOffers())

      return offerId
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const updateGridOffer = createAsyncThunk(
  'grid_offers/update',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await request({
        method: 'PATCH',
        url: `operator_portal/availability_grid_feed/${data.id}/`,
        data: data
      })

      dispatch(getOffers())
      dispatch(getGridOffers())

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const publishOfferToGrid = createAsyncThunk(
  'offer/publish/grid',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await request({
        method: 'POST',
        url: `operator_portal/availability_grid_feed/`,
        data
      })

      dispatch(getOffers())

      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)
