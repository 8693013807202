import React, { FormEvent, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { NewPassword } from './NewPassword'

import { LOGIN } from '../../../constants/urls'
import { useApiDispatch } from '../../../hooks/api/useApiDispatch'
import { saveNewPassword } from '../../../store/auth/actions'
import { useLoading } from '../../../hooks/api/useLoading'

export const NewPasswordPage: React.FC = () => {
  const navigate = useNavigate()
  const sendPassword = useApiDispatch(saveNewPassword)
  const loading = useLoading(saveNewPassword)
  const { search } = useLocation()
  const uid = new URLSearchParams(search).get('uidb64')
  const token = new URLSearchParams(search).get('token')

  const [password, setPassword] = useState('')
  const [isInputValueVisible, setInputValueVisible] = useState(false)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value
    setPassword(password)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sendPassword({ password, token: token, uid }).then(() => {
      navigate(LOGIN)
    })
  }

  const isPasswordValid = password.length > 6

  return (
    <NewPassword
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      isInputValueVisible={isInputValueVisible}
      setInputValueVisible={setInputValueVisible}
      isPasswordValid={isPasswordValid}
      loading={loading}
    />
  )
}
