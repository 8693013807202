import { TableConfig } from '../../../../models/TableConfig'
import format from 'date-fns/format'
import { DefaultTableStyles, TableStyles } from '../../../../models/TableStyles'
import { MainOffer } from '../../../../models/Offer'
import { CustomTable } from '../../Components/CustomTable'
import React from 'react'
import { CustomButton } from '../../../Common/CustomButton'
import { NEW_OFFER } from '../../../../constants/urls'

const tableConfig: TableConfig[] = [
  {
    name: 'Title',
    id: 'title',
    width: 'max-w-480px',
    classNames: { data: 'truncate', head: 'ml-2.5' },
    getData: (row: any) => row['name']
  },
  {
    name: 'Last publish to feed',
    id: 'date_publish',
    getData: (row: MainOffer) => row.last_published_to_feed ? format(new Date(row.last_published_to_feed), 'dd/MM/yyyy') : '-',
    classNames: { all: 'text-right', data: 'text-9C9C9C' }
  }
]

const getColumnStyles = (id: string, data: string) => {
  let result = 'text-16/19'
  if (id === 'date_publish') {
    result += ' text-9C9C9C'

    if (data === '-') {
      result += ' pr-10'
    }

  }
  return result
}

const styles: TableStyles = {
  ...DefaultTableStyles,
  headerSpace: '',
  rowClasses: 'border-b border-solid border-979797 last:border-none cursor-pointer',
  headerClasses: '-mx-2.5 text-white bg-81A46A text-15/18',
  getColumnClasses: getColumnStyles
}

type BodyTypes = {
  offers: MainOffer[]
  onOfferSelect: (offer: MainOffer) => void
}

export const Body: React.FC<BodyTypes> = ({ offers, onOfferSelect }) => {
  return (
    <div className="">
      <div>
        <CustomButton
          className="text-16/19 text-52A91A font-bold leading-6 py-3 pl-5"
          text="+Create New Offer"
          link={NEW_OFFER}
        />
      </div>
      <CustomTable
        data={offers}
        actions={[]}
        config={tableConfig}
        styles={styles}
        onRowClick={onOfferSelect}
      />
    </div>
  )
}