import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { AsyncThunk } from '@reduxjs/toolkit'
import { isIntersect } from '../../helpers/array'
import { useMemo } from 'react'

export const useSuccessRequests = (thunks: AsyncThunk<any, any, any>[]) => {
  const api = useSelector((state: RootState) => state.api)
  const { successfulRequests } = api

  return useMemo(() => {
    const thunkPrefixes: string[] = thunks.map(({ typePrefix }) => typePrefix)

    return isIntersect(thunkPrefixes, successfulRequests)
  }, [successfulRequests, thunks])

}
