import React from 'react'

type PropTypes = {
  text: string
  className?: string
}

export const InputDescription: React.FC<PropTypes> = ({ text, className }) => {
  return (
    <div
      className={`text-10/12 sm:text-11/13 text-333333 opacity-50 ${className ? className : ''}`}
    >
      {text}
    </div>
  )
}