import React, { useState } from 'react'
import { FieldError, RegisterOptions, UseFormRegister } from 'react-hook-form'

import { ReactComponent as ShowInput } from '../../../assets/svg/showPasswordIcon.svg'
import { ReactComponent as HideInput } from '../../../assets/svg/hidePasswordIcon.svg'

type PropsType = {
  label?: string
  labelText?: string
  htmlFor?: string
  placeholder?: string
  inputClass?: string
  type?: string
  name: string
  isError?: FieldError | boolean
  maxLength?: number
  register?: UseFormRegister<any>
  labelTextOnNewLine?: boolean
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  labelTextClass?: string
  options?: RegisterOptions
  secure?: boolean
  errorTextClass?: string
}

export const GolfInput: React.FC<PropsType> = (
  {
    label,
    labelText,
    placeholder,
    htmlFor,
    inputClass,
    type,
    name,
    isError,
    maxLength,
    register,
    labelTextOnNewLine,
    readOnly,
    required = true,
    disabled = false,
    labelTextClass = 'text-12/14 text-9C9C9C',
    options,
    secure,
    errorTextClass = 'text-C43100 text-12/14 ml-3'
  }) => {
  const [isInputValueVisible, setInputValueVisible] = useState<boolean>(false)
  const inputRegister = register && { ...register(name, options ? options : { required }) }
  return (
    <div>
      <div className="mb-1">
        {label && (
          <label
            className={`text-11/13 sm:text-15/18 text-333333 font-bold cursor-pointer ${labelText && !labelTextOnNewLine ? 'mr-3' : ''} ${
              isError ? 'text-C43100' : 'text-333333'
            }`}
            htmlFor={htmlFor}
          >
            {label}
          </label>
        )}
        {labelText && !isError && (
          <span className={`${labelTextOnNewLine ? 'block' : ''} ${labelTextClass}`}>
              {labelText}
            </span>
        )}
        {isError && typeof isError !== 'boolean' && <span className={errorTextClass}>{isError.message}</span>}
      </div>
      <div className="relative">
        <input
          className={`px-3 py-1.5 h-40px ${inputClass} ${
            isError ? 'golfApp__formELements_error' : 'golfApp__formELements'
          }`}
          placeholder={placeholder}
          id={htmlFor}
          type={type || ((!isInputValueVisible && secure) ? 'password' : '')}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled}
          {...inputRegister}
        />
        {secure &&
          <div className="w-10 h-full absolute top-0 right-0">
            <div className="flex justify-end items-center h-full pr-2.5">
              <SecureIcon isInputValueVisible={isInputValueVisible} setInputValueVisible={setInputValueVisible} />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

type SecureIconPropTypes = {
  isInputValueVisible: boolean
  setInputValueVisible: (args: boolean) => void
}

const SecureIcon: React.FC<SecureIconPropTypes> = ({ isInputValueVisible, setInputValueVisible }
) => {
  if (isInputValueVisible) {
    return (
      <ShowInput onClick={() => setInputValueVisible(false)} className="cursor-pointer" />
    )
  }

  return (
    <HideInput onClick={() => setInputValueVisible(true)} className="cursor-pointer" />
  )
}