import React, { useMemo } from 'react'
import { AvailabilityDay } from '../../../../../models/AvailabilityDay'
import { DayPeriod } from '../../../../../models/DayPeriod'
import { Day } from './Day'
import { DayCheckbox } from './DayCheckbox'

type PropTypes = {
  availabilityDays: AvailabilityDay[]
  setAvailabilityDays: (availabilityDays: AvailabilityDay[]) => void
  disabled: boolean
}

const DAYS_IN_COLUMN = 7

export const AvailabilityTable: React.FC<PropTypes> = ({ availabilityDays, setAvailabilityDays, disabled }) => {
  const onDayChange = (day: AvailabilityDay, period: DayPeriod) => {
    const index = availabilityDays.findIndex(({ date }) => date.getTime() === day.date.getTime())
    const newDays = [...availabilityDays]
    newDays[index] = { ...day }
    newDays[index][period] = !newDays[index][period]
    setAvailabilityDays(newDays)
  }

  const firstColumnDays = availabilityDays.slice(0, DAYS_IN_COLUMN)
  const secondColumnDays = availabilityDays.slice(DAYS_IN_COLUMN, DAYS_IN_COLUMN * 2)

  const isAllAmChecked = useMemo(() => {
    return availabilityDays.map(({ am }) => am).every((value) => value)
  }, [availabilityDays])

  const checkAllAm = () => {
    if (disabled) {
      return
    }

    const newAvailabilityDays = [...availabilityDays.map((day) => ({ ...day, am: !isAllAmChecked }))]
    setAvailabilityDays(newAvailabilityDays)
  }

  const isAllPmChecked = useMemo(() => {
    return availabilityDays.map(({ pm }) => pm).every((value) => value)
  }, [availabilityDays])

  const checkAllPm = () => {
    if (disabled) {
      return
    }

    const newAvailabilityDays = [...availabilityDays.map((day) => ({ ...day, pm: !isAllPmChecked }))]
    setAvailabilityDays(newAvailabilityDays)
  }

  return (
    <div className="border border-solid border-979797 rounded py-6 px-8">
      <div className="flex items-center justify-center mb-[28px]">
        <span className="text-[14px ] text-000000 mr-5">Select All</span>
        <div className="mr-2">
          <DayCheckbox name="am-all" period={DayPeriod.AM} onChange={checkAllAm} isChecked={isAllAmChecked} />
        </div>
        <DayCheckbox name="pm-all" period={DayPeriod.PM} onChange={checkAllPm} isChecked={isAllPmChecked} />
      </div>
      <div className="flex">
        <div className="w-[375px]">
          {firstColumnDays.map((day, index) => {
            return <Day day={day} key={index} onChange={onDayChange} disabled={disabled} />
          })}
        </div>
        <div className="w-[1.5px] h-[399px] bg-979797 mx-[62px]" />
        <div className="w-[375px]">
          {secondColumnDays.map((day, index) => {
            return <Day day={day} key={index} onChange={onDayChange} disabled={disabled} />
          })}
        </div>
      </div>
    </div>
  )
}