import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { CustomButton } from '../../Common/CustomButton'
import { OneTeammateInfo } from './OneTeammateInfo'
import { Page } from '../Components/Page'
import { saveTeammate } from '../../../store/course/actions'
import { useApi } from '../../../hooks/api/useApi'
import { EVENTS, MY_TEAM, PROMOTE_MY_COURSE } from '../../../constants/urls'
import { useNavigate } from 'react-router-dom'
import { SetupProgress } from '../Components/SetupProgress'
import { Teammate } from '../../../models/Teammate'
import { CongratsModal } from './CongratsModal'
import { EmailExistsErrorModal } from './EmailExistsErrorModal'
import { useSaveButtonConfig } from '../../../hooks/useSaveButtonConfig'

type Inputs = {
  teammates: {
    first_name: string
    last_name: string
    title: string
    email: string
  }[]
}

export const NewTeammatesPage: React.FC = () => {
  const [teammatesCount, setTeammatesCount] = useState<number>(1)
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
  const [emailExistsErrorModal, setEmailExistsErrorModal] = useState<boolean>(false)
  const teammatesStatus = useRef<boolean[]>([false])
  const prevLastTeammateName = useRef<string>('')
  const [loading, save] = useApi(saveTeammate)
  const navigate = useNavigate()
  const saveButtonConfig = useSaveButtonConfig(loading)
  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { errors }
  } = useForm<Inputs>({})

  const saveLeftTeammates = async (data: Inputs) => {
    let teammatesToSave: any = data.teammates

    const results = await Promise.all(teammatesToSave.map(async (teammate: Teammate, index: number) => {
      if (!teammate) {
        return true
      }
      const { error, payload } = await save(teammate)
      if (!error) {
        return true
      }

      if (typeof payload !== 'object') {
        return
      }
      for (const [key, value] of Object.entries(payload)) {
        // @ts-ignore
        setError(`teammates.${index}.${key}`, { message: value.join(', ') })
      }

      return false
    }))

    teammatesStatus.current = [...results, false]

    return results.every(e => !!e)
  }

  const submitForm = (data: Inputs) => {
    saveLeftTeammates(data)
    .then(isSuccess => {
      if (isSuccess) {
        navigate(MY_TEAM)
      } else {
        setEmailExistsErrorModal(true)
      }
    })
  }


  const addNewTeammate = () => {
    // setTeammatesCount(teammatesCount + 1)
    // teammatesStatus.current = [...teammatesStatus.current, false]
  }

  const saveAndAddTeammate = () => {
    handleSubmit(data => {
      saveLeftTeammates(data)
      .then(isSuccess => {
        if (isSuccess) {
          reset()
          setIsModalOpened(true)
        }
      })
    })()
  }

  // @ts-ignore
  const lastTeammateName: string = watch(`teammates[${teammatesCount - 1}][first_name]`) || ''

  if (prevLastTeammateName.current === '' && prevLastTeammateName.current !== lastTeammateName) {
    addNewTeammate()
  } else {
    prevLastTeammateName.current = lastTeammateName
  }

  const teammates = watch('teammates')

  return (
    <Page title="Teammates" relative>
      <div className="flex justify-end absolute right-[30px] top-5">
        <SetupProgress />
      </div>
      <form className="max-w-740px w-full mx-auto pb-10" onSubmit={handleSubmit(submitForm)}>
        <p className="text-18/22 text-4A4A4A opacity-80 mt-4 mb-8">
          Add all teammates who can access the portal to manage events at your course.
        </p>
        {Array.from(Array(teammatesCount).keys()).map((_: number, index: number) => {
          return (
            <OneTeammateInfo
              disabled={false}
              register={register}
              index={index}
              key={index}
              errors={errors}
              last={false}
            />
          )
        })}
        <div className="flex justify-between items-center">
          <CustomButton className="secondary-button" text="< Back" link={MY_TEAM} />
          <div>
            <CustomButton
              className="text-base text-4A4A4A opacity-50 font-medium mr-8 cursor-pointer"
              text="Skip This Step"
              onClick={() => navigate(PROMOTE_MY_COURSE)}
            />
            <CustomButton
              className="text-base text-4A4A4A opacity-50 font-medium mr-8 cursor-pointer"
              text="+ Add More"
              onClick={saveAndAddTeammate}
            />
            <CustomButton
              className={`py-4 px-68px text-20/24 font-bold text-white bg-52A91A`}
              type="submit"
              loading={loading}
              {...saveButtonConfig}
            />
          </div>
        </div>
      </form>
      <CongratsModal opened={isModalOpened} onClose={() => setIsModalOpened(false)} />
      <EmailExistsErrorModal opened={emailExistsErrorModal} onClose={() => setEmailExistsErrorModal(false)} />
    </Page>
  )
}
