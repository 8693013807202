import React from 'react'
import { ConfirmDialog } from '../../../Common/ConfirmDialog'

type PropTypes = {
  opened: boolean
  onReject: () => void
  onSubmit: () => void
  title: string
  suspending?: boolean
  submitText?: string
}

export const ConfirmSuspendingDialog: React.FC<PropTypes> =
  ({
     opened,
     onReject,
     onSubmit,
     title,
     suspending,
     submitText = 'Suspend'
   }) => {
    return (
      <ConfirmDialog
        opened={opened}
        title={title}
        description="This action cannot be undone."
        reject={{ text: 'Cancel', className: '', onClick: onReject }}
        submit={{
          text: submitText,
          className: 'text-C43100 font-medium font-sfui',
          onClick: onSubmit,
          loading: suspending
        }}
      />
    )
  }
