import { createAsyncThunk } from '@reduxjs/toolkit'
import request from '../../utils/agent'
import { fromUserDTO } from "../../helpers/user";

export const getMe = createAsyncThunk('user/me/get',
  async (skip: boolean, { rejectWithValue }) => {
    if (skip) {
      return undefined
    }
    try {
      const response = await request({
        method: 'GET',
        url: 'operator_portal/me/'
      })

      return fromUserDTO(response.data.user)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const updateMyProfile = createAsyncThunk(
  'user/me/update',
  async (data, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'PATCH',
        url: 'operator_portal/me/',
        data
      })

      return fromUserDTO(response.data.user)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const changePassword = createAsyncThunk(
  'me/password/change',
  async (data, { rejectWithValue }) => {
    try {
      const response = await request({
        method: 'POST',
        url: 'operator_portal/me/password/change/',
        data
      })

      return fromUserDTO(response.data.user)
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)