import React from 'react'
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form'
import { Course, State } from '../../../models/Course'
import { GolfInput } from '../Components/GolfInput'
import { CustomCheckbox } from '../../Common/CustomCheckbox'
import { GolfSelect } from '../Components/GolfSelect'
import { InputPhoneMask } from '../../Common/InputPhoneMask'
import { CustomButton } from '../../Common/CustomButton'
import { handleApiErrors } from '../../../helpers/api'
import { useSelectOptions } from '../../../hooks/useSelectOptions'
import { InputDescription } from '../Components/small/InputDescription'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { UseFormRegister } from 'react-hook-form/dist/types/form'

const fromFormObject = (data: Inputs): Partial<Course> => {
  return {
    ...data
  }
}

type Inputs = {
  name: string
  url: string
  is_public: boolean
  is_private: boolean
  street_address: string
  second_address: string
  city: string
  state: string
  postal_code: string
  contact_phone: string
  account_number?: string
  routing_number?: string
  account_name?: string
}

type PropTypes = {
  states?: State[]
  onSubmit: (course: Partial<Course>) => Promise<any>,
  onSuccess: () => void
}

export const AddCourseForm: React.FC<PropTypes> = ({ states, onSubmit, onSuccess }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    formState: { errors }
  } = useForm<Inputs>()

  const loading = false

  const onFormSubmit: SubmitHandler<Inputs> = (data) => {
    onSubmit(fromFormObject(data))
    .then(({ error, payload }) => {
      // TODO: Uncomment if when API was ready
      // if (!error) {
      reset()
      return onSuccess()
      // }
      handleApiErrors(setError, payload)
    })
  }

  const [
    isPublic,
    isPrivate,
    state
  ] = watch(['is_public', 'is_private', 'state'])

  const setState = (state: string) => setValue('state', state)

  const requiredOptions = (name: string): RegisterOptions => {
    return { required: `${name} is required` }
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="max-w-650px w-full mt-2">
      <h2 className="text-20/24 text-4A4A4A leading-[28px]">General Information</h2>
      <div className="flex justify-between mb-18px mt-5">
        <div className="max-w-300px w-full">
          <GolfInput
            name="name"
            inputClass="w-full"
            label="Course Name*"
            htmlFor="name"
            isError={errors.name}
            register={register}
            options={requiredOptions('Course Name')}
          />
        </div>
        <div className="max-w-300px w-full">
          <GolfInput
            name="url"
            inputClass="w-full"
            label="Course URL*"
            htmlFor="url"
            isError={errors.url}
            register={register}
            required={false}
          />
        </div>
      </div>
      <div className="mb-18px">
        <h3 className="block text-15/18 text-333333 font-bold cursor-pointer mb-2">
          Type of course*
        </h3>
        <span className="block text-9C9C9C text-12/14 mb-2.5">Please check one</span>
        <div className="flex">
          <CustomCheckbox
            text="Public Course"
            className="mr-7"
            textClass="ml-2.5 text-base text-black"
            htmlFor="is_public"
            name="is_public"
            checkMarkColor="green"
            register={register}
            disabled={isPrivate}
            isCheckboxChecked={isPublic}
          />
          <CustomCheckbox
            text="Private Course"
            textClass="ml-2.5 text-base text-black"
            htmlFor="is_private"
            name="is_private"
            checkMarkColor="green"
            register={register}
            isCheckboxChecked={isPrivate}
            disabled={isPublic}
          />
        </div>
      </div>
      <div className="w-full mb-18px">
        <GolfInput
          inputClass="w-full"
          label="Street Address"
          htmlFor="street_address"
          name="street_address"
          isError={errors.street_address}
          register={register}
        />
      </div>
      <div className="flex items-end justify-between mb-18px">
        <div className="max-w-435px w-full">
          <GolfInput
            inputClass="w-full"
            label="City"
            htmlFor="city"
            name="city"
            isError={errors.city}
            register={register}
          />
        </div>
        <div className="relative max-w-75px w-full">
          <GolfSelect
            className="text-15/18 h-40px border-979797"
            name="state"
            text="State"
            selectedOption={state}
            setSelectedOption={setState}
            optionsModalClass="h-190px top-16"
            isError={errors.state}
            register={register}
            {...useSelectOptions(states)}
          />
        </div>
        <div className="max-w-100px w-full">
          <GolfInput
            inputClass="w-full"
            label="Postal Code"
            htmlFor="postal_code"
            name="postal_code"
            isError={errors.postal_code}
            register={register}
            required={false}
          />
        </div>
      </div>
      <div className="mb-10 max-w-300px">
        <span
          className={`block text-15/18 text-333333 font-bold ${errors.contact_phone && 'text-C43100'}`}
        >
          Course Phone Number
        </span>
        <InputPhoneMask
          name="contact_phone"
          inputClass="myCourse__input"
          inputErrorClass="myCourse__input_error"
          flagBoxClass="border-979797"
          id="contact_phone"
          isError={errors.contact_phone}
          control={control}
        />
      </div>
      {isPrivate && <BankInformation errors={errors} register={register} />}
      <div className="flex justify-end">
        <CustomButton
          className={`py-2.5 px-52px font-bold text-white bg-52A91A mb-5`}
          text="Next"
          type="submit"
          loading={loading}
        />
      </div>
    </form>
  )
}

const BankInformation: React.FC<{ register: UseFormRegister<any>, errors: FieldErrors }> = ({ register, errors }) => {
  return (
    <>
      <p className="text-20/24 leading-[28px] text-4A4A4A mt-1">
        Bank Information
      </p>
      <InputDescription text="Where we send deposits" className="mb-5" />
      <div className="flex items-end justify-between mb-4">
        <div className="max-w-[382px] w-full">
          <GolfInput
            inputClass="w-full"
            label="Account Number"
            htmlFor="account_number"
            name="account_number"
            isError={errors.account_number}
            register={register}
            required={false}
          />
        </div>
        <div className="max-w-[257px] w-full">
          <GolfInput
            inputClass="w-full"
            label="Routing Number"
            htmlFor="routing_number"
            name="routing_number"
            isError={errors.routing_number}
            register={register}
            required={false}
          />
        </div>
      </div>
      <div className="w-full mb-10">
        <GolfInput
          inputClass="w-full"
          label="Account Name"
          htmlFor="account_name"
          name="account_name"
          isError={errors.account_name}
          register={register}
          required={false}
        />
      </div>
    </>
  )
}