import React from 'react'

type PropTypes = {
  title: string
}

export const CourseInternalPage: React.FC<PropTypes> = ({ title, children }) => {
  return (
    <section className="golfApp__section_100vh">
      <h1 className="w-full text-center text-4A4A4A text-36/50 font-medium mt-109px mb-18px">{title}</h1>
      <div className="flex justify-center px-10">
        {children}
      </div>
    </section>
  )
}