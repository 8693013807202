import React from 'react'
import AddPhoto from '../../../../assets/svg/addPhoto.svg'
import { UserCourse } from '../../../../models/UserCourse'
import MobileAppImage from '../../../../assets/svg/device.svg'
import Plane from '../../../../assets/svg/plane-green.svg'
import PromoCodeIcon from '../../../../assets/svg/promo-code-mobile.svg'

type PropsType = {
  imageUrl?: string
  name?: string
  description?: string
  promoCode?: string
  withoutImage?: boolean
  course?: UserCourse
  dates?: string
}

export const OfferPreview: React.FC<PropsType> = (
  {
    imageUrl,
    name,
    description,
    promoCode,
    withoutImage,
    course,
    dates
  }) => {
  return (
    <>
      <Device>
        <div className="w-full justify-center flex pt-1.5 pb-[11px]  border-b-[1px] border-solid border-F2F2F2">
          <div className="h-fit flex justify-center items-center">
            <img src={course?.logo} className="w-6" alt="" />
          </div>
          <div className="ml-4 pt-1 max-w-[140px]">
            <div className="text-[11px] leading-[12px] mb-[2px]">{course?.title}</div>
            <div className="text-[8px] leading-[9px] text-9C9C9C">{course?.msa_name}</div>
          </div>
        </div>
        {!withoutImage ? <div
          style={{
            backgroundImage: `url(${imageUrl})`,
            height: '102px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
          className="p-2.5 relative"
        >
          {!imageUrl ? (
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col">
              <img src={AddPhoto} alt="" className="w-14 h-13" />
              <p className="text-5/8 text-898A8D mt-2 ">Your photo will be uploaded here</p>
            </div>
          ) : (
            <></>
          )}
        </div> : <></>}
        <div className="border-t border-solid border-F2F2F2 justify-between p-2">
          <div className="flex justify-between">
            <span className="text-52A91A text-[12px] leading-[14px] font-semibold">
              Offer
            </span>
            <img src={Plane} alt="" />
          </div>
          <p className="text-808080 text-[12px] leading-[12px] font-bold">
            {dates || 'START DATE - END DATE'}
          </p>
          <p className="text-[13px] leading-[15px] mt-[18px] font-semibold text-000000">
            {name || 'Name your Offer'}
          </p>
          <p className="text-9/12 h-[50px] overflow-hidden text-ellipsis mt-[18px]">
            {description || 'Your Offer description goes here'}
          </p>
          <div className="flex justify-end items-center">
            <img src={PromoCodeIcon} alt="" className="mr-[6px]" />
            <span className="inline-block mr-2 text-7/8 text-9C9C9C">Offer Code:</span>
            <span className="text-[9px] font-black">
                {(promoCode && promoCode.substring(0, 30)) || 'CODE'}
              </span>
          </div>
        </div>
      </Device>
      <p className="text-center text-333333 opacity-50 text-14/16 mt-2">Offer Preview</p>
    </>
  )
}

export const Device: React.FC<any> = ({ children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${MobileAppImage})`,
        width: '233px',
        height: '483px',
        paddingTop: '32px',
        paddingBottom: '73px',
        paddingLeft: '13.5px',
        paddingRight: '13px'
      }}
    >
      <div className="w-full h-full bg-white">{children}</div>
    </div>
  )
}