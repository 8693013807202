import React from 'react'
import { CustomButton } from '../../Common/CustomButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { HOW_IT_WORKS } from '../../../constants/urls'
import { useApi } from '../../../hooks/api/useApi'
import { approveInvite } from '../../../store/invite/actions'

export const Desktop: React.FC = () => {
  const navigate = useNavigate()

  const { search } = useLocation()
  const uid = new URLSearchParams(search).get('uidb64')
  const token = new URLSearchParams(search).get('token')
  const email = new URLSearchParams(search).get('email')
  const courseId = new URLSearchParams(search).get('course_id')
  const [loading, login] = useApi(approveInvite)

  const takeToPortal = () => {
    const authToken = localStorage.getItem('authToken')

    if (authToken) {
      return navigate(HOW_IT_WORKS)
    }

    login({ uid, token, email, course_id: courseId })
    .then(({ error }) => {
      if (!error) {
        navigate(HOW_IT_WORKS)
      }
    })
  }
  return (
    <section className="w-full pt-[190px]">
      <p className="w-full text-center font-semibold text-40/55 leading-[49px] tracking-tight">
        You’re set up!
      </p>
      <p className="w-full text-center font-semibold text-40/55 leading-[49px] tracking-tight mb-[26px]">
        Now promote your course
      </p>
      <div className="flex flex-col items-center">
        <CustomButton
          text="Take Me To My Portal"
          className="text-white bg-52A91A w-[230px] font-bold text-20/24 py-4 mb-[26px]"
          onClick={takeToPortal}
          loading={loading}
        />
        <p className="text-9C9C9C">
          Need help?
          <a className="text-6EB629" href="mailto:headpro@golfbevy.com">
            {' '}
            headpro@golfbevy.co
          </a>
        </p>
      </div>
    </section>
  )
}